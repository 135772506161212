import Danger from "../../resources/images/Danger.svg";
import {AuthSubText} from "../components/AuthSubText";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {fetchRegisterData} from "../../utils/fetch's/apiRequests/fetchRegisterData";
import {isValidText} from "../../utils/validation/Validation";

export const RegisterPage = () => {
    const [registerInputMail, setRegisterMail] = useState('');
    const [registerInputFirstName, setRegisterFirstName] = useState('');
    const [registerInputLastName, setRegisterLastName] = useState('');
    const [registerInputPatronymic, setRegisterPatronymic] = useState('');
    const [registerInputPassword, setRegisterPassword] = useState('');
    const [registerErrorMessage, setRegisterErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (event: React.FormEvent) => {
        event.preventDefault();
        await fetchRegisterData(
            registerInputFirstName,
            registerInputLastName,
            registerInputPatronymic,
            registerInputMail,
            registerInputPassword,
            setRegisterErrorMessage,
            navigate
        );
    };

    const handleFirstNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setRegisterFirstName(e.target.value);
    };
    const handleLastNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setRegisterLastName(e.target.value);
    };

    const handlePatronymicInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setRegisterPatronymic(e.target.value);
    };

    return (
        <>
            <div className="register-page">
                <div className="row align-items-center gap-2">
                    <h3>Регистрация</h3>
                    <form className="col-4" onSubmit={handleRegister}>
                        <div className="form-group mt-3">
                            <label className="form-control-label" htmlFor={`inputRegisterNameRegister`}>
                                Имя
                                <span className="--orange-txt fs-5"> *</span>
                            </label>
                            <input type="text" className="form-control p-3"
                                   id={`inputRegisterNameRegister`}
                                   required value={registerInputFirstName}
                                   onChange={handleFirstNameInputChange}
                            />
                        </div>

                        <div className="form-group mt-3">
                            <label className="form-control-label"
                                   htmlFor={`inputRegisterLastnameRegister`}>
                                Фамилия
                                <span className="--orange-txt fs-5"> *</span>
                            </label>
                            <input type="text" className="form-control p-3"
                                   id={`inputRegisterLastnameRegister`}
                                   required
                                   value={registerInputLastName}
                                   onChange={handleLastNameInputChange}
                            />
                        </div>

                        <div className="form-group mt-3">
                            <label className="form-control-label"
                                   htmlFor={`inputRegisterPatronymicRegister`}>
                                Отчество
                            </label>
                            <input type="text" className="form-control p-3"
                                   id={`inputRegisterPatronymicRegister`}
                                   value={registerInputPatronymic}
                                   onChange={handlePatronymicInputChange}
                            />
                        </div>

                        <div className="form-group mt-3">
                            <label htmlFor={`inputRegisterMailRegister`}>
                                Эл. почта
                                <span className="--orange-txt fs-5"> *</span>
                            </label>
                            <input type="email" className="form-control p-3"
                                   id={`inputRegisterMailRegister`} required
                                   value={registerInputMail} onChange={(e) => setRegisterMail(e.target.value)}
                            />
                        </div>

                        <div className="form-group mt-3">
                            <label htmlFor={`inputRegisterPasswordRegister`}>
                                Пароль
                                <span className="--orange-txt fs-5"> *</span>
                            </label>
                            <input type="password" id={`inputRegisterPasswordRegister`} className="form-control p-3"
                                   aria-describedby="passwordHelpBlock" required value={registerInputPassword}
                                   onChange={(e) => setRegisterPassword(e.target.value)}
                            />
                        </div>
                        <p className={`error-message d-flex align-items-center --orange-txt mt-3 ${registerErrorMessage === '' ? 'd-none' : ''}`}>
                            <img src={`${Danger}`} alt="..." style={{paddingRight: 5}}/>
                            {registerErrorMessage}
                        </p>
                        <div className="d-flex flex-wrap justify-content-between mt-3">
                            <button className="btn btn-warning --orange-btn w-100 p-3"
                                    type="submit">Зарегистрироваться
                            </button>
                        </div>
                    </form>
                    <div className="col-1"></div>
                    <AuthSubText styles={"col-7"}/>
                </div>
            </div>
        </>
    )
}