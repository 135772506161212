import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "../../resources/css/Catalog.css";
import { CatalogProps } from "../../../interfaces/props/CatalogProps";
import { Card } from "./Card";
import { Pagination } from "./Pagination";
import { ProductProps } from "../../../interfaces/props/ProductProps";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MiniCatalogCard } from "./MiniCatalogCard";
import { AppContext } from "../context/AppContext";

interface ApiResponse {
  totalItems: number;
  items: ProductProps[];
}

export const Catalog: React.FC<CatalogProps> = ({
  name,
  category,
  isFiltered,
  setIsFiltered,
  loading,
  setLoading,
}) => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("Catalog must be used within an AppContextProvider");
  }

  const { link, setLink, selectedSort, setSelectedSort } = context;
  const [data, setData] = useState<ProductProps[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  // const [selectedSort, setSelectedSort] = useState<string | null>('');
  // const [selectedSort, setSelectedSort] = useState<string | null>(
  //   localStorage.getItem('selectedSort')
  // );
  const [paramsCheck, setParamsCheck] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("currentPage")) || 1
  );
  const itemsPerPage = 8;
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 950);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 950);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  // setLoading(true)

  // }, [navigate, location])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageReq = queryParams.get("page") || "1";
    // const categoryReq = queryParams.get("category");
    console.log(category);

    if (!pageReq) {
      return; // Return early if the necessary query parameters are missing
    }

    const widthReq = queryParams.get("width");
    const heightReq = queryParams.get("height");
    const diameterReq = queryParams.get("diameter");
    const vendorReq = queryParams.get("vendor");
    const seasonReq = queryParams.get("season");
    const sortReq = queryParams.get("sort");

    const params = [
      // sortReq && `sort=${sortReq}`,
      widthReq && `width=${widthReq}`,
      heightReq && `height=${heightReq}`,
      diameterReq && `diameter=${diameterReq}`,
      vendorReq && `vendor=${vendorReq}`,
      seasonReq && `season=${seasonReq}`,
    ]
      .filter(Boolean)
      .join("&");
    console.log(params)
    if (params.length > 1) {
      setParamsCheck(true);
    }
    const type =
      category === "passenger"
        ? "legkovye-shiny"
        : category === "truck"
        ? "gruzovye-shiny"
        : category === "agriculture"
        ? "selhoz-shiny"
        : category === "moto"
        ? "moto-shiny"
        : "legkovye-shiny";
    if (setLink) {
      setLink(`/products/${type}/${params && `?${params}`}`);
    }
    console.log(`/products/${type}/${params && `?${params}`}`)
    if (sortReq === "increasing") {
      localStorage.setItem("selectedSort", "increasing");
    } else if (sortReq === "decreasing") {
      localStorage.setItem("selectedSort", "decreasing");
    } else {
      localStorage.setItem("selectedSort", "");
    }
    pageReq && localStorage.setItem("currentPage", pageReq);
    pageReq && setCurrentPage(Number(pageReq));
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_SERVER_URL +
            `/api/products/get?currentPage=${pageReq}&itemsPerPage=${itemsPerPage}&category=${category}&${params}&sort=${sortReq}`
        );
        setData(response.data[1]);
        setTotalItems(response.data[0]);
        if (setLoading) {
          setLoading(false);
        }
        if (setIsFiltered) {
          setIsFiltered(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [location, navigate, isFiltered, setLink]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    localStorage.setItem("currentPage", pageNumber.toString());
  };

  const handleSort = (sort: string) => {
    if (setLoading) {
      setLoading(true);
    }
    setSelectedSort(sort);
    localStorage.setItem("selectedSort", sort);
  };

  return (
    <div className="catalog-wrap">
      <div style={{ display: "flex", alignItems: "center", gap: "40px" }}>
        <h1 style={{ margin: "0" }}>{name}</h1>
        <span>{loading ? "..." : totalItems} товара(ов)</span>
      </div>
      {loading ? (
        <div className="loading-spinner d-flex justify-content-center">
          <div className="spinner-grow text-dark" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="sort-products-block">
            <Link to={`${process.env.REACT_APP_CLIENT_URL}${link}&page=1`}>
              <p
                onClick={() => handleSort("")}
                className={
                  localStorage.getItem("selectedSort") === "" ? "active" : ""
                }
              >
                Без сортировки
              </p>
            </Link>
            <Link
              to={`${process.env.REACT_APP_CLIENT_URL}${link}${
                paramsCheck ? "&" : "?"
              }sort=increasing`}
            >
              <p
                onClick={() => handleSort("increasing")}
                className={
                  localStorage.getItem("selectedSort") === "increasing"
                    ? "active"
                    : ""
                }
              >
                Дешевле
              </p>
            </Link>
            <Link
              to={`${process.env.REACT_APP_CLIENT_URL}${link}${
                paramsCheck ? "&" : "?"
              }sort=decreasing`}
            >
              <p
                onClick={() => handleSort("decreasing")}
                className={
                  localStorage.getItem("selectedSort") === "decreasing"
                    ? "active"
                    : ""
                }
              >
                Дороже
              </p>
            </Link>
          </div>
          {isMobile ? (
            <div className="products-list-mobile">
              {data.map((product) => (
                <MiniCatalogCard key={product.id} product={product} />
              ))}
            </div>
          ) : (
            <>
              {data.map((product) => (
                <Card key={product.id} product={product} />
              ))}
            </>
          )}
          {totalItems > 0 ? (
            <Pagination
              link={link}
              paramsCheck={paramsCheck}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={onPageChange}
              setLoading={setLoading}
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
            />
          ) : (
            <h4 style={{ color: "var(--main-black)", margin: "30px auto" }}>
              По выбранным параметрам ничего не найдено..
            </h4>
          )}
        </>
      )}
    </div>
  );
};
