import {Breadcrumbs} from "../components/Breadcrumbs";
import React, {useContext, useEffect, useRef, useState} from "react";
import '../../resources/css/UserProfilePage.css'
import Plus from '../../resources/images/Plus.svg'
import {UserProfileOrderItem} from "../components/UserProfileOrderItem";
import {useNavigate} from "react-router-dom";
import {authFetch} from "../../utils/fetch\'s/authFetch";
import {isValidDigit, isValidText} from "../../utils/validation/Validation";
import {DataMessage} from "../../../interfaces/DataMessage";
import {AddressAttributes} from "../../../interfaces/Addresses";
import {AppContext} from "../context/AppContext";
import {response} from "express";
import axios from "axios";
import {CartItem} from "../../../interfaces/CartItem";

const BREADCRUMB_ITEMS = [
    {label: 'Главная', href: '/'},
    {label: 'Личный кабинет', href: '/profile'},
];

interface OrderItem {
    id: number;
    image: string;
    vendor: string;
    model: string;
    price: string;
    url: string;
    quantity: number;
}

interface UserOrder {
    _id: string;
    name: string;
    phone: string;
    city: string;
    order: OrderItem[];
    htmlContent: string;
    userId: string;
    userMail: string;
}

export const UserProfilePage = () => {
    const [inputMail, setMail] = useState('');
    const [inputFirstName, setFirstName] = useState('');
    const [inputLastName, setLastName] = useState('');
    const [inputPatronymic, setPatronymic] = useState('');
    const [inputPhone, setPhone] = useState('');

    const [addresses, setAddresses] = useState<string[]>([]);
    const [orders, setOrders] = useState<UserOrder[]>([]);
    const [showAddressInput, setShowAddressInput] = useState(false);
    const [streetType, setStreetType] = useState('');
    const [streetName, setStreetName] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [building, setBuilding] = useState('');
    const [apartmentNumber, setApartmentNumber] = useState('');

    const addressInputRef = useRef<HTMLInputElement>(null);
    const streetTypeRef = useRef<HTMLSelectElement>(null);
    const streetNameRef = useRef<HTMLInputElement>(null);
    const houseNumberRef = useRef<HTMLInputElement>(null);
    const buildingRef = useRef<HTMLInputElement>(null);
    const apartmentNumberRef = useRef<HTMLInputElement>(null);


    const handleFirstNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setFirstName(e.target.value);
    };
    const handleLastNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setLastName(e.target.value);
    };

    const handlePatronymicNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setPatronymic(e.target.value);
    };

    const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidDigit(e.target.value)) setPhone(e.target.value);
    };


    async function fetchUpdateUserData(event: any) {
        event.preventDefault();
        try {
            const response = await authFetch(process.env.REACT_APP_SERVER_URL + '/api/users', {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    firstName: inputFirstName,
                    lastName: inputLastName,
                    middleName: inputPatronymic,
                    email: inputMail,
                    phone: inputPhone
                })
            });
            const data: DataMessage = await response.json();

            if (!response.ok) {
                alert(data.message);
                return;
            }

            window.location.reload();
        } catch (error: any) {
            alert(error.message);
        }
    }

    async function fetchAddressData(event: any) {
        event.preventDefault();
        try {
            const response = await authFetch(process.env.REACT_APP_SERVER_URL + '/api/addresses/', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: inputMail,
                    streetType: streetType,
                    street: streetName,
                    house: houseNumber,
                    building: building,
                    apartment: apartmentNumber
                })
            });
            const data: DataMessage = await response.json();

            if (!response.ok) {
                alert(data.message);
                return;
            }

            window.location.reload();
        } catch (error: any) {
            alert(error.message);
        }
    }

    const navigate = useNavigate();


    useEffect(() => {
        authFetch(process.env.REACT_APP_SERVER_URL + '/api/users')
            .then(response => response.json())
            .then(data => {
                setMail(data.email);
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setPatronymic(data.middleName);
            });
        authFetch(process.env.REACT_APP_SERVER_URL + '/api/addresses')
            .then(response => {
                if (response.status === 204) {
                    return [];
                }
                return response.json();
            })
            .then(data => {
                const addresses = Array.isArray(data.addresses) ? data.addresses : [];
                const formattedAddresses = addresses.map((address: AddressAttributes) => {
                    const {streetType, street, house, building, apartment} = address;

                    return `${streetType}, ${street}, д. ${house}, ${building.trim() === '' ? '' : `корп. ${building},`} кв. ${apartment};`;
                });
                setAddresses(formattedAddresses);
            })
            .catch(error => {
                console.error('Error fetching addresses:', error);
                setAddresses([]);
            });
        authFetch(process.env.REACT_APP_SERVER_URL + '/api/order')
            .then(response => {
                if (response.status === 204) {
                    return [];
                }
                return response.json();
            })
            .then(data => {
                const orders = Array.isArray(data.orders) ? data.orders : [];
                setOrders(orders);
            })
            .catch(error => {
                console.error('Error fetching orders:', error.message);
                setOrders([]);
            });
    }, []);


    const handleLogout = () => {
        sessionStorage.removeItem('__access-token');
        sessionStorage.removeItem('__refresh-token');
        navigate('/');
        window.location.reload();
    };


    const handlePlusButtonClick = () => {
        setShowAddressInput(true);
    };


    const handleStreetNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setStreetName(e.target.value);
    };
    const handleHouseNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidDigit(e.target.value)) setHouseNumber(e.target.value);
    };
    const handleBuildingInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidDigit(e.target.value)) setBuilding(e.target.value);
    };

    const handleSaveAddress = async (event: any) => {
        const address = `${streetType}, ${streetName}, д. ${houseNumber},${building.trim() === '' ? '' : ` корп. ${building},`} кв. ${apartmentNumber}`;
        setAddresses([...addresses, address]);
        setShowAddressInput(false);
        await fetchAddressData(event);
        resetInputFields();
    };

    const resetInputFields = () => {
        setStreetType('');
        setStreetName('');
        setHouseNumber('');
        setBuilding('');
        setApartmentNumber('');
        if (streetTypeRef.current) streetTypeRef.current.value = '';
        if (streetNameRef.current) streetNameRef.current.value = '';
        if (houseNumberRef.current) houseNumberRef.current.value = '';
        if (buildingRef.current) buildingRef.current.value = '';
        if (apartmentNumberRef.current) apartmentNumberRef.current.value = '';
    };


    const context = useContext(AppContext);

    if (!context) {
        throw new Error('UserProfilePage must be used within an AppContextProvider');
    }

    const {cart, addToCart, setCart} = context;


    const repeatOrder = async (orderId: string) => {
        try {
            const order = orders.find(order => order._id === orderId);
            if (!order) {
                throw new Error('Order not found');
            }

            const productPromises = order.order.map(item =>
                axios.get(`${process.env.REACT_APP_SERVER_URL}/api/products/get-product?productId=${item.id}`)
            );

            const responses = await Promise.all(productPromises);

            const itemsToAdd = responses.map((response, index) => ({
                object: response.data,
                count: order.order[index].quantity
            }));

            addMultipleToCart(itemsToAdd);
            navigate('/cart')
        } catch (error) {
            console.error('Error repeating order:', error);
        }
    };

    const addMultipleToCart = (items: { object: any, count: number }[]) => {
        let newCart = [...cart];

        items.forEach(({object, count}) => {
            let existingProd = newCart.find(
                (item) => object.id === item.id
            );

            let itemInCart: CartItem = {
                id: object.id,
                image: object.images[0],
                vendor: object.vendor.name,
                model: object.model,
                price: object.price.toFixed(0),
                url: `https://toptyre.by/product/${object.id}`,
                quantity: count,
                supplier: object.supplierInfo,
                from: 'Повторный заказ с личного кабинета'
            };

            if (existingProd) {
                existingProd.quantity = (existingProd.quantity || 0) + count;
            } else {
                newCart.push(itemInCart);
            }
        });

        setCart(newCart);
        localStorage.setItem("cart", JSON.stringify(newCart));
    };


    return (
        <>
            <Breadcrumbs items={BREADCRUMB_ITEMS}/>
            <div className="user-profile-page mb-4">
                <div className="d-flex justify-content-between page-title">
                    <h3>Личный кабинет</h3>
                    <button className="btn btn-outline" data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop">
                        Выйти из аккаунта
                    </button>
                </div>

                <div className="modal fade log-out-modal" id="staticBackdrop" data-bs-backdrop="static"
                     data-bs-keyboard="false"
                     aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <h4>Вы уверены, что хотите выйти из аккаунта?</h4>
                            <div className="border-0 d-flex justify-content-between">
                                <button type="button" className="btn btn-outline" onClick={handleLogout}>Да</button>
                                <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Нет</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row gap-4">
                    <div className="col-sm-6 custom-card">
                        <h5>Личные данные</h5>

                        <form onSubmit={fetchUpdateUserData}>
                            <div className="form-group mt-3">
                                <label className="form-control-label" htmlFor="inputUserProfileName">Имя</label>
                                <input
                                    type="text"
                                    className="form-control p-3"
                                    id="inputUserProfileName"
                                    required
                                    value={inputFirstName}
                                    onChange={handleFirstNameInputChange}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label className="form-control-label" htmlFor="inputUserProfileLastname">Фамилия</label>
                                <input
                                    type="text"
                                    className="form-control p-3"
                                    id="inputUserProfileLastname"
                                    required
                                    value={inputLastName}
                                    onChange={handleLastNameInputChange}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label className="form-control-label"
                                       htmlFor="inputUserProfilePatronymic">Отчество</label>
                                <input
                                    type="text"
                                    className="form-control p-3"
                                    id="inputUserProfilePatronymic"
                                    required
                                    value={inputPatronymic}
                                    onChange={handlePatronymicNameInputChange}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label htmlFor="inputUserProfilePhone">Телефон</label>
                                <input
                                    type="tel"
                                    id="inputUserProfilePhone"
                                    className="form-control p-3"
                                    aria-describedby="passwordHelpBlock"
                                    value={inputPhone}
                                    onChange={handlePhoneInputChange}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label htmlFor="inputUserProfileMail">Эл. почта</label>
                                <input type="email" className="form-control p-3"
                                       id="inputUserProfileMail"
                                       required
                                       value={inputMail}
                                       onChange={(e) => setMail(e.target.value)}
                                />
                            </div>
                            <div className="d-flex justify-content-end repeat-order-container">
                                <button className="repeat-order" type="submit">Сохранить</button>
                            </div>
                        </form>

                    </div>
                    <div className="col-sm-6 custom-card">
                        <h5>Мои адреса</h5>
                        <div>
                            {addresses.map((address, index) => (
                                <div key={index}>
                                    <input
                                        ref={addressInputRef}
                                        type="text"
                                        value={address}
                                        className="form-control mt-2 p-3"
                                        readOnly
                                    />
                                </div>
                            ))}
                            {addresses.length < 3 && (
                                <div>
                                    <div className="d-flex justify-content-center mt-2">
                                        <button className="plus-button" onClick={handlePlusButtonClick}>
                                            <img src={`${Plus}`} alt="Добавить адрес"/>
                                        </button>
                                    </div>
                                    {showAddressInput && (
                                        <form onSubmit={handleSaveAddress}>
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <select required
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            ref={streetTypeRef}
                                                            value={streetType}
                                                            onChange={(e) => setStreetType(e.target.value)}
                                                    >
                                                        <option value="">Выберите тип улицы</option>
                                                        <option value="ул.">Улица</option>
                                                        <option value="пр-т.">Проспект</option>
                                                        <option value="бульв.">Бульвар</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <input required
                                                           type="text"
                                                           className="form-control p-3"
                                                           placeholder="Название улицы"
                                                           ref={streetNameRef}
                                                           value={streetName}
                                                           onChangeCapture={handleStreetNameInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-4">
                                                    <input required
                                                           type="text"
                                                           className="form-control p-3"
                                                           placeholder="Номер дома"
                                                           ref={houseNumberRef}
                                                           value={houseNumber}
                                                           onChange={handleHouseNumberInputChange}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <input
                                                        type="text"
                                                        className="form-control p-3"
                                                        placeholder="Корпус"
                                                        ref={buildingRef}
                                                        value={building}
                                                        onChange={handleBuildingInputChange}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <input required
                                                           type="text"
                                                           className="form-control p-3"
                                                           placeholder="Офис / кв."
                                                           ref={apartmentNumberRef}
                                                           value={apartmentNumber}
                                                           onChange={(e) => setApartmentNumber(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-12 d-flex justify-content-center">
                                                    <div className="d-flex justify-content-end repeat-order-container">
                                                        <button className="repeat-order" type="submit">Сохранить
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-6 custom-card orders">
                        <h5>Мои заказы</h5>
                        {orders.map((item, index) => (
                            <div key={index} className="user-profile-order-item">
                                {item.order.map((product, subIndex) => (
                                    <UserProfileOrderItem
                                        key={subIndex}
                                        image={product.image}
                                        title={product.vendor}
                                        sub_text={product.model}
                                        price={product.price}
                                        quantity={product.quantity}
                                    />
                                ))}
                                <div className="d-flex justify-content-end repeat-order-container">
                                    <button className="repeat-order" onClick={() => repeatOrder(item._id)}>Повторить
                                        заказ
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}