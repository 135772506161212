import {DataMessage} from "../../../../interfaces/DataMessage";

export async function fetchRegisterData(
    registerInputFirstName: string,
    registerInputLastName: string,
    registerInputPatronymic: string,
    registerInputMail: string,
    registerInputPassword: string,
    setRegisterErrorMessage: (message: string) => void,
    navigate: (path: string) => void
): Promise<void> {
    try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + '/api/auth/register', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                firstName: registerInputFirstName,
                lastName: registerInputLastName,
                middleName: registerInputPatronymic,
                email: registerInputMail,
                password: registerInputPassword,
            }),
        });
        const data: DataMessage = await response.json();

        if (!response.ok) {
            setRegisterErrorMessage(data.message);
            return;
        }
        navigate('/login');
        window.location.reload();
    } catch (error: any) {
        setRegisterErrorMessage(error.message);
    }
}
