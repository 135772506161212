import '../../resources/css/CalculatorPage.css'
import {Breadcrumbs} from "../components/Breadcrumbs";
import React from "react";
import Danger from "../../resources/images/Danger.svg";
import Calculator from "../../resources/images/Calculator.png";
import {TranslatorStandardSizes} from "../components/TranslatorStandardSizes";
import {ReplacementTireCalculator} from "../components/ReplacementTireCalculator";

const BREADCRUMB_ITEMS = [
    {label: 'Главная', href: '/'},
    {label: 'Шины', href: '/products/legkovye-shiny/'},
    {label: 'Шинный калькулятор', href: '/calculator'},
];

export const CalculatorPage = () => {
    return (
        <>
            <Breadcrumbs items={BREADCRUMB_ITEMS}/>
            <div className="calculator-page">
                <h3>Для чего нужен калькулятор</h3>

                <div className="row">
                    <div className="col-lg-8 order-1">
                        <p>С помощью этого шинного калькулятора вы легко можете посчитать как изменятся: внешние размеры
                            колеса,
                            высота дорожного просвета (клиренс), показания спидометра и другие характеристики, при
                            установке шин
                            с другим типоразмером на ваш автомобиль.</p>
                        <div className="--blue-big-card text m-top-20">
                            <h4 className="d-flex align-items-center">
                                <img src={`${Danger}`} alt="..." style={{paddingRight: 10, maxWidth: 35}}/>
                                <span>Поминте</span>
                            </h4>
                            <div className="row">
                                <p className="col-sm-6">Не меняйте типоразмер шины или размер диска на автомобилях,
                                    которые
                                    находятся на гарантии, это будет основанием прекратить гарантийное обслуживание</p>
                                <p className="col-sm-6">Если поставить диск большего диаметра - вырастет нагрузка на
                                    подвеску, автомобиль будет чаще ломаться и станет заметно жестче</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 order-2 --blue-big-card buttons d-flex align-items-center flex-column">
                        <a href="#translation-standard-size" className="btn btn-warning --orange-btn w-100"
                           type="submit">
                            Перевод типоразмера
                        </a>
                        <a href="#calculating-disk-width" className="btn btn-warning --orange-btn w-100" type="submit">
                            Расчет ширины диска
                        </a>
                        <a href="#replacement-tire-calculator" className="btn btn-warning --orange-btn w-100"
                           type="submit">
                            Шина под замену
                        </a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-5">
                        <h3 id="replacement-tire-calculator">Калькулятор шины под замену</h3>
                        <p className="p-top-15 p-bottom-15">
                            Введите сначала типоразмер установленный на вашем автомобиле, а затем тот, который вы хотите
                            установить. В таблице будут показаны результаты расчетов.
                        </p>
                        <h5>Маркировка</h5>
                        <img src={`${Calculator}`} className="w-100" alt="..."/>
                    </div>
                    <div className="col-md-1"></div>
                    <ReplacementTireCalculator styles={'col-md-6'}/>
                    <div className="col-md-6">

                    </div>
                </div>

                <hr/>

                {/*<div className="row">*/}
                {/*    <div className="col-md-5">*/}
                {/*        <h3>Подбор шин по марке и модели авто</h3>*/}
                {/*        <p className="p-top-15 p-bottom-15">*/}
                {/*            Автопроизводители рекомендуют: чтобы ходовая часть работала правильно, эксплуатируйте шины*/}
                {/*            подходящего размера. Шины неподходящего размера снижают безопасность и комфорт, ускоряют*/}
                {/*            износ деталей. Узнайте, какой размер и какие шины подходят конкретному автомобилю.*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-1"></div>*/}
                {/*    <div className="col-md-6"></div>*/}
                {/*</div>*/}

                {/*<hr/>*/}

                {/*<div className="row">*/}
                {/*    <div className="col-md-5">*/}
                {/*        <h3 id="calculating-disk-width">Расчет ширины диска</h3>*/}
                {/*        <p className="p-top-15 p-bottom-15">*/}
                {/*            При подборе дисков для вашего автомобиля необходимо точно знать минимальную и максимальную*/}
                {/*            ширину диска подходящую для данного типоразмера шины. Для расчета ширины диска вы можете*/}
                {/*            воспользоваться этим разделом*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-1"></div>*/}
                {/*    <div className="col-md-6">*/}
                {/*        <div className="text-center m-bottom-15">*/}
                {/*            <p>*/}
                {/*                Укажите типоразмер шины*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*        <div className="tyres-tab-panel">*/}
                {/*            <nav>*/}
                {/*                <div className="nav nav-tabs " id="nav-tab" role="tablist">*/}
                {/*                    <button className="nav-link" id="nav-disk-euro-tab" data-bs-toggle="tab"*/}
                {/*                            data-bs-target="#nav-disk-euro" type="button" role="tab"*/}
                {/*                            aria-controls="nav-disk-euro"*/}
                {/*                            aria-selected="false">ЕВРО*/}
                {/*                    </button>*/}
                {/*                    <button className="nav-link active" id="nav-disk-usa-tab" data-bs-toggle="tab"*/}
                {/*                            data-bs-target="#nav-disk-usa" type="button" role="tab"*/}
                {/*                            aria-controls="nav-disk-usa"*/}
                {/*                            aria-selected="true">США*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </nav>*/}
                {/*            <div className="tab-content" id="nav-tabContent">*/}
                {/*                <div className="tab-pane fade" id="nav-disk-euro" role="tabpanel"*/}
                {/*                     aria-labelledby="nav-disk-euro-tab">*/}
                {/*                    <div className="border-card">*/}
                {/*                        слайд 1*/}
                {/*                        // сюда писать код*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="tab-pane fade active show" id="nav-disk-usa" role="tabpanel"*/}
                {/*                     aria-labelledby="nav-disk-usa-tab">*/}
                {/*                    <div className="border-card">*/}
                {/*                        слайд 2*/}
                {/*                        // сюда писать код*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <button className="btn btn-warning --orange-btn w-100">Рассчитать</button>*/}
                {/*        <div className="text-center m-bottom-15 m-top-15">*/}
                {/*            <p>*/}
                {/*                Параменты подходящего диска*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*        <div className="result-block">*/}
                {/*            Резулт блок*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<hr/>*/}

                <div className="row">
                    <div className="col-md-5">
                        <h3 id="translation-standard-size">Перевод типоразмера</h3>
                        <p className="p-top-15 p-bottom-15">
                            С помощью перевода можно найти аналог шины американского типоразмера на европейском рынке и
                            наоборот
                        </p>
                    </div>
                    <div className="col-md-1"></div>

                    <TranslatorStandardSizes styles={"col-md-6"}/>
                </div>
            </div>
        </>
    )
}
