import {DataMessage} from "../../../../interfaces/DataMessage";

export async function fetchDataResetPassword(
    resetInputMail: string,
    resetInputPassword: string,
    setErrorMessage: (message: string) => void,
    navigate: (path: string) => void
): Promise<void> {
    try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + '/api/auth/set-new-password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: resetInputMail,
                newPassword: resetInputPassword,
            }),
        });
        const data: DataMessage = await response.json();
        if (!response.ok) {
            setErrorMessage(data.message);
            return;
        }

        setErrorMessage('');
        navigate('/login');
        window.location.reload();
    } catch (error: any) {
        setErrorMessage(error.message);
    }
}