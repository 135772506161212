import {OnlyStylesProps} from "../../../interfaces/props/OnlyStylesProps";
import React, {useState} from "react";
import {
    convertTireSize_EURO_to_USA,
    replacementTireCalculateProfile_USA,
    replacementTireCalculateDiameter_USA
} from "../../utils/calc/custom-calc";

export const ReplacementTireCalculator: React.FC<OnlyStylesProps> = ({styles}) => {

    const [selectedOldA_EURO, setSelectedOldA_EURO] = useState<string>('235');
    const [selectedOldB_EURO, setSelectedOldB_EURO] = useState<string>('40');
    const [selectedOldC_EURO, setSelectedOldC_EURO] = useState<string>('14');

    const [selectedOldD_USA, setSelectedOldD_USA] = useState<string>('20');
    const [selectedOldA_USA, setSelectedOldA_USA] = useState<string>('8');
    const [selectedOldC_USA, setSelectedOldC_USA] = useState<string>('17');

    const [selectedNewA_EURO, setSelectedNewA_EURO] = useState<string>('275');
    const [selectedNewB_EURO, setSelectedNewB_EURO] = useState<string>('30');
    const [selectedNewC_EURO, setSelectedNewC_EURO] = useState<string>('23');

    const [selectedNewD_USA, setSelectedNewD_USA] = useState<string>('25');
    const [selectedNewA_USA, setSelectedNewA_USA] = useState<string>('10');
    const [selectedNewC_USA, setSelectedNewC_USA] = useState<string>('19');

    const [previousProfile, setPreviousProfile] = useState<string>('38.1');
    const [previousDiameter, setPreviousDiameter] = useState<string>('508.0');

    const [newProfile, setNewProfile] = useState<string>('76.2');
    const [newDiameter, setNewDiameter] = useState<string>('635.0');

    const [profileDifference, setProfileDifference] = useState<string>('38.1');
    const [diameterDifference, setDiameterDifference] = useState<string>('127.0');


    const [activeTab_Old, setActiveTab_Old] = useState<'EURO' | 'USA'>('USA');
    const [activeTab_New, setActiveTab_New] = useState<'EURO' | 'USA'>('USA');

    const handleTabClick_Old = (tab: 'EURO' | 'USA') => {
        setActiveTab_Old(tab);
    };

    const handleTabClick_New = (tab: 'EURO' | 'USA') => {
        setActiveTab_New(tab);
    };


    const calculateReplacementTires = async (event: React.FormEvent) => {
        event.preventDefault();

        const INCH = 25.4;

        let TEMP_OLD_D = 0, TEMP_NEW_D = 0;
        let TEMP_OLD_A = 0, TEMP_NEW_A = 0;
        let TEMP_OLD_C = 0, TEMP_NEW_C = 0;

        if (activeTab_Old === 'EURO') {
            const result = convertTireSize_EURO_to_USA(
                selectedOldA_EURO,
                selectedOldB_EURO,
                selectedOldC_EURO
            );
            TEMP_OLD_D = result.americanDiameter;
            TEMP_OLD_A = result.americanWidthInches;
            TEMP_OLD_C = result.americanDisk;
        } else {
            TEMP_OLD_D = parseFloat(selectedOldD_USA);
            TEMP_OLD_A = parseFloat(selectedOldA_USA);
            TEMP_OLD_C = parseFloat(selectedOldC_USA);
        }

        if (activeTab_New === 'EURO') {
            const result = convertTireSize_EURO_to_USA(
                selectedNewA_EURO,
                selectedNewB_EURO,
                selectedNewC_EURO
            );
            TEMP_NEW_D = result.americanDiameter;
            TEMP_NEW_A = result.americanWidthInches;
            TEMP_NEW_C = result.americanDisk;
        } else {
            TEMP_NEW_D = parseFloat(selectedNewD_USA);
            TEMP_NEW_A = parseFloat(selectedNewA_USA);
            TEMP_NEW_C = parseFloat(selectedNewC_USA);
        }

        const RESULT_PROFILE = replacementTireCalculateProfile_USA(
            TEMP_OLD_D, TEMP_OLD_A, TEMP_OLD_C,
            TEMP_NEW_D, TEMP_NEW_A, TEMP_NEW_C,
        );

        const RESULT_DIAMETER = replacementTireCalculateDiameter_USA(
            TEMP_OLD_D, TEMP_NEW_D
        )

        setPreviousProfile(RESULT_PROFILE.previousProfile.toString());
        setPreviousDiameter(RESULT_DIAMETER.previousDiameter.toString());

        setNewProfile(RESULT_PROFILE.newProfile.toString());
        setNewDiameter(RESULT_DIAMETER.newDiameter.toString());

        setProfileDifference(RESULT_PROFILE.profileDifference.toString());
        setDiameterDifference(RESULT_DIAMETER.diameterDifference.toString());
    }

    return (
        <form className={styles + " replacement-tire-calculator"} onSubmit={calculateReplacementTires}>
            <div className="text-center m-bottom-15">
                <h4>Исходные данные</h4>
                <p>Укажите прежний типоразмер</p>
            </div>
            <div className="tyres-tab-panel">
                <nav>
                    <div className="nav nav-tabs " id="nav-tab" role="tablist">
                        <button className="nav-link" id="nav-old-euro-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-old-euro" type="button" role="tab"
                                aria-controls="nav-old-euro"
                                aria-selected="false"
                                onClick={() => handleTabClick_Old('EURO')}
                        >
                            ЕВРО
                        </button>
                        <button className="nav-link active" id="nav-old-usa-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-old-usa" type="button" role="tab"
                                aria-controls="nav-old-usa"
                                aria-selected="true"
                                onClick={() => handleTabClick_Old('USA')}
                        >
                            США
                        </button>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade" id="nav-old-euro" role="tabpanel"
                         aria-labelledby="nav-old-euro-tab">
                        <div className="border-card">
                            <div className="row">
                                <div className="d-flex col-4">
                                    <span className="label_A">A</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedOldA_EURO(e.target.value)}
                                        value={selectedOldA_EURO}
                                    >
                                        <option value="135">135</option>
                                        <option value="145">145</option>
                                        <option value="155">155</option>
                                        <option value="165">165</option>
                                        <option value="175">175</option>
                                        <option value="185">185</option>
                                        <option value="195">195</option>
                                        <option value="205">205</option>
                                        <option value="215">215</option>
                                        <option value="225">225</option>
                                        <option value="235">235</option>
                                        <option value="245">245</option>
                                        <option value="255">255</option>
                                        <option value="265">265</option>
                                        <option value="275">275</option>
                                        <option value="285">285</option>
                                        <option value="295">295</option>
                                        <option value="305">305</option>
                                        <option value="315">315</option>
                                        <option value="325">325</option>
                                        <option value="335">335</option>
                                        <option value="345">345</option>
                                        <option value="355">355</option>
                                        <option value="365">365</option>
                                        <option value="375">375</option>
                                    </select>
                                </div>
                                <div className="d-flex col-4">
                                    <span className="label_B">B</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedOldB_EURO(e.target.value)}
                                        value={selectedOldB_EURO}
                                    >
                                        <option value="20">20</option>
                                        <option value="25">25</option>
                                        <option value="30">30</option>
                                        <option value="35">35</option>
                                        <option value="40">40</option>
                                        <option value="45">45</option>
                                        <option value="50">50</option>
                                        <option value="55">55</option>
                                        <option value="60">60</option>
                                        <option value="65">65</option>
                                        <option value="70">70</option>
                                        <option value="75">75</option>
                                        <option value="80">80</option>
                                        <option value="85">85</option>
                                        <option value="90">90</option>
                                    </select>
                                </div>
                                <div className="d-flex col-4">
                                    <span className="label_C">C</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedOldC_EURO(e.target.value)}
                                        value={selectedOldC_EURO}
                                    >
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="tab-pane fade active show" id="nav-old-usa" role="tabpanel"
                         aria-labelledby="nav-old-usa-tab">
                        <div className="border-card">
                            <div className="row">
                                <div className="d-flex col-4">
                                    <span className="label_D">D</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedOldD_USA(e.target.value)}
                                        value={selectedOldD_USA}
                                    >
                                        <option value="20">20,0</option>
                                        <option value="20.5">20,5</option>
                                        <option value="21">21,0</option>
                                        <option value="21.5">21,5</option>
                                        <option value="22">22,0</option>
                                        <option value="22.5">22,5</option>
                                        <option value="23">23,0</option>
                                        <option value="23.5">23,5</option>
                                        <option value="24">24,0</option>
                                        <option value="24.5">24,5</option>
                                        <option value="25">25,0</option>
                                        <option value="25.5">25,5</option>
                                        <option value="26">26,0</option>
                                        <option value="26.5">26,5</option>
                                        <option value="27">27,0</option>
                                        <option value="27.5">27,5</option>
                                        <option value="28">28,0</option>
                                        <option value="28.5">28,5</option>
                                        <option value="29">29,0</option>
                                        <option value="29.5">29,5</option>
                                        <option value="30">30,0</option>
                                        <option value="30.5">30,5</option>
                                        <option value="31">31,0</option>
                                        <option value="31.5">31,5</option>
                                        <option value="32">32,0</option>
                                        <option value="32.5">32,5</option>
                                        <option value="33">33,0</option>
                                        <option value="33.5">33,5</option>
                                        <option value="34">34,0</option>
                                        <option value="34.5">34,5</option>
                                        <option value="35">35,0</option>
                                        <option value="35.5">35,5</option>
                                        <option value="36">36,0</option>
                                        <option value="36.5">36,5</option>
                                        <option value="37">37,0</option>
                                        <option value="37.5">37,5</option>
                                        <option value="38">38,0</option>
                                        <option value="38.5">38,5</option>
                                        <option value="39">39,0</option>
                                        <option value="39.5">39,5</option>
                                        <option value="40">40,0</option>
                                        <option value="40.5">40,5</option>
                                        <option value="41">41,0</option>
                                        <option value="41.5">41,5</option>
                                        <option value="42">42,0</option>
                                        <option value="42.5">42,5</option>
                                        <option value="43">43,0</option>
                                        <option value="43.5">43,5</option>
                                        <option value="44">44,0</option>
                                        <option value="44.5">44,5</option>
                                        <option value="45">45,0</option>
                                        <option value="45.5">45,5</option>
                                        <option value="46">46,0</option>
                                        <option value="46.5">46,5</option>
                                        <option value="47">47,0</option>
                                        <option value="47.5">47,5</option>
                                        <option value="48">48,0</option>
                                        <option value="48.5">48,5</option>
                                        <option value="49">49,0</option>
                                        <option value="49.5">49,5</option>
                                        <option value="50">50,0</option>
                                        <option value="50.5">50,5</option>
                                        <option value="51">51,0</option>
                                        <option value="51.5">51,5</option>
                                        <option value="52">52,0</option>
                                        <option value="52.5">52,5</option>
                                        <option value="53">53,0</option>
                                        <option value="53.5">53,5</option>
                                        <option value="54">54,0</option>
                                        <option value="54.5">54,5</option>
                                        <option value="55">55,0</option>
                                    </select>
                                </div>
                                <div className="d-flex col-4">
                                    <span className="label_A">A</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedOldA_USA(e.target.value)}
                                        value={selectedOldA_USA}
                                    >
                                        <option value="5.5">5,5</option>
                                        <option value="6">6,0</option>
                                        <option value="6.5">6,5</option>
                                        <option value="7">7,0</option>
                                        <option value="7.5">7,5</option>
                                        <option value="8">8,0</option>
                                        <option value="8.5">8,5</option>
                                        <option value="9">9,0</option>
                                        <option value="9.5">9,5</option>
                                        <option value="10">10,0</option>
                                        <option value="10.5">10,5</option>
                                        <option value="11">11,0</option>
                                        <option value="11.5">11,5</option>
                                        <option value="12">12,0</option>
                                        <option value="12.5">12,5</option>
                                        <option value="13">13,0</option>
                                        <option value="13.5">13,5</option>
                                        <option value="14">14,0</option>
                                        <option value="14.5">14,5</option>
                                    </select>
                                </div>
                                <div className="d-flex col-4">
                                    <span className="label_C">C</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedOldC_USA(e.target.value)}
                                        value={selectedOldC_USA}
                                    >
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="text-center m-bottom-15">
                <p>Укажите новый типоразмер</p>
            </div>
            <div className="tyres-tab-panel">
                <nav>
                    <div className="nav nav-tabs " id="nav-tab" role="tablist">
                        <button className="nav-link" id="nav-new-euro-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-new-euro" type="button" role="tab"
                                aria-controls="nav-new-euro"
                                aria-selected="false"
                                onClick={() => handleTabClick_New('EURO')}
                        >
                            ЕВРО
                        </button>
                        <button className="nav-link active" id="nav-new-usa-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-new-usa" type="button" role="tab"
                                aria-controls="nav-new-usa"
                                aria-selected="true"
                                onClick={() => handleTabClick_New('USA')}
                        >
                            США
                        </button>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade" id="nav-new-euro" role="tabpanel"
                         aria-labelledby="nav-new-euro-tab">
                        <div className="border-card">
                            <div className="row">
                                <div className="d-flex col-4">
                                    <span className="label_A">A</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedNewA_EURO(e.target.value)}
                                        value={selectedNewA_EURO}
                                    >
                                        <option value="135">135</option>
                                        <option value="145">145</option>
                                        <option value="155">155</option>
                                        <option value="165">165</option>
                                        <option value="175">175</option>
                                        <option value="185">185</option>
                                        <option value="195">195</option>
                                        <option value="205">205</option>
                                        <option value="215">215</option>
                                        <option value="225">225</option>
                                        <option value="235">235</option>
                                        <option value="245">245</option>
                                        <option value="255">255</option>
                                        <option value="265">265</option>
                                        <option value="275">275</option>
                                        <option value="285">285</option>
                                        <option value="295">295</option>
                                        <option value="305">305</option>
                                        <option value="315">315</option>
                                        <option value="325">325</option>
                                        <option value="335">335</option>
                                        <option value="345">345</option>
                                        <option value="355">355</option>
                                        <option value="365">365</option>
                                        <option value="375">375</option>
                                    </select>
                                </div>
                                <div className="d-flex col-4">
                                    <span className="label_B">B</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedNewB_EURO(e.target.value)}
                                        value={selectedNewB_EURO}
                                    >
                                        <option value="20">20</option>
                                        <option value="25">25</option>
                                        <option value="30">30</option>
                                        <option value="35">35</option>
                                        <option value="40">40</option>
                                        <option value="45">45</option>
                                        <option value="50">50</option>
                                        <option value="55">55</option>
                                        <option value="60">60</option>
                                        <option value="65">65</option>
                                        <option value="70">70</option>
                                        <option value="75">75</option>
                                        <option value="80">80</option>
                                        <option value="85">85</option>
                                        <option value="90">90</option>
                                    </select>
                                </div>
                                <div className="d-flex col-4">
                                    <span className="label_C">C</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedNewC_EURO(e.target.value)}
                                        value={selectedNewC_EURO}
                                    >
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade active show" id="nav-new-usa" role="tabpanel"
                         aria-labelledby="nav-new-usa-tab">
                        <div className="border-card">
                            <div className="row">
                                <div className="d-flex col-4">
                                    <span className="label_D">D</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedNewD_USA(e.target.value)}
                                        value={selectedNewD_USA}
                                    >
                                        <option value="20">20,0</option>
                                        <option value="20.5">20,5</option>
                                        <option value="21">21,0</option>
                                        <option value="21.5">21,5</option>
                                        <option value="22">22,0</option>
                                        <option value="22.5">22,5</option>
                                        <option value="23">23,0</option>
                                        <option value="23.5">23,5</option>
                                        <option value="24">24,0</option>
                                        <option value="24.5">24,5</option>
                                        <option value="25">25,0</option>
                                        <option value="25.5">25,5</option>
                                        <option value="26">26,0</option>
                                        <option value="26.5">26,5</option>
                                        <option value="27">27,0</option>
                                        <option value="27.5">27,5</option>
                                        <option value="28">28,0</option>
                                        <option value="28.5">28,5</option>
                                        <option value="29">29,0</option>
                                        <option value="29.5">29,5</option>
                                        <option value="30">30,0</option>
                                        <option value="30.5">30,5</option>
                                        <option value="31">31,0</option>
                                        <option value="31.5">31,5</option>
                                        <option value="32">32,0</option>
                                        <option value="32.5">32,5</option>
                                        <option value="33">33,0</option>
                                        <option value="33.5">33,5</option>
                                        <option value="34">34,0</option>
                                        <option value="34.5">34,5</option>
                                        <option value="35">35,0</option>
                                        <option value="35.5">35,5</option>
                                        <option value="36">36,0</option>
                                        <option value="36.5">36,5</option>
                                        <option value="37">37,0</option>
                                        <option value="37.5">37,5</option>
                                        <option value="38">38,0</option>
                                        <option value="38.5">38,5</option>
                                        <option value="39">39,0</option>
                                        <option value="39.5">39,5</option>
                                        <option value="40">40,0</option>
                                        <option value="40.5">40,5</option>
                                        <option value="41">41,0</option>
                                        <option value="41.5">41,5</option>
                                        <option value="42">42,0</option>
                                        <option value="42.5">42,5</option>
                                        <option value="43">43,0</option>
                                        <option value="43.5">43,5</option>
                                        <option value="44">44,0</option>
                                        <option value="44.5">44,5</option>
                                        <option value="45">45,0</option>
                                        <option value="45.5">45,5</option>
                                        <option value="46">46,0</option>
                                        <option value="46.5">46,5</option>
                                        <option value="47">47,0</option>
                                        <option value="47.5">47,5</option>
                                        <option value="48">48,0</option>
                                        <option value="48.5">48,5</option>
                                        <option value="49">49,0</option>
                                        <option value="49.5">49,5</option>
                                        <option value="50">50,0</option>
                                        <option value="50.5">50,5</option>
                                        <option value="51">51,0</option>
                                        <option value="51.5">51,5</option>
                                        <option value="52">52,0</option>
                                        <option value="52.5">52,5</option>
                                        <option value="53">53,0</option>
                                        <option value="53.5">53,5</option>
                                        <option value="54">54,0</option>
                                        <option value="54.5">54,5</option>
                                        <option value="55">55,0</option>
                                    </select>
                                </div>
                                <div className="d-flex col-4">
                                    <span className="label_A">A</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedNewA_USA(e.target.value)}
                                        value={selectedNewA_USA}
                                    >
                                        <option value="5.5">5,5</option>
                                        <option value="6">6,0</option>
                                        <option value="6.5">6,5</option>
                                        <option value="7">7,0</option>
                                        <option value="7.5">7,5</option>
                                        <option value="8">8,0</option>
                                        <option value="8.5">8,5</option>
                                        <option value="9">9,0</option>
                                        <option value="9.5">9,5</option>
                                        <option value="10">10,0</option>
                                        <option value="10.5">10,5</option>
                                        <option value="11">11,0</option>
                                        <option value="11.5">11,5</option>
                                        <option value="12">12,0</option>
                                        <option value="12.5">12,5</option>
                                        <option value="13">13,0</option>
                                        <option value="13.5">13,5</option>
                                        <option value="14">14,0</option>
                                        <option value="14.5">14,5</option>
                                    </select>
                                </div>
                                <div className="d-flex col-4">
                                    <span className="label_C">C</span>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        onChange={(e) => setSelectedNewC_USA(e.target.value)}
                                        value={selectedNewC_USA}
                                    >
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <button className="btn btn-warning --orange-btn w-100" type="submit">
                Рассчитать
            </button>
            <div className="result-block">
                <h5>Данные расчета</h5>
                <div className="row">
                    <div className="col-4">
                        <label>Прежний профиль</label>
                        <div className="d-flex">
                            <p>{previousProfile} мм</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <label>Новый профиль</label>
                        <div className="d-flex">
                            <p>{newProfile} мм</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <label>Разница профиля</label>
                        <div className="d-flex">
                            <p>{parseFloat(profileDifference) > 0 ? `+${profileDifference}` : profileDifference} мм</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <label>Прежний диаметр</label>
                        <div className="d-flex">
                            <p>{previousDiameter} мм</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <label>Новый диаметр</label>
                        <div className="d-flex">
                            <p>{newDiameter} мм</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <label>Разница диаметра</label>
                        <div className="d-flex">
                            <p>{parseFloat(diameterDifference) > 0 ? `+${diameterDifference}` : diameterDifference} мм</p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}