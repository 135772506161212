import Danger from "../../resources/images/Danger.svg";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthSubText} from "../components/AuthSubText";
import "../../resources/css/AuthPages.css";
import {fetchLoginData} from "../../utils/fetch's/apiRequests/fetchLoginData";

export const LoginPage = () => {
    const [inputMail, setMail] = useState('');
    const [inputPassword, setPassword] = useState('');
    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        await fetchLoginData(inputMail, inputPassword, setLoginErrorMessage, navigate);
    };

    return (
        <>
            <div className="login-page">
                <div className="row align-items-center gap-2">
                    <h3>Личный кабинет</h3>
                    <form className="col-4" onSubmit={handleLogin}>
                        <div className="mt-2">
                            <div className="form-group mt-3">
                                <label htmlFor={`inputLoginEmailPage`}>Эл. почта
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input type="email" className="form-control p-3" id={`inputLoginEmailPage`}
                                       required
                                       value={inputMail} onChange={(e) => setMail(e.target.value)}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor={`inputLoginPasswordPage`}>Пароль
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input type="password" id={`inputLoginPasswordPage`}
                                       className="form-control p-3"
                                       aria-describedby="passwordHelpBlock" required
                                       value={inputPassword} onChange={(e) => setPassword(e.target.value)}
                                />
                                <small id="passwordHelpBlock"
                                       className="form-text text-muted d-flex justify-content-end">
                                    <a className="btn --orange-txt" style={{paddingRight: 0}} href="/reset-password">
                                        Забыли пароль?
                                    </a>
                                </small>
                            </div>
                            <p className={`error-message d-flex align-items-center --orange-txt ${loginErrorMessage === '' ? 'd-none' : ''}`}>
                                <img src={`${Danger}`} alt="..." style={{paddingRight: 5}}/>
                                {loginErrorMessage}
                            </p>
                            <div className="d-flex flex-wrap align-items-center justify-content-between mt-3 mb-2">
                                <button className="btn btn-warning --orange-btn w-100 mb-3" type="submit">Войти
                                </button>
                                <p className="last-small-text">У вас нет аккаунта? </p>
                                <a type="button" className="btn btn-outline" href="/register">
                                    Зарегистрироваться
                                </a>
                            </div>
                        </div>
                    </form>
                    <div className="col-1"></div>
                    <AuthSubText styles={"col-7"}/>
                </div>
            </div>
        </>
    )
}