import React, { useEffect, useState } from "react";
import { PaginationProps } from "../../../interfaces/PaginationProps";
import "../../resources/css/Pagination.css";
import { useLocation, useNavigate } from "react-router-dom";

export const Pagination: React.FC<PaginationProps> = ({
  link,
  paramsCheck,
  totalItems,
  itemsPerPage,
  onPageChange,
  setLoading,
  selectedSort,
  setSelectedSort
}) => {
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("currentPage")) || 1
  );
  const [maxPageNumbersToShow, setMaxPageNumbersToShow] = useState(8);
  const [isMobile, setIsMobile] = useState(false);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageReq = queryParams.get("page") || "1";
    const sortReq = queryParams.get("sort");
    pageReq && localStorage.setItem("currentPage", pageReq);
    pageReq && setCurrentPage(Number(pageReq));
    sortReq && localStorage.setItem("selectedSort", sortReq);
    sortReq && setSelectedSort && setSelectedSort(sortReq);
  }, [navigate, location]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMaxPageNumbersToShow(3);
        setIsMobile(true);
      } else {
        setMaxPageNumbersToShow(8);
        setIsMobile(false);
      }
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePageChange = (pageNumber: number) => {
    setLoading && setLoading(true);
    setCurrentPage(pageNumber);
    localStorage.setItem("currentPage", pageNumber.toString());
    onPageChange(pageNumber);
    console.log(selectedSort)
    navigate(
      `${link}${
        selectedSort ? `${paramsCheck ? "&" : "?"}sort=${selectedSort}` : ""
      }${
        pageNumber !== 1
          ? `${paramsCheck || selectedSort  ? "&" : "?"}page=${Number(
              localStorage.getItem("currentPage")
            )}`
          : ""
      }`
    );
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(
      1,
      currentPage - Math.floor(maxPageNumbersToShow / 2)
    );
    let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

    if (endPage - startPage < maxPageNumbersToShow - 1) {
      startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          className={`page-item ${currentPage === i ? "active" : ""}`}
          key={i}
        >
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <li className="page-item" key="ellipsis1">
          <span className="page-link">...</span>
        </li>
      );
      pageNumbers.unshift(
        <li className="page-item" key={1}>
          <button className="page-link" onClick={() => handlePageChange(1)}>
            1
          </button>
        </li>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <li className="page-item" key="ellipsis2">
          <span className="page-link">...</span>
        </li>
      );
      pageNumbers.push(
        <li className="page-item" key={totalPages}>
          <button
            className="page-link"
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <nav aria-label="Page navigation example" className="custom-pagination">
      <ul className="pagination justify-content-center">
        {!isMobile && (
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Предыдущая
            </button>
          </li>
        )}
        {renderPageNumbers()}
        {!isMobile && (
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Следующая
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};
