import {DataLogin} from "../../../../interfaces/DataLogin";

export async function fetchLoginData(
    inputMail: string,
    inputPassword: string,
    setLoginErrorMessage: (message: string) => void,
    navigate: (path: string) => void
): Promise<void> {
    try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + '/api/auth/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: inputMail,
                password: inputPassword,
            }),
        });
        const data: DataLogin = await response.json();

        if (!response.ok) {
            setLoginErrorMessage(data.message);
            return;
        }

        sessionStorage.setItem('__access-token', data.accessToken);
        sessionStorage.setItem('__refresh-token', data.refreshToken);
        navigate('/profile');
        window.location.reload();
    } catch (error: any) {
        setLoginErrorMessage(error.message);
    }
}
