function replacementTireCalculateProfile_USA(Old_D, Old_A, Old_C, New_D, New_A, New_C) {
    let previousProfile = (Old_D - Old_C) / 2 * 25.4;
    let newProfile = (New_D - New_C) / 2 * 25.4;

    let profileDifference = newProfile - previousProfile;

    return {
        previousProfile: previousProfile.toFixed(1),
        newProfile: newProfile.toFixed(1),
        profileDifference: profileDifference.toFixed(1),
    };
}

function replacementTireCalculateDiameter_USA(Old_D, New_D) {

    return {
        previousDiameter: (Old_D * 25.4).toFixed(1),
        newDiameter: (New_D * 25.4).toFixed(1),
        diameterDifference: ((New_D - Old_D) * 25.4).toFixed(1),
    };
}

function convertTireSize_USA_to_EURO(americanDiameter, americanWidthInches, americanDisk) {
    // Перевод ширины из дюймов в миллиметры
    const europeanWidthMM = americanWidthInches * 25.4;

    // Расчет высоты боковины (sidewallHeight)
    const sidewallHeightInches = (americanDiameter - americanDisk) / 2;

    // Высота боковины в миллиметрах
    const sidewallHeightMM = sidewallHeightInches * 25.4;

    // Расчет профиля (B) в процентах
    const profilePercentage = (sidewallHeightMM / europeanWidthMM) * 100;

    return {
        europeanWidth: europeanWidthMM,
        profile: profilePercentage,
        europeanDisk: americanDisk
    };
}


function convertTireSize_EURO_to_USA(europeanWidth, europeanProfile, europeanDiameter) {
    // Перевод ширины из миллиметров в дюймы
    const americanWidthInches = europeanWidth / 25.4;

    // Расчет диаметра в дюймах (D):
    // Формула для вычисления диаметра D в дюймах:
    // D = (ширина * профиль / 100 * 2) / 25.4 + диаметр
    const sidewallHeightInches = (europeanWidth * europeanProfile / 100) / 25.4; // Высота боковины в дюймах
    const americanDiameter = (2 * sidewallHeightInches) + (europeanDiameter * 25.4) / 25.4; // Общий диаметр в дюймах

    // Американский диск в дюймах остается таким же, как и европейский диаметр
    return {
        americanDiameter: americanDiameter,
        americanWidthInches: americanWidthInches,
        americanDisk: europeanDiameter
    };
}


export {
    replacementTireCalculateProfile_USA,
    replacementTireCalculateDiameter_USA,
    convertTireSize_USA_to_EURO,
    convertTireSize_EURO_to_USA
}