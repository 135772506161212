import React, {useEffect} from 'react';
import './resources/css/App.css';
import {Route, Routes} from "react-router-dom";
import {BasicLayout} from "./layouts/BasicLayout";
import {ProductPage} from './views/pages/ProductPage';
import {NotFoundPage} from "./views/pages/NotFoundPage";
import {HomePage} from "./views/pages/HomePage";
import {PaymentDeliveryPage} from "./views/pages/PaymentDeliveryPage";
import {GuaranteePage} from "./views/pages/GuaranteePage";
import {AboutPage} from "./views/pages/AboutPage";
import {CartPage} from './views/pages/CartPage';
import {ContactsPage} from "./views/pages/ContactsPage";
import {HomeLayout} from "./layouts/HomeLayout";
import {ComparisonPage} from './views/pages/ComparisonPage';
import {ElectronicSignPage} from './views/pages/ElectronicSignPage';
import {OrderPage} from './views/pages/OrderPage';
import {TireTestPage} from "./views/pages/TireTestPage";
import {NotFoundLayout} from "./layouts/NotFoundLayout";
import {TireTestArticlePage} from "./views/pages/TireTestArticlePage";
import {UserProfilePage} from "./views/pages/UserProfilePage";
import {TiresPage} from './views/pages/TiresPage'
import {PrivateRoute} from "./utils/router/PrivateRoute";
import {LoginPage} from "./views/pages/LoginPage";
import {RegisterPage} from "./views/pages/RegisterPage";
import {ResetPasswordPage} from "./views/pages/ResetPasswordPage";
import {CalculatorPage} from "./views/pages/CalculatorPage";
import {DisksPage} from "./views/pages/DisksPage";

function App() {

    return (
        <>
            <Routes>
                <Route path="/" element={<HomeLayout/>}>
                    <Route index element={<HomePage/>}/>
                    <Route path="/home" element={<HomePage/>}/>
                </Route>
                <Route path="/" element={<BasicLayout/>}>
                    <Route path='/product/:productId' element={<ProductPage/>}/>
                    <Route path='/comparison' element={<ComparisonPage/>}/>
                    <Route path='/electonicsign' element={<ElectronicSignPage/>}/>
                    <Route path="/payment" element={<PaymentDeliveryPage/>}/>
                    <Route path="/delivery" element={<PaymentDeliveryPage/>}/>
                    <Route path="/guarantee" element={<GuaranteePage/>}/>
                    <Route path="/contacts" element={<ContactsPage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/cart" element={<CartPage/>}/>
                    <Route path="/products/legkovye-shiny" element={<TiresPage categoryReq='passenger'/>}/>
                    <Route path="/products/gruzovye-shiny" element={<TiresPage categoryReq='truck'/>}/>
                    <Route path="/products/selhoz-shiny" element={<TiresPage categoryReq='agriculture'/>}/>
                    <Route path="/products/moto-shiny" element={<TiresPage categoryReq='moto'/>}/>
                    <Route path="/disks" element={<DisksPage/>}/>
                    <Route path="/tests" element={<TireTestPage/>}/>
                    <Route path="/tests/article/:article_id" element={<TireTestArticlePage/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/register" element={<RegisterPage/>}/>
                    <Route path="/reset-password" element={<ResetPasswordPage/>}/>
                    <Route path="/calculator" element={<CalculatorPage/>}/>
                    <Route path="/order" element={<OrderPage/>}/>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/profile" element={<UserProfilePage/>}/>
                    </Route>
                </Route>
                <Route path="/" element={<NotFoundLayout/>}>
                    <Route path="/*" element={<NotFoundPage/>}/>
                </Route>
            </Routes>
        </>
    );
}

export default App;