import React, { useContext, useEffect, useState } from 'react';
import { ProductProps } from '../../../interfaces/props/ProductProps';
import Summer from "../../resources/images/Summer.svg";
import Winter from "../../resources/images/Winter.svg";
import Demi from "../../resources/images/Demi.svg";
import Vector from "../../resources/images/vector.svg";
import Garranty from "../../resources/images/garranty.svg";
import Info from "../../resources/images/info.svg";
import '../../resources/css/Card.css';
import { AppContext } from '../context/AppContext';
import useComparison from "../../utils/handles/useComparison";
import { ComparisonAlert } from "./ComparisonAlert";

interface MiniCatalogCardProps {
    product: ProductProps;
}

export const MiniCatalogCard: React.FC<MiniCatalogCardProps> = ({ product }) => {
    const [seasonality, setSeasonality] = useState<string>('');
    const [width, setWidth] = useState<string>('');
    const [height, setHeight] = useState<string>('');
    const [inCart, setInCart] = useState<boolean>(false);
    
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('Card должен быть использован внутри AppContextProvider');
    }

    const {cart, addToCart} = context;

    useEffect(() => {
        setInCart(cart.some((item) => item.id === product.id));
    }, [cart, product.id]);

    const {
        isChecked,
        handleCheckboxChange,
        showAlert,
        setShowAlert,
    } = useComparison(product.id);

    useEffect(() => {
        product.filters.some((item) => {
            if (item.name === 'Сезонность') {
                setSeasonality(item.values[0].name);
                return false; // Continue to the next iteration
            }
            if (item.name === 'Ширина профиля') {
                setWidth(item.values[0].name);
                return false; // Continue to the next iteration
            }
            if (item.name === 'Высота профиля') {
                setHeight(item.values[0].name);
                return false; // Continue to the next iteration
            }
        })
    }, [product]);

    const formattedPrice = Number(product.price).toFixed(2);
    const [rubles, kopecks] = formattedPrice.split('.');
    
    const multiplier = product.vendor.name === 'Arivo' || product.vendor.name === 'Kumho' ? 1.25 : 1.15
    const beforeSalePrice = (Number(product.price) * multiplier).toFixed(2);
    const [rublesSale, kopecksSale] = beforeSalePrice.split('.');

    return (
        <div className='card-product'>
            <ComparisonAlert
                show={showAlert}
                onClose={() => setShowAlert(false)}
                message=""
                buttonText="Перейти к сравнению"
                buttonLink="/comparison"
            />
            <div className="model-info">
                <a href={`https://toptyre.by/product/${product.id}`}>
                    <div className="model-name">
                        <p>{product.vendor.name}</p>
                        <span>{product.model}</span>
                    </div>
                </a>
                <div className='image-btns-block'>
                    <div className="card-image">
                        <a href={`https://toptyre.by/product/${product.id}`}>
                            <img className="sun-image" src={seasonality === 'летние' ? Summer : seasonality === 'зимние' ? Winter : Demi} alt="" />
                            <img className="tyre-img" src={product.images[0]} alt="Tyre" />
                        </a>
                        <div className="comparison-block">
                            <input type="checkbox" checked={isChecked}
                                onChange={(event) => handleCheckboxChange(event, product)} />
                            <p>Сравнить</p>
                        </div>
                    </div>
                    <div className="card-truck-info">
                        <div>
                            <img src={Garranty} alt="" />
                            <span>Гарантия</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="product-info">
                <ul>
                    <li>
                        <div>Сезонность</div>
                        <span>{seasonality}</span>
                    </li>
                    <li>
                        <div>Ширина профиля</div>
                        <span>{width} мм</span>
                    </li>
                    <li>
                        <div>Высота профиля</div>
                        <span>{height} мм</span>
                    </li>
                </ul>
                <a href={`https://toptyre.by/product/${product.id}`} className="other-info">
                    <p>Смотреть все характеристики товара</p>
                    <img src={Vector} alt="" />
                </a>
            </div>
            <div className="product-price mobile">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="sale-block">
                        <p className='sale-p'>СКИДКА {product.vendor.name === 'Arivo' || product.vendor.name === 'Kumho' ? '25%' : '15%'}</p>
                        <div className="salePrice">
                            <p style={{fontWeight: 500, fontSize: '15px'}}>{rublesSale},{kopecksSale}</p>
                        </div>
                    </div>
                    <div className="price">
                        <p>{rubles},{kopecks} руб.</p>
                    </div>
                    <p className="price-text">Цена за шт.</p>
                </div>
                <div>
                    <div className="garanty-text">
                        <p>Гарантия {product.vendor.name === 'Kumho' || product.vendor.name === 'Arivo' ? '5 лет' : '2 года'}</p>
                        <img src={Info} alt=""/>
                    </div>
                    {inCart ? (
                        <a href="https://toptyre.by/cart">
                            <button id="incart" className="btn btn-warning --orange-btn btn-price">
                                Добавлено
                            </button>
                        </a>
                    ) : (
                        <button className="btn btn-warning --orange-btn btn-price" onClick={() => addToCart(product, 1)}>
                            В корзину
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
