import {useState} from "react";
import {ProductProps} from "../../../interfaces/props/ProductProps";

const useComparison = (product_id: number ) => {
    const [isChecked, setIsChecked] = useState<boolean>(() : boolean => {
        const comparisonData = sessionStorage.getItem('__product-data-comparison');
        if (comparisonData) {
            const parsedData = JSON.parse(comparisonData);
            return parsedData.some((item: { id: number }) => item.id === product_id);
        }
        return false;
    }); 
    const [showAlert, setShowAlert] = useState<boolean>(false); // State for the alert
    const COMPARISON_LIST_LENGTH = parseInt(process.env.REACT_APP_COMPARISON_LIST_LENGTH ?? '5');
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, product: ProductProps) => {
        setIsChecked(event.target.checked);
        if (event.target.checked) {
            addToComparison(product);
        } else {
            removeFromComparison(product.id);
        }
    };

    const addToComparison = (product: ProductProps) => {
        const currentComparison = sessionStorage.getItem('__product-data-comparison');
        const comparisonList = currentComparison ? JSON.parse(currentComparison) : [];

        if (!comparisonList.some((item: ProductProps) => item.id === product.id)) {
            if (comparisonList.length < COMPARISON_LIST_LENGTH) {
                comparisonList.push(product);
                setShowAlert(true);
            } else {
                setShowAlert(true);
                setIsChecked(false);
            }
            sessionStorage.setItem('__product-data-comparison', JSON.stringify(comparisonList));
        }
    };

    const removeFromComparison = (productId: number) => {
        const currentComparison = sessionStorage.getItem('__product-data-comparison');
        if (currentComparison) {
            const jsonCurrentComparison = JSON.parse(currentComparison);
            const comparisonList = jsonCurrentComparison.filter((item: ProductProps) => item.id !== productId);
            sessionStorage.setItem('__product-data-comparison', JSON.stringify(comparisonList));
        }
    };

    return {
        isChecked,
        handleCheckboxChange,
        showAlert,
        setShowAlert,
    };
};

export default useComparison;