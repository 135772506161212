import {Breadcrumbs} from "../components/Breadcrumbs";
import Shield5 from "../../resources/images/Shield-5.svg"
import Shield2 from "../../resources/images/Shield-2.svg"
import Shield3 from "../../resources/images/Shield-3.svg"
import Shield1 from "../../resources/images/Shield-1.svg"
import Danger from "../../resources/images/Danger.svg"
import {AskForm} from "../components/AskForm";
import React from "react";

const BREADCRUMB_ITEMS = [
    {label: 'Главная', href: '/'},
    {label: 'Гарантия', href: '/guarantee'},
];

export const GuaranteePage = () => {
    return (
        <>
            <Breadcrumbs items={BREADCRUMB_ITEMS}/>

            <div className="guarantee-page">
                <h3>Гарантия</h3>

                <h4 className="fw-bold mt-4">Гарантия на легковые шины</h4>
                <p className="mt-2">Гарантийный срок начинается со дня продажи товара покупателю, и составляет 60 месяцев.</p>
                <p className="mt-2">Стандартная гарантия распространяется на все шины и обеспечивает их замену или возврат средств в случае обнаружения в шинах производственных дефектов.</p>

                <div className="--blue-big-card d-flex mt-3 row">
                    <div className="d-flex align-items-center col-md-6">
                        <img src={`${Shield5}`} alt="..."/>
                        <p className="text-black" style={{padding: '5px'}}>Стандартная гарантия от заводского брака для
                            шин
                            брендов Kumho, Arivo, Continental, Michelin составляет 5 лет со дня продажи.</p>
                    </div>
                    <div className="d-flex align-items-center col-md-6">
                        <img src={`${Shield2}`} alt="..."/>
                        <p className="text-black" style={{padding: '5px'}}>Стандартная гарантия от заводского брака для
                            остальных брендов легковых и
                            легкогрузовых шин составляет 2 года со дня приобретения.</p>
                    </div>
                </div>

                <h4 className="fw-bold">Гарантия на грузовые, индустриальные и сельскохозяйственные шины</h4>
                <div className="--blue-big-card d-flex mt-3 row">
                    <div className="d-flex align-items-center">
                        <img src={`${Shield5}`} alt="..."/>
                        <p className="text-black" style={{padding: '5px'}}>Гарантийный срок службы грузовой шины
                            составляет
                            5 лет с даты изготовления, для восстановленных – 1 год с момента продажи. Неделя и год
                            производства нанесены на боковине шины.</p>
                    </div>
                </div>

                <h4 className="fw-bold">Гарантия на легковые диски</h4>
                <div className="--blue-big-card d-flex mt-3 row">
                    <div className="col-md-6 d-flex align-items-center">
                        <img src={`${Shield3}`} alt="..."/>
                        <p className="text-black" style={{padding: '5px'}}>Гарантия на диски Skad, K&K, Alutec, MAK и
                            Rial –
                            3 года. При отсутствии механических повреждений диска гарантируется отсутствие коррозии
                            лакокрасочного покрытия вне зависимости от погодных условий и применяемых на дорогах
                            реагентов.</p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                        <img src={`${Shield1}`} alt="..."/>
                        <p className="text-black" style={{padding: '5px'}}>Гарантия на диски остальных брендов – 1 год.
                            Гарантия на производственные дефекты стальных дисков – 1 год.</p>
                    </div>
                </div>

                <div className="alert alert-danger" role="alert">
                    <h4 className="fw-bold d-flex align-items-center">
                        <img src={`${Danger}`} alt="..." style={{paddingRight: 10}}/>
                        <span>Помните</span>
                    </h4>
                    <p>При возникновении гарантийного случая или при наличии претензии к качеству просим обратиться в
                        наши магазины или по нашим телефонам. Если товар подлежит гарантийной замене, мы сможем принять
                        его при предоставлении чека на покупку товара у нас.</p>
                </div>
            </div>
            <AskForm/>
        </>
    )
}
