import '../../resources/css/Header.css';
import logo from '../../resources/images/TopTyre.svg';
import {RecordTireServiceButton} from "./RecordTireServiceButton";
import {LoginForm} from "./LoginForm";
import {OnlyStylesProps} from "../../../interfaces/props/OnlyStylesProps";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from '../context/AppContext';
import {LoginFormModal} from "./LoginFormModal";
import {RecordTireServiceModal} from "./RecordTireServiceModal";

export const Header: React.FC<OnlyStylesProps> = (styles) => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('Card должен быть использован внутри AppContextProvider');
    }

    const {cart, setCart} = context;

    const [showSubmenu, setShowSubmenu] = useState(false);
    const [submenuTitle, setSubmenuTitle] = useState('');

    const handleMenuClick = (title: any) => {
        setSubmenuTitle(title);
        setShowSubmenu(true);
    };

    const handleBackClick = () => {
        setShowSubmenu(false);
        setSubmenuTitle('');
    };

    return (
        <>
            <header className={`${styles}`}>
                <nav className="navbar navbar-expand-xl d-flex flex-column justify-content-between"
                     aria-label="Sixth navbar example">
                    <div className="container-lg w-100">
                        <a className="navbar-brand" href="/">
                            <img src={`${logo}`} alt="TopTyre"/>
                        </a>
                        <div className="navbar-toggler">
                            <div className="nav-item dropdown" style={{marginRight: 20}}>
                                <p className="nav-link dropdown-toggle"
                                   data-bs-toggle="dropdown"
                                   aria-expanded="false">
                                    <i className="bi bi-telephone"></i>
                                </p>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="tel:+375291152626">
                                            {process.env.REACT_APP_FIRST_PHONE}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="tel:+375447952234">
                                            {process.env.REACT_APP_SECOND_PHONE}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <a href="/cart" className="btn a-block">
                                <i className="bi bi-cart fs-4"></i>
                                <div className='counter'>{cart.length}</div>
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarsExample06" aria-controls="navbarsExample06"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarsExample06">
                            {showSubmenu ? (
                                <div className="navbar-nav me-auto mb-2 mb-xl-0 small-show submenu">
                                    <div className="submenu-header">
                                        <button onClick={handleBackClick} className="btn back-button">
                                            <i className="bi bi-arrow-left-short fs-3"></i>
                                            {submenuTitle}
                                        </button>
                                    </div>
                                    <ul className="submenu-list">
                                        {submenuTitle === 'Диски' && (
                                            <>
                                                <li className="submenu-item"><a className="submenu-link" href="/disks">Легковые диски</a></li>
                                                <li className="submenu-item"><a className="submenu-link" href="/disks">Грузовые диски</a></li>
                                                <li className="submenu-item"><a className="submenu-link" href="/disks">Сельхоз диски</a></li>
                                            </>
                                        )}
                                        {submenuTitle === 'Шины' && (
                                            <>
                                                <li className="submenu-item"><a className="submenu-link" href="/products/legkovye-shiny/">Легковые шины</a></li>
                                                <li className="submenu-item"><a className="submenu-link" href="/products/gruzovye-shiny/">Грузовые шины</a></li>
                                                <li className="submenu-item"><a className="submenu-link" href="/products/selhoz-shiny/">Сельхоз шины</a></li>
                                                <li className="submenu-item"><a className="submenu-link" href="/products/moto-shiny/">Мотоциклетные шины</a></li>
                                                <li className="submenu-item"><a className="submenu-link" href="/tests">Тесты шин</a></li>
                                                <li className="submenu-item"><a className="submenu-link" href="/calculator">Шинный калькулятор</a></li>
                                            </>
                                        )}
                                        {submenuTitle === 'Покупателям' && (
                                            <>
                                                <li className="submenu-item"><a className="submenu-link" href="/about">О нас</a></li>
                                                <li className="submenu-item"><a className="submenu-link" href="/electonicsign">Электронный знак</a></li>
                                                <li className="submenu-item"><a className="submenu-link" href="/guarantee">Гарантия</a></li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            ) : (
                                <>
                                    <ul className="navbar-nav me-auto mb-2 mb-xl-0 small-show">
                                        <li className="nav-item">
                                            <LoginForm
                                                styles="btn nav-link active"
                                                i_content=""
                                                modalName="mobile"
                                            />
                                        </li>
                                        <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" onClick={() => handleMenuClick('Шины')}>Шины</a></li>
                                        <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" onClick={() => handleMenuClick('Диски')}>Диски</a></li>
                                        <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" onClick={() => handleMenuClick('Покупателям')}>Покупателям</a></li>
                                        <li className="nav-item"><a className="nav-link" href="/delivery">Доставка и оплата</a></li>
                                        <li className="nav-item"><a className="nav-link" href="/contacts">Контакты</a></li>
                                    </ul>
                                </>
                            )}

                            <div>
                                <div className="d-flex small-unshow">
                                    <div className="d-flex flex-column justify-content-center me-2">
                                        <p className="fw-bold">{process.env.REACT_APP_FIRST_PHONE}</p>
                                        <p className="fw-bold">{process.env.REACT_APP_SECOND_PHONE}</p>
                                    </div>
                                    <a href="/cart" className="btn me-2 a-block">
                                        <i className="bi bi-cart"></i>
                                        <div className='counter'>{cart.length}</div>
                                    </a>
                                    <LoginForm
                                        styles="btn btn-outline"
                                        i_content="bi bi-person"
                                        modalName="desktop"
                                    />
                                </div>
                                <div className="justify-content-warning-button">
                                    <RecordTireServiceButton
                                        styles="small-show mt-2"
                                        modalName="desktop"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="second-block mt-3 small-unshow d-flex justify-content-between w-100 container-lg">
                        <ul className="nav col-12 col-md-auto mb-2 justify-content-between mb-md-0 ">
                            <li className="dropdown">
                                <p className="nav-link dropdown-toggle left-nav-link"
                                   data-bs-toggle="dropdown"
                                   aria-expanded="false">
                                    ШИНЫ
                                </p>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/products/legkovye-shiny/">Легковые шины</a></li>
                                    <li><a className="dropdown-item" href="/products/gruzovye-shiny/">Грузовые шины</a></li>
                                    <li><a className="dropdown-item" href="/products/selhoz-shiny/">Сельхоз шины</a></li>
                                    <li><a className="dropdown-item" href="/products/moto-shiny/">Мотоциклетные шины</a></li>
                                    <li><a className="dropdown-item" href="/tests">Тесты шин</a></li>
                                    <li><a className="dropdown-item" href="/calculator">Шинный калькулятор</a></li>
                                </ul>
                            </li>
                            <li className="dropdown ">
                                <p className="nav-link dropdown-toggle border-end border-2"
                                   data-bs-toggle="dropdown"
                                   aria-expanded="false">
                                    ДИСКИ
                                </p>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/disks">Легковые диски</a></li>
                                    <li><a className="dropdown-item" href="/disks">Грузовые диски</a></li>
                                    <li><a className="dropdown-item" href="/disks">Сельхоз диски</a></li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <p className="nav-link dropdown-toggle"
                                   data-bs-toggle="dropdown"
                                   aria-expanded="false">
                                    ПОКУПАТЕЛЯМ
                                </p>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/about">О нас</a></li>
                                    <li><a className="dropdown-item" href="/electonicsign">Электронный знак</a></li>
                                    <li><a className="dropdown-item" href="/guarantee">Гарантия</a></li>
                                </ul>
                            </li>
                            <li><a href="/delivery" className="nav-link px-4">ДОСТАВКА И ОПЛАТА</a></li>
                            <li><a href="/contacts" className="nav-link px-4">КОНТАКТЫ</a></li>
                        </ul>
                        <RecordTireServiceButton
                            styles={''}
                            modalName="mobile"
                        />
                    </div>
                </nav>
            </header>
            <LoginFormModal modalName={'mobile'}/>
            <LoginFormModal modalName={'desktop'}/>
            <RecordTireServiceModal modalName={'mobile'}/>
            <RecordTireServiceModal modalName={'desktop'}/>
        </>
    )
}