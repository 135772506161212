import React from "react";
import {RecordTireServiceButtonProps} from "../../../interfaces/props/RecordTireServiceButtonProps";
import '../../resources/css/RecordTireServiceButton.css';

export const RecordTireServiceButton: React.FC<RecordTireServiceButtonProps> = ({styles, modalName}) => {
    return (
        <>
            <button type="button" className={`btn btn-warning --orange-btn ${styles}`}
                    data-bs-toggle="modal"
                    data-bs-target="#recordForTireServiceModal">
                <i className="bi bi-journal-check"></i>
                ЗАПИСЬ НА ШИНОМОНТАЖ
            </button>
        </>
    );
};