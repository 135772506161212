import '../../resources/css/Footer.css';

export const Footer = () => {
    return (
        <>
            <footer>
                <div className="row row-cols-1 row-cols-lg-2 row-cols-md-4 row-cols-xl-4 gap-2">
                    <div className="col-6 col-md-5">
                        <table>
                            <tbody>
                            <tr>
                                <td>Телефоны:<br/><br/></td>
                                <td>
                                    {process.env.REACT_APP_FIRST_PHONE}
                                    <br/>
                                    {process.env.REACT_APP_SECOND_PHONE}
                                </td>
                            </tr>
                            <tr>
                                <td>Эл. почта:</td>
                                <td>{process.env.REACT_APP_COMPANY_EMAIL}</td>
                            </tr>
                            <tr>
                                <td>Адрес:</td>
                                <td>Минск, улица Одинцова, 20Б</td>
                            </tr>
                            </tbody>
                        </table>
                        <p>Режим работы и обработки заказов: {process.env.REACT_APP_OPERATING_MODE}</p>
                        <p>Заказы по электронной почте и через сайт принимаются круглосуточно.</p>
                    </div>
                    <div className="col-md-2">
                        <h5>Шины</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/products/legkovye-shiny/" className="nav-link p-0">Легковые шины</a></li>
                            <li className="nav-item mb-2"><a href="/products/gruzovye-shiny/" className="nav-link p-0">Грузовые шины</a></li>
                            <li className="nav-item mb-2"><a href="/products/selhoz-shiny/" className="nav-link p-0">Сельхоз шины</a></li>
                            <li className="nav-item mb-2"><a href="/products/moto-shiny/" className="nav-link p-0">Мотоциклетные шины</a></li>
                            <li className="nav-item mb-2"><a href="/tests" className="nav-link p-0">Тест шин</a></li>
                            <li className="nav-item mb-2"><a href="/calculator" className="nav-link p-0">Шинный калькулятор</a></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h5>Диски</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/disks" className="nav-link p-0">Легковые диски</a></li>
                            <li className="nav-item mb-2"><a href="/disks" className="nav-link p-0">Грузовые диски</a></li>
                            <li className="nav-item mb-2"><a href="/disks" className="nav-link p-0">Сельхоз диски</a></li>
                            <li className="nav-item mb-2"><a href="/disks" className="nav-link p-0">Мотоциклетные диски</a></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h5>Покупателю</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/about" className="nav-link p-0">О нас</a></li>
                            <li className="nav-item mb-2"><a href="/electonicsign" className="nav-link p-0">Электронный знак</a></li>
                            <li className="nav-item mb-2"><a href="/guarantee" className="nav-link p-0">Гарантия</a></li>
                            <li className="nav-item mb-2"><a href="/delivery" className="nav-link p-0">Доставка и оплата</a></li>
                            <li className="nav-item mb-2"><a href="/contacts" className="nav-link p-0">Контакты</a></li>
                        </ul>
                    </div>
                </div>

                {/* <p className="col-md-12 mt-4" style={{fontSize: '70%'}}>СООО Автосеть Республика Беларусь, 224003, г.
                    Брест, Варшавское шоссе, 43. УНП 190513391 в торговом реестре с 30 июня 2014 г. Свидетельство о
                    регистрации № 190513391 выдано 7.06.2012 г.</p> */}
            </footer>
        </>
    )
}
