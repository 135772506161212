import {Header} from "../views/components/Header";
import {Outlet} from "react-router-dom";
import React from "react";
import {Footer} from "../views/components/Footer";

export const BasicLayout = () => {
    return (
        <>
            <Header styles={'basic-header'}/>
            <main className="container container-1120">
                <Outlet/>
            </main>
            <Footer/>
        </>
    );
}