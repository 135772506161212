import {LeavingRequestForm} from "../components/LeavingRequestForm";
import Wrench from '../../resources/images/Wrench.svg'

export const DisksPage = () => {
    return (
        <>
            <div className="d-flex align-items-centerl flex-column text-center" style={{marginTop: 35, marginBottom: 35}}>
                <h3 className="m-bottom-15">Страница находится в разработке</h3>
                <div>
                    <img src={Wrench} alt="..." style={{maxWidth: 140}}/>
                </div>
                <h4 className="m-top-15">Извините за временные неудобства</h4>
            </div>
            <LeavingRequestForm />
        </>
    )
}