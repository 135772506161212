import Danger from "../../resources/images/Danger.svg";
import Phone from "../../resources/images/BluePhone.svg";
import React, {useRef, useState} from "react";
import {isValidDigit, isValidText, isValidGosNumber} from "../../utils/validation/Validation";
import {DataMessage} from "../../../interfaces/DataMessage";
import {resetStates} from "../../utils/resetStates/resetStates";

export const RecordTireServiceModal: React.FC<{ modalName: string }> = ({modalName}) => {
    const [formIsValid, setFormIsValid] = useState(false);
    const [secondFormIsValid, setSecondFormIsValid] = useState(false);
    const [licensePlateError, setLicensePlateError] = useState(false);

    const [service, setService] = useState('');
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [storage, setStorage] = useState('no');
    const [brand, setBrand] = useState('');
    const [model, setModel] = useState('');
    const [licensePlate, setLicensePlate] = useState('');
    const [comment, setComment] = useState('');

    const mySelectRef = useRef<HTMLSelectElement>(null);

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        const selectedValue = mySelectRef.current?.value;
        if (selectedValue !== "") {
            setFormIsValid(true);
        }
    };

    const handleSelectChange = () => {
        const value = mySelectRef.current?.value as string;
        setService(value);
        setFormIsValid(value !== "");
    };

    const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setFullName(e.target.value);
        validateForm();
    };


    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidDigit(e.target.value)) setPhone(e.target.value);
        validateForm();
    };

    const handleLicensePlateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === "") {
            setLicensePlate("");
            setLicensePlateError(false);
        } else if (isValidGosNumber(value)) {
            setLicensePlate(value);
            setLicensePlateError(false);
        } else {
            setLicensePlateError(true);
        }
        validateForm();
    };

    const handleStorageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value !== '') {
            setStorage(e.target.value);
        }
        validateForm(e.target.value !== '');
    };

    const handleBrandChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBrand(e.target.value);
        validateForm();
    };

    const handleModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setModel(e.target.value);
        validateForm();
    };

    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
        validateForm();
    };

    const validateForm = (selectValue: boolean = storage !== '') => {
        const isValid =
            fullName.trim() !== '' &&
            phone.trim() !== '' &&
            brand.trim() !== '' &&
            selectValue &&
            model.trim() !== '' &&
            licensePlate.trim() !== '' &&
            !licensePlateError;
        setSecondFormIsValid(isValid);
    };

    const fetchRecordForTireService = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + '/api/support/tire-service', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    service,
                    fullName,
                    phone,
                    storage,
                    brand,
                    model,
                    licensePlate,
                    comment: comment || '-',
                }),
            });
            const data: DataMessage = await response.json();
            if (!response.ok) {
                console.error(data.message);
                return;
            }
        } catch (error: any) {
            console.error(error.message);
        }
    }

    return (
        <>
            <div className="modal fade record-tire-service-button" id="recordForTireServiceModal"
                 data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="recordForTireServiceModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered record">
                    <div className="modal-content ">
                        <form className="modal-body" onSubmit={handleFormSubmit}>
                            <div className="header-modal d-flex justify-content-between align-items-center">
                                <h5 className="modal-title " id="recordForTireServiceModalLabel">Запись на
                                    шиномонтаж</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                        onClick={() => resetStates([setService, setFullName, setPhone, setStorage, setBrand, setModel, setLicensePlate, setComment])}>
                                </button>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="form-group">
                                    <label htmlFor={`inputRecordType-${modalName}`}>Тип услуги</label>
                                    <input disabled value="Шиномонтаж" type="text"
                                           className="form-control form-control-lg" id={`inputRecordType-${modalName}`}
                                           placeholder="Иванович" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mySelect">Услуга</label>
                                    <select className="form-select form-select-lg" required
                                            aria-label=".form-select-lg example" name="mySelect" ref={mySelectRef}
                                            onChange={handleSelectChange}>
                                        <option value="">Выбрать</option>
                                        <option value="Легковой/легкогрузовой шиномонтаж">Легковой/легкогрузовой
                                            шиномонтаж
                                        </option>
                                        <option value="Грузовой шиномонтаж (17,5” - 22,5”)">Грузовой шиномонтаж (17,5” -
                                            22,5”)
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <button
                                className={`btn btn-warning --orange-btn w-100 p-2 ${formIsValid ? "" : "disabled"}`}
                                type="submit" disabled={!formIsValid}
                                data-bs-target={formIsValid ? "#enteringInformationModal" : "#recordForTireServiceModal"}
                                data-bs-toggle="modal" data-bs-dismiss="modal">
                                Забронировать
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal fade input-information" id="enteringInformationModal" aria-hidden="true"
                 aria-labelledby="exampleModalToggleLabel2">
                <div className="modal-dialog modal-dialog-scrollable">
                    <form className="modal-content" onSubmit={fetchRecordForTireService}>
                        <div className="modal-header border-0">
                            <h5 className="modal-title" id="exampleModalToggleLabel2">Ввод информации</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => resetStates([setService, setFullName, setPhone, setStorage, setBrand, setModel, setLicensePlate, setComment])}
                            />
                        </div>
                        <div className="modal-body">
                            <div className="form-group mt-3">
                                <label className="form-control-label" htmlFor={`inputRecordFullName-${modalName}`}>ФИО
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input type="text"
                                       className="form-control p-3"
                                       id={`inputRecordFullName-${modalName}`}
                                       required
                                       value={fullName}
                                       onChange={handleFullNameChange}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className="form-control-label" htmlFor={`inputRecordPhone-${modalName}`}>Телефон
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input
                                    type="tel"
                                    className="form-control p-3"
                                    id={`inputRecordPhone-${modalName}`}
                                    required
                                    value={phone}
                                    onChange={handlePhoneChange}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="mySelect">Мои шины находятся на сезонном хранении</label>
                                <select
                                    className="form-select form-select-lg"
                                    required
                                    aria-label=".form-select-lg example"
                                    name="mySelect"
                                    onChange={handleStorageChange}
                                >
                                    <option value="no">Нет</option>
                                    <option value="yes">Да</option>
                                </select>
                            </div>
                            <div className="form-group mt-3">
                                <label className="form-control-label" htmlFor={`inputRecordBrand-${modalName}`}>Марка
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control p-3"
                                    id={`inputRecordBrand-${modalName}`}
                                    required
                                    value={brand}
                                    onChange={handleBrandChange}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className="form-control-label" htmlFor={`inputRecordModel-${modalName}`}>Модель
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control p-3"
                                    id={`inputRecordModel-${modalName}`}
                                    required
                                    value={model}
                                    onChange={handleModelChange}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className="form-control-label" htmlFor={`inputRecordLicensePlate-${modalName}`}>Госномер
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control p-3"
                                    id={`inputRecordLicensePlate-${modalName}`}
                                    required
                                    value={licensePlate}
                                    onChange={handleLicensePlateChange}
                                />
                                {licensePlateError && (
                                    <div className="text-danger mt-1">
                                        <p>Внимание! Вводите только латинские буквы и цифры без спецсимволов.</p>
                                    </div>
                                )}
                            </div>
                            <div className="form-group mt-3">
                                <label className="form-control-label"
                                       htmlFor={`inputRecordComment-${modalName}`}>Комментарий</label>
                                <textarea
                                    className="form-control p-3"
                                    id={`inputRecordComment-${modalName}`}
                                    rows={5}
                                    value={comment}
                                    onChange={handleCommentChange}
                                />
                            </div>
                        </div>
                        <div className="modal-footer border-0 justify-content-start">
                            {!formIsValid && (
                                <div className="text-danger mb-2">
                                    <img src={`${Danger}`} alt="..." style={{paddingRight: 10}}/>
                                    Заполните все обязательные поля
                                </div>
                            )}
                            <button
                                className={`btn btn-warning --orange-btn w-100 ${secondFormIsValid ? '' : 'disabled'}`}
                                type="submit" data-bs-toggle="modal" data-bs-dismiss="modal"
                                disabled={!secondFormIsValid}
                                data-bs-target={secondFormIsValid ? '#recordIsFramedModal' : '#enteringInformationModal'}
                            >
                                Забронировать
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="modal fade record-is-framed" id="recordIsFramedModal" data-bs-backdrop="static"
                 data-bs-keyboard="false" aria-labelledby="recordIsFramedModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered record">
                    <div className="modal-content ">
                        <form className="modal-body" onSubmit={handleFormSubmit}>
                            <div className="header-modal d-flex justify-content-between align-items-center">
                                <h5 className="modal-title " id="recordIsFramedModalLabel">Запись на шиномонтаж
                                    оформлена!</h5>
                            </div>
                            <div className="d-flex gap-2 align-items-top">
                                <img src={`${Phone}`} alt="..."/>
                                <p>В ближайшее время с Вами свяжется наш менеджер для уточнения деталей</p>
                            </div>
                            <p>Услуга: {service}</p>
                            <a href="/"
                               className='btn btn-warning --orange-btn w-100'
                               type="submit">
                                Перейти на Главную
                            </a>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}