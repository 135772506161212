import React, {useContext, useEffect, useState} from 'react';
import '../../resources/css/ProductInfo.css';
import Phone from "../../resources/images/RedPhone.svg";
import CreditCard from "../../resources/images/creditcard.svg";
import NextArrow from "../../resources/images/nextarrow.svg";
import PrevArrow from "../../resources/images/prevarrow.svg";
import Slider, {Settings} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ProductProps} from '../../../interfaces/props/ProductProps';
import {AppContext} from '../context/AppContext';
import axios from "axios";
import { SmallProductCard } from './SmallProductCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';

interface ProductInfoProps {
    product: ProductProps;
}

export const ProductInfo: React.FC<ProductInfoProps> = ({product}) => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('Catalog must be used within an AppContextProvider');
    }

    const {addToCart, cart} = context;
    const [otherProducts, setOtherProducts] = useState<ProductProps[]>([]);
    const [seasonality, setSeasonality] = useState<string>('');
    const [width, setWidth] = useState<string>('');
    const [height, setHeight] = useState<string>('');
    const [diameter, setDiameter] = useState<string>('');
    const [loadIndex, setLoadIndex] = useState<string>('');
    const [speedIndex, setSpeedIndex] = useState<string>('');
    const [construction, setConstruction] = useState<string>('');
    const [typeTyre, setTypeTyre] = useState<string>('');
    const [inCart, setInCart] = useState<boolean>(false);
    const [deliveryTime, setDeliveryTime] = useState<string>();
    const [otherPage, setOtherPage] = useState()
    const [loading, setLoading] = useState<boolean>(true)
    const itemsPerPage = 4;

    useEffect(() => {
        product.filters.some((item) => {
            if (item.name === 'Сезонность') {
                setSeasonality(item.values[0].name);
                return false; // Continue to the next iteration
            }
            if (item.name === 'Ширина профиля') {
                setWidth(item.values[0].name);
                return false; // Continue to the next iteration
            }
            if (item.name === 'Высота профиля') {
                setHeight(item.values[0].name);
                return false; // Continue to the next iteration
            }
            if (item.name === 'Диаметр') {
                setDiameter(item.values[0].name);
                return false; // Continue to the next iteration
            }
            if (item.name === 'Индекс нагрузки') {
                setLoadIndex(item.values[0].name);
                return false; // Continue to the next iteration
            }
            if (item.name === 'Индекс максимальной скорости') {
                setSpeedIndex(item.values[0].name);
                return false; // Continue to the next iteration
            }
            if (item.name === 'Конструкция') {
                setConstruction(item.values[0].name);
                return false; // Continue to the next iteration
            }
            return false; // Continue to the next iteration
        });
        product.supplierInfo === 'мосавтошина' ? setDeliveryTime('Доставка на склад через 4-7 дней') :
        product.supplierInfo === 'Колесо' ? setDeliveryTime('Доставка на склад через 1-3 дней') :
        product.supplierInfo === 'саблайн' ? setDeliveryTime('На складе') :
        product.supplierInfo === 'Триовист' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'Автоспейс' ? setDeliveryTime('На складе') :
        product.supplierInfo === 'лоял' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'Энергия' ? setDeliveryTime('На складе') :
        product.supplierInfo === 'Мегасклад' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'ТШК' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'Каршина' ? setDeliveryTime('На складе') :
        product.supplierInfo === 'багория' ? setDeliveryTime('Доставка на склад через 3-5 дней') :
        product.supplierInfo === 'ШатеМ' ? setDeliveryTime('На складе') :
        product.supplierInfo === 'шинрек' ? setDeliveryTime('На складе') :
        product.supplierInfo === 'Вакси' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'ALS Trading' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'ГранатТорг' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'прошина' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'гранат торг' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'Паречин' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'леня' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'Сергей' ? setDeliveryTime('Доставка на склад через 7-14 дней') :
        product.supplierInfo === 'Довнар' ? setDeliveryTime('Доставка на склад через 2-4 дней') :
        product.supplierInfo === 'Априла' ? setDeliveryTime('Доставка на склад через 1-3 дней') :
        product.supplierInfo === 'Толвик' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'Обмоин 6343000' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'вика авто' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'курва' ? setDeliveryTime('Доставка на склад через 7-14 дней') :
        product.supplierInfo === 'Шинный дом ООО' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'Шинсервис' ? setDeliveryTime('Доставка на склад через 5-10 дней') :
        product.supplierInfo === 'Вианор' ? setDeliveryTime('Доставка на склад через 5-10 дней') :
        product.supplierInfo === 'rff' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'станок' ? setDeliveryTime('Доставка на склад через 1-2 дней') :
        product.supplierInfo === 'Боненкамп' ? setDeliveryTime('Доставка на склад через 2-10 дней') :
        product.supplierInfo === '4tochki' ? setDeliveryTime('Доставка на склад через 5-10 дней') : setDeliveryTime('Наличие уточняйте у менеджера')
    }, [product]);
    useEffect(() => {
        const fetchOtherProducts = async () => {
            const brands = ['Michelin', 'Arivo', 'Continental', 'Kumho'];
            try {
                const response = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/products/get-products-by-brands', {
                    params: {brands}
                });
                setOtherProducts(response.data);
                setLoading(false)
            } catch (error) {
                console.error('Error fetching other products:', error);
            }
        };

        fetchOtherProducts();
    }, []);

    function getFilterValue(tire: ProductProps, index: number): string {
        return tire?.filters.find(filter => filter.id === index)?.values[0].name || '–'
    }

    const filledImages = [...product.images];
    while (filledImages.length < 3) {
        filledImages.push(...product.images);
    }
    filledImages.length = 3; // Ограничиваем массив до трех элементов

    const [mainImage, setMainImage] = useState(filledImages[0]);
    const [count, setCount] = useState(1);
    const [selectedTab, setSelectedTab] = useState<string>("characteristics");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1010);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1010);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //Для слайдера
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 767);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        product.filters.some((item) => {
            if (item.name === 'Тип автомобиля') {
                setTypeTyre(item.values[0].name);
                return false; // Continue to the next iteration
            }
        })
    }, [])

    useEffect(() => {
        setInCart(cart.some((item) => item.id === product.id));
    }, [cart, product.id]);

    const handleTabChange = (tab: string) => {
        setSelectedTab(tab);
    };
    const settingsDesk: Settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        centerPadding: "0",
        arrows: false,
        beforeChange: (_, next) => setMainImage(filledImages[next])
    };

    const settingsMobile: Settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <img src={NextArrow} alt='NextArrow'/>,
        prevArrow: <img src={PrevArrow} alt='PrevArrow'/>
    };
    const countClick = (type: string) => {
        if (type === 'minus') {
            if (count > 0) {
                setCount(count - 1);
            }
        } else if (type === 'plus') {
            setCount(count + 1);
        }
    };
    return (
        <div className='product-container'>
            <div className='product-main-info'>
                <h1>{typeTyre === 'легковой' && 'Легковые шины'} {product.vendor.name} {product.model}</h1>
                <div className='main-info-block'>
                    {/* <div> */}
                    {isMobile ? (
                        <div className='slider-container-mobile'>
                            <Slider key="mobile" {...settingsMobile}>
                                {filledImages.map((image, index) => (
                                    <div key={index}>
                                        <img src={image} alt={`Image ${index + 1}`}/>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    ) : (
                        <div className="slider-container">
                            <div className="slider-thumbnails">
                                <Slider key="desktop" {...settingsDesk}>
                                    {filledImages.map((image, index) => (
                                        <div key={index} onClick={() => setMainImage(image)}>
                                            <img src={image} alt={`Image ${index + 1}`}/>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                            <div className="slider-main-image">
                                <img src={mainImage} alt="Main Image"/>
                            </div>
                        </div>
                    )}
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div className="tyre-price-block">
                            <div className='tyre-price-info'>
                                <span>Цена за 1 шт. :</span>
                                <p>{product.price} руб.</p>
                            </div>
                            <div className='tyre-price-info'>
                                <span>Итого :</span>
                                <p className='final-price'>{Number(product.price) * count} руб.</p>
                            </div>
                            <div className='order-info'>
                                <div className='btns-block'>
                                    <button onClick={() => countClick('minus')} className='btn-quantity'>–</button>
                                    <span>{count}</span>
                                    <button onClick={() => countClick('plus')} className='btn-quantity'>+</button>
                                </div>
                                {inCart ? (
                                    <a href="https://toptyre.by/cart">
                                        <button id="incart" className="btn btn-warning --orange-btn btn-order-info">
                                            Добавлено
                                        </button>
                                    </a>
                                ) : (
                                    <button className="btn btn-warning --orange-btn btn-order-info"
                                            onClick={() => count > 0 && addToCart(product, count)}>Добавить в корзину
                                    </button>
                                )}
                            </div>
                        </div>
                        {!isMobile && (
                            <>
                                {/* <div className="card-truck-info">
                                    <div>
                                        <div>
                                            <p>B</p>
                                        </div>
                                        <span>Стандарт</span>
                                    </div>
                                    <div>
                                        <div>
                                            <img src={Garranty} alt=""/>
                                        </div>
                                        <span>Этикетка</span>
                                    </div>
                                    <div className='card-truck'>
                                        <img src={Truck} alt=""/>
                                        <span>- Всепозицион.</span>
                                    </div>
                                </div> */}
                                <div className='card-questions'>
                                    <div>
                                        <img src={Phone} alt=""/>
                                        <p>ОСТАЛИСЬ ВОПРОСЫ ?</p>
                                    </div>
                                    <p>Звоните нам +375 (29) 115-26-26</p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {isMobile ? (
                <div className='product-parameters-mobile'>
                    <ul>
                        <li>
                            <p className='parameters-title'>Наличие</p>
                            <div className='availability-info-block'>
                                <div>
                                    <div style={{borderBottom: '1px solid var(--purple-40)'}}>
                                        <p>Адрес магазина</p>
                                        <span>г. Минск, ул. Одинцова 20Б</span>
                                    </div>
                                    <div>
                                        <p>Наличие</p>
                                        <p className='for-order'>Под заказ: {product.quantity} шт.</p>
                                    </div>
                                </div>
                                <div>
                                    <span>{deliveryTime}</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className='parameters-title'>Характеристика</p>
                            <div className="product-info">
                                <ul style={{borderRight: '1px solid var(--purple-20)'}}>
                                    <li>
                                        <div>Категория</div>
                                        <span>{typeTyre === 'легковой' ? 'Легковые шины' : typeTyre === 'грузовой' ? 'Грузовые шины' : 'Шины для спецтехники'}</span>
                                    </li>
                                    <li>
                                        <div>Сезонность</div>
                                        <span>{seasonality}</span>
                                    </li>
                                    <li>
                                        <div>Ширина профиля</div>
                                        <span>{width} мм</span>
                                    </li>
                                    <li>
                                        <div>Высота профиля</div>
                                        <span>{height} мм</span>
                                    </li>
                                    <li>
                                        <div>Диаметр</div>
                                        <span>{diameter}</span>
                                    </li>
                                    <li>
                                        <div>Индекс нагрузки</div>
                                        <span>{loadIndex}</span>
                                    </li>
                                    <li>
                                        <div>Индекс максимальной скорости</div>
                                        <span>{speedIndex}</span>
                                    </li>
                                    <li>
                                        <div>Конструкция</div>
                                        <span>{construction}</span>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <p className='parameters-title'>Доставка</p>
                            <div className='delivery-info-block'>
                                <p className="fw-medium">Мы доставляем товар следующими способами: </p>
                                <span>Доставка по Минску: </span>
                                <span>Будние дни с 11.00 до 21.00</span>
                                <span>Суббота с 11.00 до 18.00 (выходной воскресенье).</span>
                                <span>Доставка по Минску в пределах МКАД осуществляется от 4 шин бесплатно (при сумме заказа от 800 BYN),  2шт оговаривается (доставка платная), или самовывоз.</span>
                                <p className="fw-medium">Доставка по Беларуси:</p>
                                <span>осуществляется с помощью транспортной компании</span>
                                <span>Тариф  от 25 до 38 BYN, Вашу стоимость уточняйте у менеджера.</span>
                                <span>Доставка  товара осуществляется  до ПОДЪЕЗДА-МАШИНЫ!</span>
                                <span>Доставка заказных позиций осуществляется  от 3 до 14 дней, (уточняйте у менеджера).</span>
                                <span>Безналичный расчет для юридических лиц  доставка не осуществляется, самовывоз из магазина.</span>
                            </div>
                        </li>
                        <li>
                            <p className='parameters-title'>Рассрочка</p>
                            <div className='installment-info-block'>
                                <div>
                                    <span>Мы принимаем карты рассрочки:</span>
                                    <div>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <img src={CreditCard} alt="CreditCard"/>
                                                <span>Халва</span>
                                                <p className='ms-0'>от 2 до 4 месяцев</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <img src={CreditCard} alt="CreditCard"/>
                                                <span>Карта покупок</span>
                                                <p className='ms-0'>3 месяца</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <img src={CreditCard} alt="CreditCard"/>
                                                <span>Черепаха</span>
                                                <p className='ms-0'>8 месяцев</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p className='parameters-title'>Гарантия</p>
                            <div className='warranty-info-block'>
                                <p className='mb-2'>Порядок обращения по гарантии:</p>
                                <span>В случае предъявления претензий по качеству купленного товара,  в течение трех рабочих дней мы назначаем дату и время предварительного осмотра. По результатам предварительного осмотра принимается решение о возврате или замене некачественного товара, отказе в гарантии, либо в спорных и не очевидных случаях назначается проведение экспертизы. Согласно действующему законодательству Республики Беларусь, покупатель самостоятельно обращается к независимому лицензированному эксперту за заключением, оплачивает все расходы за проведение экспертизы, уведомляя нас о дате и времени ее проведения.</span>
                                <span>В случае положительного решения по экспертизе и признания дефекта, возникшего в результате заводского брака, мы возмещаем все расходы на проведение экспертизы и осуществляем замену товара либо компенсацию в соответствии с действующим законодательством Республики Беларусь.</span>
                                <span>В случае установления заводского брака одной единицы товара (шины или диска), а товар приобретался комплектом, то возврат или замена производится только утратившей свои свойства отдельной единицы товара (шины или диска), а не всего комплекта.</span>
                                <p className='mb-2 mt-2'>Нормативное регулирование</p>
                                <span>Все шины и диски, представленные в нашем каталоге интернет-магазина, сертифицированы для использования в Республике Беларусь. На весь товар предоставляется гарантия, гарантийный срок от 12 до 36 месяцев с момента покупки шин и дисков, в зависимости от их производителей. Претензия по гарантии принимается нами к рассмотрению в случае правильной установки шин и дисков на сертифицированных для данного вида работ шиномонтажных станциях, при наличии документов, удостоверяющих покупку именно в нашем магазине.</span>
                                <p className='mb-2 mt-2'>Возврат товара надлежащего качества</p>
                                <span>Срок возврата товара надлежащего качества составляет 14 дней с момента получения товара (оплаты товара), согласно закона РБ «О защите прав потребителей»</span>
                                <span>При себе иметь документы, подтверждающие факт приобретения товара в нашем магазине: чек и гарантийный талон.</span>
                                <span>Товары надлежащего качества, принимаются назад в полной комплектации, со всеми наклейками, QR-коды, в неиспользованном виде, без следов монтажа и эксплуатации. В противном случае, если они были в употреблении, или отсутствуют Оригинальные наклейки, стикеры и упаковка, товар возврату не подлежит.</span>
                            </div>
                        </li>
                    </ul>
                </div>
            ) : (
                <div className='product-parameters'>
                    <ul className="nav nav-tabs-info">
                        <li className="nav-item" style={{borderLeft: 'none'}}>
                            <div className={`nav-link-info ${selectedTab === 'characteristics' ? 'active' : ''}`}
                                 aria-current="page" onClick={() => handleTabChange("characteristics")}>
                                Характеристика
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link-info ${selectedTab === 'availability' ? 'active' : ''}`}
                                 onClick={() => handleTabChange("availability")}>
                                Наличие
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link-info ${selectedTab === 'delivery' ? 'active' : ''}`}
                                 onClick={() => handleTabChange("delivery")}>
                                Доставка
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link-info ${selectedTab === 'warranty' ? 'active' : ''}`}
                                 onClick={() => handleTabChange("warranty")}>
                                Гарантия
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link-info ${selectedTab === 'installment' ? 'active' : ''}`}
                                 style={{borderRight: 'none', borderRadius: '5px 5px 0 0'}}
                                 onClick={() => handleTabChange("installment")}>
                                Рассрочка
                            </div>
                        </li>
                    </ul>
                    {selectedTab === 'characteristics' ? (
                        <div className="product-info">
                            <ul style={{borderRight: '1px solid var(--purple-20)'}}>
                                <li>
                                    <div>Категория</div>
                                    <span>{typeTyre === 'легковой' ? 'Легковые шины' : typeTyre === 'грузовой' ? 'Грузовые шины' : 'Шины для спецтехники'}</span>
                                </li>
                                <li>
                                    <div>Сезонность</div>
                                    <span>{seasonality}</span>
                                </li>
                                <li>
                                    <div>Ширина профиля</div>
                                    <span>{width} мм</span>
                                </li>
                                <li>
                                    <div>Высота профиля</div>
                                    <span>{height} мм</span>
                                </li>
                            </ul>
                            <ul style={{borderLeft: '1px solid var(--purple-20)'}}>
                                <li>
                                    <div>Диаметр</div>
                                    <span>{diameter}</span>
                                </li>
                                <li>
                                    <div>Индекс нагрузки</div>
                                    <span>{loadIndex}</span>
                                </li>
                                <li>
                                    <div>Индекс максимальной скорости</div>
                                    <span>{speedIndex}</span>
                                </li>
                                <li>
                                    <div>Конструкция</div>
                                    <span>{construction}</span>
                                </li>
                            </ul>
                        </div>
                    ) : (selectedTab === 'availability') ? (
                        <div className='availability-info-block'>
                            <a href="/contacts">Показать адрес на карте</a>
                            <div>
                                <div style={{borderBottom: '1px solid var(--purple-40)'}}>
                                    <p>Адрес магазина</p>
                                    <p>Режим работы</p>
                                    <p>Наличие</p>
                                </div>
                                <div>
                                    <span>г. Минск, ул. Одинцова 20Б</span>
                                    <p>ПН-ВС: <span>9:00 - 20:00</span></p>
                                    <div className='availability-prod'>
                                        <p>Под заказ: {product.quantity} шт.</p>
                                        <span>{deliveryTime}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (selectedTab === "delivery") ? (
                        <div className='delivery-info-block'>
                            <p className="fw-medium">Мы доставляем товар следующими способами: </p>
                            <span>Доставка по Минску: </span>
                            <span>Будние дни с 11.00 до 21.00</span>
                            <span>Суббота с 11.00 до 18.00 (выходной воскресенье).</span>
                            <span>Доставка по Минску в пределах МКАД осуществляется от 4 шин бесплатно (при сумме заказа от 800 BYN),  2шт оговаривается (доставка платная), или самовывоз.</span>
                            <p className="fw-medium">Доставка по Беларуси:</p>
                            <span>осуществляется с помощью транспортной компании</span>
                            <span>Тариф  от 25 до 38 BYN, Вашу стоимость уточняйте у менеджера.</span>
                            <span>Доставка  товара осуществляется  до ПОДЪЕЗДА-МАШИНЫ!</span>
                            <span>Доставка заказных позиций осуществляется  от 3 до 14 дней, (уточняйте у менеджера).</span>
                            <span>Безналичный расчет для юридических лиц  доставка не осуществляется, самовывоз из магазина.</span>
                        </div>
                    ) : (selectedTab === "warranty") ? (
                        <div className='warranty-info-block'>
                            <p>Порядок обращения по гарантии:</p>
                            <span>В случае предъявления претензий по качеству купленного товара,  в течение трех рабочих дней мы назначаем дату и время предварительного осмотра. По результатам предварительного осмотра принимается решение о возврате или замене некачественного товара, отказе в гарантии, либо в спорных и не очевидных случаях назначается проведение экспертизы. Согласно действующему законодательству Республики Беларусь, покупатель самостоятельно обращается к независимому лицензированному эксперту за заключением, оплачивает все расходы за проведение экспертизы, уведомляя нас о дате и времени ее проведения.</span>
                            <span>В случае положительного решения по экспертизе и признания дефекта, возникшего в результате заводского брака, мы возмещаем все расходы на проведение экспертизы и осуществляем замену товара либо компенсацию в соответствии с действующим законодательством Республики Беларусь.</span>
                            <span>В случае установления заводского брака одной единицы товара (шины или диска), а товар приобретался комплектом, то возврат или замена производится только утратившей свои свойства отдельной единицы товара (шины или диска), а не всего комплекта.</span>
                            <p>Нормативное регулирование</p>
                            <span>Все шины и диски, представленные в нашем каталоге интернет-магазина, сертифицированы для использования в Республике Беларусь. На весь товар предоставляется гарантия, гарантийный срок от 12 до 36 месяцев с момента покупки шин и дисков, в зависимости от их производителей. Претензия по гарантии принимается нами к рассмотрению в случае правильной установки шин и дисков на сертифицированных для данного вида работ шиномонтажных станциях, при наличии документов, удостоверяющих покупку именно в нашем магазине.</span>
                            <p>Возврат товара надлежащего качества</p>
                            <span>Срок возврата товара надлежащего качества составляет 14 дней с момента получения товара (оплаты товара), согласно закона РБ «О защите прав потребителей»</span>
                            <span>При себе иметь документы, подтверждающие факт приобретения товара в нашем магазине: чек и гарантийный талон.</span>
                            <span>Товары надлежащего качества, принимаются назад в полной комплектации, со всеми наклейками, QR-коды, в неиспользованном виде, без следов монтажа и эксплуатации. В противном случае, если они были в употреблении, или отсутствуют Оригинальные наклейки, стикеры и упаковка, товар возврату не подлежит.</span>
                        </div>
                    ) : (
                        <div className='installment-info-block'>
                            <span>Рассрочка до 6 месяцев при покупке от 100р.</span>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <img src={CreditCard} alt="CreditCard"/>
                                            <span>Халва</span>
                                        </div>
                                        <p>от 2 до 4 месяцев</p>
                                    </div>
                                    <div>
                                        <div>
                                            <img src={CreditCard} alt="CreditCard"/>
                                            <span>Карта покупок</span>
                                        </div>
                                        <p>3 месяца</p>
                                    </div>
                                    <div>
                                        <div>
                                            <img src={CreditCard} alt="CreditCard"/>
                                            <span>Черепаха</span>
                                        </div>
                                        <p>8 месяцев</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className='other-tyres-block'>
                <h1>Другие шины</h1>
                {loading ? (
                    <div className="loading-spinner d-flex justify-content-center">
                        <div className="spinner-grow text-dark" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                            {otherProducts.length > 0 && (
                                <>
                                    {isSmallScreen ? (
                                        <div className="row">
                                            <Swiper
                                                slidesPerView="auto"
                                                spaceBetween={16}
                                                scrollbar={{draggable: true}}
                                                modules={[Scrollbar]}
                                                className="mySwiper"
                                            >
                                                {otherProducts.map((othProduct, index) => (
                                                    <SwiperSlide key={index}>
                                                        <SmallProductCard
                                                            key={index}
                                                            id={othProduct.id}
                                                            imageUrl={othProduct.images[0]}
                                                            season={getFilterValue(othProduct, 8631)}
                                                            title={othProduct.vendor.name}
                                                            text={othProduct.model}
                                                            price={othProduct.price}
                                                            product={othProduct}
                                                        />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                    ) : (
                                        <div
                                            className="row row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 row-cols-xl-4 g-4 gap-3 justify-content-around">
                                            {otherProducts.map((othProduct, index) => (
                                                <SmallProductCard
                                                    key={index}
                                                    id={othProduct.id}
                                                    imageUrl={othProduct.images[0]}
                                                    season={getFilterValue(othProduct, 8631)}
                                                    title={othProduct.vendor.name}
                                                    text={othProduct.model}
                                                    price={othProduct.price}
                                                    product={othProduct}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}
                    </>
            )}
                </div>
        </div>
    )
}
