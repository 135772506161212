import {AuthSubText} from "../components/AuthSubText";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Danger from "../../resources/images/Danger.svg";
import {fetchDataResetPassword} from "../../utils/fetch\'s/apiRequests/fetchDataResetPassword";
import {fetchDataVerificationCode} from "../../utils/fetch's/apiRequests/fetchDataVerificationCode";
import {fetchDataForgotPassword} from "../../utils/fetch's/apiRequests/fetchDataForgotPassword";
import {useTimer} from "../../utils/timer/timer";
import {handleVerificationCodeChange} from "../../utils/handles/handleVerificationCodeChange";

export const ResetPasswordPage = () => {
    const [resetInputMail, setResetMail] = useState('');
    const [resetInputPassword, setResetPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const [verificationCode, setVerificationCode] = React.useState<string[]>(Array(6).fill(''));

    const navigate = useNavigate();
    const [isButtonDisabled, timeRemaining, handleReset, setTimeRemaining, setIsButtonDisabled] = useTimer();

    const handleResetPassword = async (event: React.FormEvent) => {
        event.preventDefault();
        await fetchDataResetPassword(resetInputMail, resetInputPassword, setErrorMessage, navigate);
    };
    const handleVerifyCode = async (event: React.FormEvent) => {
        event.preventDefault();
        await fetchDataVerificationCode(resetInputMail, verificationCode, setErrorMessage, setShowResetPassword);
    };
    const handleForgotPassword = async (event: React.FormEvent) => {
        event.preventDefault();
        await fetchDataForgotPassword(
            resetInputMail,
            setErrorMessage,
            setShowNewPassword,
            setIsButtonDisabled,
            setTimeRemaining
        );
    };
    const handleSendPasswordReset = async (event: React.FormEvent) => {
        event.preventDefault();
        event.preventDefault();
        await handleForgotPassword(event);
        handleReset();
    };

    const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        handleVerificationCodeChange(index, event, verificationCode, setVerificationCode, inputsRef);
    };

    return (
        <>
            <div className="reset-password-page">
                <div className="row gap-2">
                    <h3>Восстановление пароля</h3>
                    <div className="col-4">
                        <>
                            {!showNewPassword ? (
                                <>
                                    <form onSubmit={handleForgotPassword}>
                                        <p>На указанную вами почту будет отпралено письмо с восстановлением пароля</p>
                                        <div className="form-group mt-3">
                                            <label htmlFor={`inputResetPasswordMailResetPassword`}>
                                                Эл. почта
                                                <span className="--orange-txt fs-5"> *</span>
                                            </label>
                                            <input type="email" className="form-control p-3"
                                                   id={`inputResetPasswordMailResetPassword`}
                                                   placeholder="name@example.com" required
                                                   value={resetInputMail} onChange={(e) => setResetMail(e.target.value)}
                                            />
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between mt-3">
                                            <button className="btn btn-warning --orange-btn w-100 p-3"
                                                    type="submit">Отправить письмо
                                            </button>
                                        </div>
                                    </form>
                                </>
                            ) : (
                                <>
                                    {!showResetPassword ?
                                        <>
                                            <form className="verify-form" onSubmit={handleVerifyCode}>
                                                <div className="form-group mt-3">
                                                    <label htmlFor="verificationCode">Код верификации <span
                                                        className="--orange-txt fs-5">*</span></label>
                                                    <div className='d-flex justify-content-around'>
                                                        {Array.from({length: 6}, (_, index) => (
                                                            <input
                                                                key={index}
                                                                type="text"
                                                                id={`inputResetPasswordResetPassword${index}`}
                                                                className="form-control p-3 mx-1 text-center"
                                                                aria-describedby="passwordHelpBlock"
                                                                required
                                                                value={verificationCode[index]}
                                                                onChange={(e) => handleChange(index, e)}
                                                                ref={(el) => inputsRef.current[index] = el}
                                                                maxLength={1}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                                <p className={`error-message d-flex align-items-center --orange-txt mt-1 ${errorMessage === '' ? 'd-none' : ''}`}>
                                                    <img src={`${Danger}`} alt="..." style={{paddingRight: 5}}/>
                                                    {errorMessage}
                                                </p>
                                                <div className="d-flex flex-wrap justify-content-between mt-3 mb-2">
                                                    <button className="btn btn-warning --orange-btn w-100 p-3 mb-3"
                                                            type="submit">Отправить
                                                    </button>
                                                    <p className="last-small-text">Письмо не пришло?</p>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline"
                                                        onClick={handleSendPasswordReset}
                                                        disabled={isButtonDisabled}
                                                    >
                                                        {isButtonDisabled ? `Отправить (${timeRemaining} с)` : 'Отправить'}
                                                    </button>
                                                </div>
                                            </form>
                                        </>
                                        :
                                        <>
                                            <form onSubmit={handleResetPassword}>
                                                <div className="form-group mt-3">
                                                    <label htmlFor={`inputResetPasswordResetPassword`}>
                                                        Новый пароль
                                                        <span className="--orange-txt fs-5"> *</span>
                                                    </label>
                                                    <input type="password" id={`inputResetPasswordResetPassword`}
                                                           className="form-control p-3"
                                                           aria-describedby="passwordHelpBlock" required
                                                           value={resetInputPassword}
                                                           onChange={(e) => setResetPassword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="d-flex flex-wrap justify-content-between mt-3 mb-2">
                                                    <button className="btn btn-warning --orange-btn w-100 p-3 mb-3"
                                                            type="submit">Войти
                                                    </button>
                                                </div>
                                            </form>
                                        </>
                                    }
                                </>
                            )}
                        </>
                    </div>
                    <div className="col-1"></div>
                    <AuthSubText styles={"col-7"}/>
                </div>
            </div>
        </>
    )
}