import React from "react";
import {UserProfileOrderItemProps} from "../../../interfaces/props/UserProfileOrderItemProps";


export const UserProfileOrderItem: React.FC<UserProfileOrderItemProps> = ({key, image, title, sub_text, price, quantity}) => {
    return (
        <>
            <div key={key}>
                <div className="row">
                    <img className="col-3" src={image} alt="..."/>
                    <div className="col-5">
                        <h6 className="fw-bold">{title}</h6>
                        <p>{sub_text}</p>
                    </div>
                    <div className="col-4">
                        <p>Стоимость</p>
                        <p className="fw-bold mt-1">{price} руб.</p>
                        <p className="mt-1">Кол-во: {quantity}</p>
                    </div>
                </div>
            </div>
        </>
    )
}