import React from "react";
import {OnlyStylesProps} from "../../../interfaces/props/OnlyStylesProps";
import RedPhone from "../../resources/images/RedPhone.svg";

export const AuthSubText: React.FC<OnlyStylesProps> = ({styles}) => {
    return (
        <>
            <div className={styles} style={{maxWidth: 567}}>
                <div className="--purple-card" style={{padding: 15}}>
                    <p>
                        Преимущества личного кабинета:
                    </p>
                    <ul className="--orange-ul">
                        <li><span>Просмотр истории заказов</span></li>
                        <li><span>Быстрый заказ благодаря автоматическому заполнению форм</span></li>
                        <li><span>Добавление своих адресов</span></li>
                        <li><span>Уведомления об акциях и скидках</span></li>
                    </ul>
                </div>
                <div className="d-flex align-items-start" style={{marginTop: 15}}>
                    <img src={`${RedPhone}`} alt="..." style={{paddingRight: 10}}/>
                    <p>Если у вас возникли трудности со входом в личный кабинет, пожалуйста, свяжитесь с нами по
                        телефонам:</p>
                </div>
                <div className="d-flex" style={{marginTop: 10}}>
                    <div className="text-end">
                        <p>Звоните нам {process.env.REACT_APP_FIRST_PHONE}</p>
                        <p>{process.env.REACT_APP_SECOND_PHONE}</p>
                    </div>
                </div>
            </div>

        </>
    )
}