const authFetch = async (url: string, options: RequestInit = {}): Promise<Response> => {
    let accessToken = sessionStorage.getItem('__access-token') as string;
    const refreshToken = sessionStorage.getItem('__refresh-token') as string;


    if (accessToken) {
        if (!options.headers) {
            options.headers = {};
        }

        const headers = new Headers(options.headers);
        headers.set('Authorization', `Bearer ${accessToken}`);

        options.headers = headers;
    }

    try {
        let response = await fetch(url, options);

        if (response.status === 401 && refreshToken) {
            const refreshResponse = await fetch(process.env.REACT_APP_SERVER_URL + '/api/auth/refresh', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            });
            if (refreshResponse.ok) {
                const data = await refreshResponse.json();
                accessToken = data.accessToken;
                sessionStorage.setItem('__access-token', accessToken);

                if (!options.headers) {
                    options.headers = {};
                }
                const headers = new Headers(options.headers);
                headers.set('Authorization', `Bearer ${accessToken}`);
                options.headers = headers;

                response = await fetch(url, options);
            } else {
                window.location.href = '/login';
                return response;
            }
        } else if (response.status === 401) {
            window.location.href = '/login';
            return response;
        }

        else if (response.status === 404) {
            window.location.href = '/not-found';
            return response;
        }

        return response;
    } catch (error : any) {
        console.error('Network request error', error.message);
        throw error;
    }
};

export { authFetch };
