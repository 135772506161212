import React from "react";
import { ArticleProps } from "../../../interfaces/props/ArticleProps";
import '../../resources/css/TestCardItem.css'
import Cap from '../../resources/images/cap.png'

export const TestCardItem: React.FC<ArticleProps> = ({id, image1, title, publishedDate}) => {
    return (
        <>
            <a className="test-card d-flex flex-column" href={`/tests/article/${id}`}>
                {image1 && (
                    <img
                        src={image1 ? `${process.env.REACT_APP_SERVER_URL}${image1}` : Cap}
                        alt="Article Image"
                    />
                )}
                <div className="card-body">
                    <h6 className="card-title">{title}</h6>
                    <p className="card-text small">Дата публикации: {publishedDate}</p>
                </div>
            </a>
        </>
    )
}