import React, { useEffect, useState } from 'react'
import '../../resources/css/TyresTest.css'
import TyresTest1 from '../../resources/images/tyrestest1.png'
import TyresTest2 from '../../resources/images/tyrestest2.png'
import TyresTest3 from '../../resources/images/tyrestest3.png'
import Cap from '../../resources/images/cap.png'
import axios from 'axios'
import { ArticleProps } from '../../../interfaces/props/ArticleProps'

export const TyresTest = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [articles, setArticles] = useState<ArticleProps[]>([])
    const [lastArticles, setLastArticles] = useState<ArticleProps[]>([])
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/articles/get-articles');
                setArticles(response.data);
            } catch (error) {
                console.error('Ошибка при получении статей:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (articles.length) {
            setLastArticles(articles.slice(-3));
        }
    }, [articles]);

    // const truncateContent = (content: string, maxLines: number) => {
    //     const lines = content.split('\n'); // Разделяем текст на строки
    //     if (lines.length <= maxLines) {
    //         return content; // Если строк меньше или столько же, сколько maxLines, возвращаем весь текст
    //     }
    //     return lines.slice(0, maxLines).join('\n') + '\n...'; // Иначе возвращаем первые maxLines строк и добавляем '...'
    // };
    

    return (
        <div className='test-tyres mb-4'>
            <h3>Тесты шин</h3>
            {loading ? (
                <div className="loading-spinner d-flex justify-content-center">
                    <div className="spinner-grow text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <ul>
                    {lastArticles.map((item, index) => (
                        <li key={index}>
                            <a href={`/tests/article/${item.id}`}>
                                <img
                                    src={item.image1 ? `${process.env.REACT_APP_SERVER_URL}${item.image1}` : Cap}
                                    alt="Article Image"
                                />
                                <div>
                                    <p>{item.title}</p>
                                    {item.content1 && (
                                        <span>{item.content1}</span>
                                    )}
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
            )}
            <a href={`${process.env.REACT_APP_CLIENT_URL}/tests`} className="other-tests">
                <p>Смотреть все тесты шин</p>
                <i className="bi bi-arrow-right-circle" style={{marginBottom: '-3px'}}></i>
            </a>
        </div>
    )
}
