import React, {createContext, useEffect, useState, ReactNode} from "react";
import {CartItem} from '../../../interfaces/CartItem';

// Определение типов для контекста
// interface CartItem {
//   id: number;
//   image: string;
//   price: string;
//   vendor: string;
//   model: string;
//   quantity?: number;
//   url: string;
// }

interface AppContextProps {
    cart: CartItem[];
    setCart: (cart: CartItem[]) => void;
    addToCart: (object: any, count: number) => void;
    removeFromCart: (variantId: number) => void;
    toggleBtns: (item: any, action: "plus" | "minus") => void;
    link: string | null;
    setLink: (value: string | null) => void;
    selectedSort: string | null;
    setSelectedSort: (selectedSort: string | null) => void
}

interface AppContextProviderProps {
    children: ReactNode;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

const AppContextProvider: React.FC<AppContextProviderProps> = ({children}) => {
    const [cart, setCart] = useState<CartItem[]>(() => {
        const storedCart = localStorage.getItem("cart");
        return storedCart ? JSON.parse(storedCart) : [];
    });
    const [link, setLink] = useState<string | null>(null);
    const [selectedSort, setSelectedSort] = useState<string | null>('');

    // useEffect(() => {
    //     localStorage.setItem("cart", JSON.stringify(cart));
    // }, [cart]);

    const addToCart = (object: any, count: number) => {
        let newCart = [...cart];
        let existingProd = newCart.find(
            (item) => object.id === item.id
        );
        let filter = localStorage.getItem('filterType')
        let itemInCart: CartItem = {
            id: object.id,
            image: object.images[0],
            vendor: object.vendor.name,
            model: object.model,
            price: object.price.toFixed(0),
            url: `${process.env.REACT_APP_CLIENT_URL}/product/${object.id}`,
            supplier: object.supplierInfo,
            from: `Шина была выбрана через подбор ${filter === 'type' ? 'по типоразмеру' : `по авто ${localStorage.getItem('selectedMake')} ${localStorage.getItem('selectedModel')} ${localStorage.getItem('selectedModification')} ${localStorage.getItem('selectedYear')} `}`
        };
        if (existingProd) {
            existingProd.quantity = (existingProd.quantity || 0) + count;
        } else {
            itemInCart.quantity = count;
            newCart.push(itemInCart);
        }
        setCart(newCart);
        localStorage.setItem("cart", JSON.stringify(newCart));
    };

    const removeFromCart = (id: number) => {
        
        let newCart = [...cart];
        const index = newCart.findIndex((item) => item.id === id);
        if (index !== -1) {
            newCart.splice(index, 1);
        } else {
            console.log("Товар не найден в корзине");
        }
        setCart(newCart);
        localStorage.setItem("cart", JSON.stringify(newCart));
    };

    const toggleBtns = (item: any, action: "plus" | "minus") => {
        let newCart = [...cart];
        const product = newCart.find((obj) => obj.id === item.id);
        if (product) {
            if (action === "plus") {
                product.quantity!++;
                setCart(newCart);
                localStorage.setItem("cart", JSON.stringify(newCart));
            } else if (action === "minus") {
                if (product.quantity === 1) {
                    removeFromCart(product.id);
                } else {
                    product.quantity!--;
                    setCart(newCart);
                    localStorage.setItem("cart", JSON.stringify(newCart));
                }
            }
        } else {
            console.log("Товар не найден в корзине");
        }
    };

    return (
        <AppContext.Provider
            value={{
                cart,
                setCart,
                addToCart,
                removeFromCart,
                toggleBtns,
                link,
                setLink,
                selectedSort, 
                setSelectedSort
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
