import React, {useContext, useEffect, useState} from "react";
import Thorns from "../../resources/images/Thorns.svg";
import Demi from "../../resources/images/Demi.svg";
import Winter from "../../resources/images/Winter.svg";
import Summer from "../../resources/images/Summer.svg";
import White from "../../resources/images/White.png";
import {MiniCardBodyProps} from "../../../interfaces/props/MiniCardBodyProps";
import {AppContext} from "../context/AppContext";
import {useNavigate} from "react-router-dom";


export const MiniCardBody: React.FC<MiniCardBodyProps> = ({
                                                              id,
                                                              season,
                                                              imageUrl,
                                                              title,
                                                              text1,
                                                              price,
                                                              buttonText,
                                                              handleRemoveItemById,
                                                              product
                                                          }) => {


    const [inCart, setInCart] = useState<boolean>(false);
    const context = useContext(AppContext);
    const navigate = useNavigate();

    if (!context) {
        throw new Error('MiniCardBody must be used within an AppContextProvider');
    }

    const {cart, addToCart} = context;

    useEffect(() => {
        setInCart(cart.some((item: any) => item.id === id));
    }, [cart, id]);

    function handleSeasonCheck(season: string): string {
        switch (season) {
            case 'Thorns':
                return Thorns as string; ///todo add шипы
            case 'всесезонные':
                return Demi as string;
            case 'зимние':
                return Winter as string;
            case 'летние':
                return Summer as string;
        }
        return White as string;
    }

    return (
        <div>
            <div className='icons-card'>
                <img src={handleSeasonCheck(season)} alt="Сезон"/>
                <button type="button" className="btn-close fs-5"
                        onClick={() => handleRemoveItemById(id)}></button>
            </div>
            <img src={imageUrl} className="product-image cursor-pointer" alt=""  onClick={() => navigate(`/product/${id}`)}/>
            <div className="description cursor-pointer" onClick={() => navigate(`/product/${id}`)}>
                <p className="fw-semibold">{title}</p>
                <span className="m-top-5">{text1}</span>
            </div>
            <p className="fw-semibold m-top-15 cursor-pointer"  onClick={() => navigate(`/product/${id}`)}>{price} руб.</p>
            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {inCart ? (
                    <button id="incart" className="btn btn-warning --orange-btn btn-comp m-top-15 ms-1 " onClick={() => navigate('/cart')}>
                        Добавлено
                    </button>
                ) : (
                    <button className="btn btn-warning --orange-btn btn-comp m-top-15"
                            onClick={() => addToCart(product, 1)}>
                        {buttonText}
                    </button>
                )}
            </div>

        </div>
    )
}