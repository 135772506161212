import {Breadcrumbs} from "../components/Breadcrumbs";
import React from "react";
import {AskForm} from "../components/AskForm";
import '../../resources/css/ContactsPage.css';

const BREADCRUMB_ITEMS = [
    {label: 'Главная', href: '/'},
    {label: 'Контакты', href: '/contacts'},
];

export const ContactsPage = () => {
    return (
        <>
            <Breadcrumbs items={BREADCRUMB_ITEMS}/>
            <div className="contacts-page">
                <h3 className="mb-4">Контакты</h3>

                <div className="row ">
                    <div className="col-sm-3 list-contacts">
                        <div>
                            <h4>Телефоны:</h4>
                            <div>
                                <p>{process.env.REACT_APP_FIRST_PHONE}</p>
                                <p>{process.env.REACT_APP_SECOND_PHONE}</p>
                            </div>
                        </div>
                        <div>
                            <h4>Режим работы:</h4>
                            <p>{process.env.REACT_APP_OPERATING_MODE}</p>
                        </div>
                        <div>
                            <h4>Адрес:</h4>
                            <p>Минск, улица Одинцова, 20Б</p>
                        </div>
                        <div className="mb-3">
                            <h4>Почта:</h4>
                            <p>{process.env.REACT_APP_COMPANY_EMAIL}</p>
                        </div>
                    </div>
                    <div className="col-sm-9 mb-5 map-block">
                        <iframe
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3A91fe97a897695900c7a7a16632f3918f23ef481af0c7217ce515890059040c7e&amp;source=constructor"/>
                    </div>
                </div>
            </div>
            <AskForm/>
        </>
    )
}