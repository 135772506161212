import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Filter } from "../components/Filter";
import { Catalog } from "../components/Catalog";
import { useLocation, useNavigate } from "react-router-dom";
import "../../resources/css/Catalog.css";

interface TiresPageProps {
  categoryReq: string;
}

export const TiresPage: React.FC<TiresPageProps> = ({ categoryReq }) => {
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   if (!searchParams.get('category') || !searchParams.get('page')) {
  //     searchParams.set('category', searchParams.get('category') || 'passenger');
  //     searchParams.set('page', searchParams.get('page') || '1');
  //     navigate({
  //       pathname: location.pathname,
  //       search: searchParams.toString()
  //     }, { replace: true });
  //   }
  // }, [location, navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // const categoryReq = queryParams.get('category');

    if (categoryReq) {
      setSelectedCategory(categoryReq);

      switch (categoryReq) {
        case "passenger":
          setName("Легковые шины");
          break;
        case "truck":
          setName("Грузовые шины");
          break;
        case "agriculture":
          setName("Сельхоз шины");
          break;
        case "moto":
          setName("Мотоциклетные шины");
          break;
        default:
          setName("Легковые шины");
      }
    }

    if (selectedCategory) {
    }
  }, [location.search]);

  const BreadcrumbItems = [
    { label: "Главная", href: "/" },
    {
      label: `${name}`,
      href: `/products/${
        selectedCategory === "passenger"
          ? "legkovye-shiny"
          : selectedCategory === "truck"
          ? "gruzovye-shiny"
          : selectedCategory === "agriculture"
          ? "selhoz-shiny"
          : selectedCategory === "moto"
          ? "moto-shiny"
          : "legkovye-shiny"
      }`,
    },
  ];

  return (
    <div>
      <Breadcrumbs items={BreadcrumbItems} />
      <h1
        className="tires-title"
        style={{
          fontSize: "26px",
          fontWeight: "400",
        }}
      >
        Подбор{" "}
        {name === "Легковые шины"
          ? "легковых шин"
          : name === "Грузовые шины"
          ? "грузовых шин"
          : name === "Сельхоз шины"
          ? "сельхоз шин"
          : name === "Шины для спецтехники"
          ? "шин для спецтехники"
          : name === "Мотоциклетные шины"
          ? "мотоциклетных шин"
          : ""}
      </h1>
      <Filter
        name={name}
        loading={loading}
        setLoading={setLoading}
        category={categoryReq}
        isFiltered={isFiltered}
        setIsFiltered={setIsFiltered}
      />
      <Catalog
        name={name}
        loading={loading}
        setLoading={setLoading}
        category={categoryReq}
        isFiltered={isFiltered}
        setIsFiltered={setIsFiltered}
      />
    </div>
  );
};
