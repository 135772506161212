import {Breadcrumbs} from "../components/Breadcrumbs";
import BlueTruck from "../../resources/images/BlueTruck.svg"
import BlueMoney from "../../resources/images/BlueMoney.svg"
import BlueNote from "../../resources/images/BlueNote.svg"
import BlueHandshake from "../../resources/images/BlueHandshake.svg"
import {AskForm} from "../components/AskForm";

const BREADCRUMB_ITEMS = [
    {label: 'Главная', href: '/'},
    {label: 'Доставка и оплата', href: '/delivery'},
];

export const PaymentDeliveryPage = () => {
    return (
        <>
            <Breadcrumbs items={BREADCRUMB_ITEMS}/>

            <h3>Доставка и оплата</h3>
            <div className="--blue-big-card">
                <h4 className="fw-bold d-flex align-items-center">
                    <img src={`${BlueTruck}`} alt="..." style={{paddingRight: 10}}/>
                    <span>Доставка</span>
                </h4>
                <p className="text-black">Доставка товара возможна:</p>
                <ul className="--orange-ul">
                    <li><span>В магазин TopTyre</span></li>
                    <li><span>По адресу покупателя</span></li>
                </ul>

                <p className="text-black">Стоимость доставки:</p>
                <ul className="--orange-ul">
                    <li><span>Бесплатная доставка по Беларуси при покупке комплекта колёс бренда Kumho и Arivo (комплект 4 колеса ).</span>
                    </li>
                    <li><span>Бесплатная доставка по Минску при покупке комплекта колёс (комплект 4 колеса на сумму не менее 600 BYN).</span>
                    </li>
                    <li><span>За пределы МКАД до 5 км 5-10 BYN, в зависимости от суммы заказа.</span>
                    </li>
                    <li><span>Если вы находитесь вне этой зоны, доставка осуществляется платно, через транспортную компанию, стоимость доставки (R12 - R17  1-2 шины 25 BYN, 3-4 шины 30 BYN; R18 – R20 1-2 шины 30 BYN, 3-4 шины 38 BYN; R21-R24 1-2 шины 35 BYN, 3-4 шины 50 BYN)</span>
                    </li>

                </ul>

                <p>При получении товара обязательно произведите проверку заказа по количеству и качеству: убедитесь в
                    комплектности заказа, соответствии выдаваемого и заказанного товара; произведите визуальный осмотр
                    на предмет отсутствия дефектов товара.</p>
                <p>Выдача заказов с оплатой при получении производится без предъявления документа, удостоверяющего
                    личность.</p>
                <p>После получения заказа Вам выдадут кассовый чек (накладную) и гарантийный талон.</p>
            </div>
            <div className="--blue-big-card">
                <h4 className="fw-bold d-flex align-items-center">
                    <img src={`${BlueMoney}`} alt="..." style={{paddingRight: 10}}/>
                    <span>Способы оплаты</span>
                </h4>

                <ul className="--orange-ul">
                    <li><span><b>Наличными</b></span></li>
                    <li><span><b>Банковской картой: </b>Оплатить картой через терминал можно в пункте самовывоза г. Минск, ул. Одинцова 20б.</span></li>
                    <li><span><b>Система «Расчет» (ЕРИП): </b>Оплата через систему «Расчет» (ЕРИП) позволяет оплатить покупку со своего карт-счета, используя интернет-банк, либо банкоматы и инфокиоски многих банков РБ. Рассчитаться наличными через систему «Расчет» (ЕРИП) также возможно, обратившись в кассы банков либо воспользовавшись устройствами приема наличных денег (cash-in). При оплате покупок в нашем интернет-магазине Вам понадобится указать номер заказа. Этот номер необходимо уточнить у нашего менеджера по телефону.</span></li>
                    <li><span><b>Безналичный расчет для юридических лиц: </b> Заполните форму с реквизитами и мы сформируем для Вас счет на оплату.</span></li>
                </ul>
            </div>
            <div className="--blue-big-card">
                <h4 className="fw-bold d-flex align-items-center">
                    <img src={`${BlueNote}`} alt="..." style={{paddingRight: 10}}/>
                    <span>Возврат товара</span>
                </h4>

                <ul className="--orange-ul">
                    <li><span>При обращении покупателя (физического лица), основанного на правах потребителя, по вопросам приобретённых им товаров и/или услуг, в том числе по возврату товара надлежащего качества, продавец руководствуется Законом Республики Беларусь «О защите прав потребителей» от 9 января 2002 г. № 90-З.</span>
                    </li>
                    <li><span>Покупатель вправе в течение четырнадцати дней с момента передачи ему товара в месте приобретения товара возвратить товар надлежащего качества или обменять его на аналогичный товар, произведя в случае разницы в цене необходимый перерасчет с продавцом.</span>
                    </li>
                    <li><span>Требование покупателя об обмене либо возврате товара подлежит удовлетворению, если товар не был в употреблении, сохранены его потребительские свойства и имеются доказательства приобретения его у данного продавца. В случае обмена либо возврата товара покупатель обязан возвратить товар в целостной потребительской упаковке, если товар был продан в такой упаковке.</span>
                    </li>
                    <li><span>При возврате покупателем товара надлежащего качества его требование о возврате уплаченной за товар денежной суммы подлежит удовлетворению продавцом незамедлительно. В случае, если удовлетворить требование покупателя незамедлительно не представляется возможным, максимальный срок для удовлетворения требования не может превышать семи дней.</span>
                    </li>
                    <li><span>Расчеты с покупателем при возврате уплаченной за товар денежной суммы осуществляются в той же форме, в которой производилась оплата товара.</span>
                    </li>
                    <li><span>Продавец освобождается от ответственности за применимость приобретенного товара к автомобилю покупателя в связи с непредоставлением покупателем полных исходных данных транспортного средства, для которого товар приобретается. Продавец не отвечает за убытки покупателя, возникшие в результате предоставления не полной или не достоверной информации, в том числе неполного или неправильного указания идентификационных сведений.</span>
                    </li>
                    <li><span>В случае, когда покупателем выступает юридическое лицо или индивидуальный предприниматель, возврат товара надлежащего качества не предусмотрен.</span>
                    </li>
                </ul>
            </div>
            <div className="--blue-big-card">
                <h4 className="fw-bold d-flex align-items-center">
                    <img src={`${BlueHandshake}`} alt="..." style={{paddingRight: 10}}/>
                    <span>Замена товара ненадлежащего качества</span>
                </h4>

                <ul className="--orange-ul">
                    <li><span>В случае обнаружения покупателем в течение действия гарантийного срока недостатков товара и предъявления требования о замене такого товара продавец обязан заменить такой товар, а при необходимости дополнительной проверки качества такого товара продавцом – заменить его в течение четырнадцати дней со дня предъявления указанного требования.</span>
                    </li>
                    <li><span>При отсутствии у продавца необходимого для замены товара на день предъявления покупателем требования о его замене продавец должен заменить такой товар в течение месяца со дня предъявления указанного требования. В случае отсутствия у продавца товара данной модели (марки, типа, артикула и др.) по не зависящим от него причинам в течение месяца со дня предъявления требования покупателем продавец обязан предоставить ему аналогичный товар другой модели (марки, типа, артикула и др.) с согласия потребителя либо осуществить возврат уплаченной за товар денежной суммы.</span>
                    </li>
                </ul>
            </div>
            <AskForm/>
        </>
    )
}