import React from "react";
import {BreadcrumbProps} from "../../../interfaces/props/BreadcrumbProps";

export const Breadcrumbs: React.FC<BreadcrumbProps> = ({items}) => {
    return (
        <nav aria-label="breadcrumb" className="breadcrumbs">
            <ol className="breadcrumb breadcrumb-chevron pb-3">
                {items.map((item, index) => (
                    <li
                        key={index}
                        className={`breadcrumb-item ${index === items.length - 1 ? 'active' : ''}`}
                        aria-current={index === items.length - 1 ? 'page' : undefined}
                    >
                        {item.href ? (
                            <a href={item.href}
                               className={"--orange-hover text-decoration-none text-muted"}
                               style={{
                                   color: '#8F8F8F !important',
                                   fontSize: '14px',
                                   fontWeight: '400'
                               }}>{item.label}</a>
                        ) : (
                            item.label
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};