import '../../resources/css/MiniCard.css'
import 'swiper/swiper-bundle.css';
import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Pagination, Navigation} from 'swiper/modules';
import {MiniCardBody} from "./MiniCardBody";
import {ComparisonArray} from "../../../interfaces/ComparisonArray";
import {ProductProps} from "../../../interfaces/props/ProductProps";

export const MiniCard: React.FC<ComparisonArray> = ({tyres, onTyreChange}) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [buttonText, setButtonText] = useState("Добавить в корзину");

    const handleSlideChange = (swiper: any) => {
        const newIndex = swiper.activeIndex;
        setSelectedIndex(newIndex);
        onTyreChange(tyres[newIndex]);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 711) {
                setButtonText("В корзину");
            } else {
                setButtonText("Добавить в корзину");
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function handleRemoveItemById(itemId: number) {
        const storedData = sessionStorage.getItem('__product-data-comparison');
        let currentData = storedData ? JSON.parse(storedData) : [];

        const updatedTyres = currentData.filter((tyre: ProductProps) => tyre.id !== itemId);

        if (updatedTyres.length === currentData.length) {
            alert("Элемент не найден для удаления");
            return;
        }

        if (updatedTyres.length === 0) {
            sessionStorage.removeItem('__product-data-comparison');
        } else {
            currentData = updatedTyres;
            sessionStorage.setItem('__product-data-comparison', JSON.stringify(currentData));
        }

        window.location.reload();
    }

    return (
        <>
            {tyres.length === 1 ? (
                <>
                    <div className="mini-card --purple-card first">
                        <MiniCardBody
                            season={tyres[0].filters.find(filter => filter.id === 8631)?.values[0].name as string}
                            imageUrl={tyres[0].images[0]}
                            title={tyres[0].model}
                            text1={tyres[0].vendor.name}
                            price={tyres[0].price}
                            buttonText={buttonText}
                            handleRemoveItemById={handleRemoveItemById}
                            id={tyres[0].id}
                            product={tyres[0]}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="mini-card --purple-card">
                        <Swiper
                            pagination={{
                                type: 'fraction',
                                renderBullet: (index: number, className: string) => {
                                    return `<span class="${className}">${index + 1}</span>`;
                                },
                            }}
                            navigation={true}
                            onSlideChange={handleSlideChange}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {tyres.map((product, index) => (
                                <SwiperSlide key={index} className="slide">
                                    <MiniCardBody
                                        season={product.filters.find(filter => filter.id === 8631)?.values[0].name as string}
                                        imageUrl={product.images[0]}
                                        title={product.vendor.name}
                                        text1={product.model}
                                        price={product.price}
                                        buttonText={buttonText}
                                        handleRemoveItemById={handleRemoveItemById}
                                        id={product.id}
                                        product={product}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </>
            )}
        </>
    )
}
