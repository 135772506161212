import React from 'react';

export const handleVerificationCodeChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
    verificationCode: string[],
    setVerificationCode: (code: string[]) => void,
    inputsRef: React.RefObject<(HTMLInputElement | null)[]>
) => {
    const value = event.target.value;

    if (/^\d?$/.test(value)) {
        const newCode = [...verificationCode];
        newCode[index] = value;
        setVerificationCode(newCode);

        if (value && index < (inputsRef.current as (HTMLInputElement | null)[]).length - 1) {
            (inputsRef.current as (HTMLInputElement | null)[])[index + 1]?.focus();
        }
    }
};