import React, { useEffect, useState } from 'react'
import '../../resources/css/TyresChoice.css'
import TyresSize from '../../resources/images/tyres-size.png'
import Spike from '../../resources/images/spike.png'
import Model from '../../resources/images/model.png'
import App from '../../resources/images/app.png'

export const TyresChoice = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    return (
        <div className='choice-container'>
            {isMobile ? (
                <ul>
                    <li id='first'>
                        <p className='list-number'>1</p>
                        <div style={{height: '100%', display: 'flex', alignItems: "end"}}>
                            <div className='vertical'></div>
                        </div>
                        <div className='text-point'>
                            <h2>Проверьте типоразмер.</h2>
                            <span>Прочитайте типоразмер на боковине летних
                                шин, которые у вас установлены, и сверьте с
                                табличкой на водительской двери. Типоразмер
                                выглядит, например, вот так: 205/55 R16 . Если
                                цифры совпали – отлично, выбирайте такой
                                же! Нет совпадений – у вас установлен
                                нештатный размер, нужно дополнительно
                                проконсультироваться с менеджером.</span>
                        </div>
                    </li>
                    <li id='second'>
                        <p className='list-number'>2</p>
                            <div className='horizontal'></div>
                            <div className='img-block'>
                                <div className='text-point'>
                                    <h2>Шипованные или фрикционные?</h2>
                                    <span>Оцените дороги, по которым вам чаще
                                        придется ездить. Шипованные шины в
                                        большинстве случаев безопаснее в гололед,
                                        зато фрикционные подарят вам тишину и
                                        комфорт передвижения по асфальту.</span>
                                </div>
                                <div className='vertical'></div>
                            </div>
                    </li>
                    <li id='third'>
                        <p className='list-number'>3</p>
                        <div className='img-block-wrap'>
                            <div className='horizontal'></div>
                            <div className='img-block'>
                                <div className='vertical'></div>
                                <div className='text-point'>
                                    <h2>Выбираем марку и модель.</h2>
                                    <span>Определить на глаз или на ощупь, какая шина
                                            лучше, не получится. За советом лучше
                                            обратиться к профессионалам, которые
                                            хорошо ориентируются на рынке,
                                            сотрудничают с проверенными
                                            производителями и получают от них
                                            информацию об особенностях всех моделей
                                            покрышек.</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li id='fourth'>
                        <p className='list-number'>4</p>
                        <div className='horizontal'></div>
                        <div className='img-block'>
                            <div className='text-point'>
                                <h2>Надежный способ убедиться в качестве и
                                    легальности товара – проверка кода
                                    маркировки в приложении Електронный ЗНАК.</h2>
                            </div>
                            <div className='vertical'></div>
                        </div>
                    </li>
                </ul>
            ) : (
                <>
                    <h1>Как выбрать зимнюю резину?</h1>
                    <ul>
                        <li id='first'>
                            <p className='list-number'>1</p>
                            <div style={{height: '100%', display: 'flex', alignItems: "end"}}>
                                <div className='vertical'></div>
                            </div>
                            <div className='text-point'>
                                <h2>Проверьте типоразмер.</h2>
                                <span>Прочитайте типоразмер на боковине летних
                                    шин, которые у вас установлены, и сверьте с
                                    табличкой на водительской двери. Типоразмер
                                    выглядит, например, вот так: 205/55 R16 . Если
                                    цифры совпали – отлично, выбирайте такой
                                    же! Нет совпадений – у вас установлен
                                    нештатный размер, нужно дополнительно
                                    проконсультироваться с менеджером.</span>
                            </div>
                            <img src={TyresSize} alt=""/>
                        </li>
                        <li id='second'>
                            <p className='list-number'>2</p>
                            <div className='img-block-wrap'>
                                <div className='horizontal'></div>
                                <div className='img-block'>
                                    <img src={Spike} alt=""/>
                                    <div className='vertical'></div>
                                </div>
                            </div>
                            <div className='text-point'>
                                <h2>Шипованные или фрикционные?</h2>
                                <span>Оцените дороги, по которым вам чаще
                                    придется ездить. Шипованные шины в
                                    большинстве случаев безопаснее в гололед,
                                    зато фрикционные подарят вам тишину и
                                    комфорт передвижения по асфальту.</span>
                            </div>
                        </li>
                        <li id='third'>
                            <p className='list-number'>3</p>
                            <div className='img-block-wrap'>
                                <div className='horizontal'></div>
                                <div className='img-block'>
                                    <div className='vertical'></div>
                                    <div className='text-point'>
                                        <h2>Выбираем марку и модель.</h2>
                                        <span>Определить на глаз или на ощупь, какая шина
                                                лучше, не получится. За советом лучше
                                                обратиться к профессионалам, которые
                                                хорошо ориентируются на рынке,
                                                сотрудничают с проверенными
                                                производителями и получают от них
                                                информацию об особенностях всех моделей
                                                покрышек.</span>
                                    </div>
                                </div>
                            </div>
                            <img src={Model} alt=""/>
                        </li>
                        <li id='fourth'>
                            <p className='list-number'>4</p>
                            <div className='img-block-wrap'>
                                <div className='horizontal'></div>
                                <div className='img-block'>
                                    <img src={App} alt=""/>
                                    <div className='vertical'></div>
                                </div>
                            </div>
                            <div className='text-point'>
                                <h2>Надежный способ убедиться в качестве и
                                    легальности товара – проверка кода
                                    маркировки в приложении Электронный ЗНАК.</h2>
                            </div>
                        </li>
                    </ul>
                </>
            )}
        </div>
    )
}
