import {DataMessage} from "../../../../interfaces/DataMessage";

export async function fetchDataVerificationCode(
    resetInputMail: string,
    verificationCode: string[],
    setErrorMessage: (message: string) => void,
    showResetPassword: (show: boolean) => void
): Promise<void> {
    try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + '/api/auth/verify-code', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: resetInputMail,
                verificationCode: verificationCode.join(''),
            }),
        });
        const data: DataMessage = await response.json();
        if (!response.ok) {
            setErrorMessage(data.message);
            return;
        }
        showResetPassword(true);
        setErrorMessage('');
    } catch (error: any) {
        setErrorMessage(error.message);
    }
}