import React from "react";
import { SmallProductCardProps } from "../../../interfaces/props/SmallProductCardProps";
import Summer from '../../resources/images/Summer.svg'
import Winter from '../../resources/images/Winter.svg'
import Demi from '../../resources/images/Demi.svg'
import Thorns from '../../resources/images/Thorns.svg'
import White from "../../resources/images/White.png";
import {useNavigate} from "react-router-dom";


export const SmallProductCard: React.FC<SmallProductCardProps> = ({ id, imageUrl, title, text, price, season }) => {
    const navigate = useNavigate();

    let imageSrc: {};

    switch (season) {
        case 'Thorns': imageSrc = Thorns; break;
        case 'всесезонные':   imageSrc = Demi;   break;
        case 'зимние': imageSrc = Winter; break;
        case 'летние': imageSrc = Summer; break;
        default: imageSrc = White;
    }

    const formattedPrice = Number(price).toFixed(2);
    const [rubles, kopecks] = formattedPrice.split('.');
    
    const multiplier = title === 'Arivo' || title === 'Kumho' ? 1.25 : 1.15
    const beforeSalePrice = (Number(price) * multiplier).toFixed(2);
    const [rublesSale, kopecksSale] = beforeSalePrice.split('.');

    const handleClick = () => {
        // Переход по ссылке с перезагрузкой страницы
        window.location.href = `/product/${id}`;
    };

    return (
        <div className="card col" onClick={handleClick} style={{cursor: 'pointer'}}>
            <img src={imageSrc as string} className="position-absolute" style={{maxWidth: 30}} alt="..."/>
            <img src={imageUrl} className="card-img-top" alt="..." />
            <div className="card-body">
                <div>
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{text}</p>
                </div>
                <div>
                    <div className="sale-block">
                        <p className="sale-p">СКИДКА {title === 'Arivo' || title === 'Kumho' ? '25%' : '15%'}</p>
                        <div className="salePrice">
                            <p style={{fontWeight: 500, fontSize: '15px'}}>{rublesSale},{kopecksSale}</p>
                        </div>
                    </div>
                    <h4 className="card-text fw-bold">{rubles},{kopecks} руб.</h4>
                </div>
            </div>
        </div>
    );
};
