import {Breadcrumbs} from "../components/Breadcrumbs";
import About1 from "../../resources/images/about1.png"
import About2 from "../../resources/images/about2.png"
import React from "react";
import {AskForm} from "../components/AskForm";

const BREADCRUMB_ITEMS = [
    {label: 'Главная', href: '/'},
    {label: 'О нас', href: '/about'},
];

export const AboutPage = () => {
    return (
        <>
            <Breadcrumbs items={BREADCRUMB_ITEMS}/>
            <div className="about-page">
                <h3 className="mb-4">О нас</h3>

                <div className="d-flex mb-4 row">
                    <div className="col-md-7">
                        <p className="fs-5 mb-2">
                            Компания "TopTyre" специализируется на продаже шин и дисков для автомобилей всех марок и
                            моделей. Мы понимаем, насколько важен выбор правильных шин для безопасности и комфорта
                            вождения, поэтому предлагаем широкий ассортимент продукции от ведущих мировых
                            производителей.
                        </p>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-4">
                        <img src={`${About1}`} alt="..." className="w-100"/>
                    </div>
                </div>
                <div className="d-flex mb-2 row">
                    <div className="col-md-7">
                        <h5 className="fw-bold">Наши преимущества:</h5>

                        <ul className="--orange-ul">
                            <li><span><b>Широкий ассортимент: </b>У нас вы найдете шины для легковых автомобилей, внедорожников, грузовиков, а также специализированные модели для спортивных машин и зимних условий.</span></li>
                            <li><span><b>Качество продукции: </b>Мы работаем только с проверенными брендами, что гарантирует долговечность и надежность наших товаров.</span></li>
                            <li><span><b>Профессиональная консультация: </b>Наша команда опытных специалистов всегда готова помочь вам выбрать идеальные шины и диски, исходя из ваших потребностей и условий эксплуатации.</span></li>
                            <li><span><b>Доступные цены: </b>Мы предлагаем конкурентоспособные цены и регулярные акции, чтобы сделать качественные шины доступными для каждого автовладельца.</span></li>
                            <li><span><b>Услуги по установке: </b>В нашем сервисном центре вы можете воспользоваться услугами по установке и балансировке шин, что обеспечит вам максимальный комфорт и безопасность на дороге.</span></li>
                            <li><span><b>Онлайн-магазин: </b>Удобный интерфейс нашего сайта позволяет легко выбрать и заказать шины и диски с доставкой на дом или в наш пункт самовывоза.</span></li>
                        </ul>

                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-4">
                        <img src={`${About2}`} alt="..." className="w-100"/>
                    </div>
                </div>
            </div>
            <AskForm/>
        </>
    )
}