import React, {useContext, useEffect, useState} from "react";
import "../../resources/css/Card.css";
import Summer from "../../resources/images/Summer.svg";
import Winter from "../../resources/images/Winter.svg";
import Demi from "../../resources/images/Demi.svg";
import Vector from "../../resources/images/vector.svg";
import Info from "../../resources/images/info.svg";
import Garranty from "../../resources/images/garranty.svg";
import {ProductProps} from '../../../interfaces/props/ProductProps'
import {AppContext} from "../context/AppContext";
import useComparison from "../../utils/handles/useComparison";
import {ComparisonAlert} from "./ComparisonAlert";

interface CardProps {
    product: ProductProps;
    

}

export const Card: React.FC<CardProps> = ({product}) => {
    const [seasonality, setSeasonality] = useState<string>('');
    const [width, setWidth] = useState<string>('');
    const [height, setHeight] = useState<string>('');
    const [diameter, setDiameter] = useState<string>('');
    const [loadIndex, setLoadIndex] = useState<string>('');
    const [speedIndex, setSpeedIndex] = useState<string>('');
    const [construction, setConstruction] = useState<string>('');
    const [inCart, setInCart] = useState<boolean>(false);

    const {
        isChecked,
        handleCheckboxChange,
        showAlert,
        setShowAlert,
    } = useComparison(product.id);

    const context = useContext(AppContext);

    if (!context) {
        throw new Error('Catalog must be used within an AppContextProvider');
    }

    const {cart, addToCart} = context;

    useEffect(() => {
        setInCart(cart.some((item) => item.id === product.id));
    }, [cart, product.id]);

    useEffect(() => {
        product.filters.forEach((item) => {
            switch (item.name) {
                case 'Сезонность':
                    setSeasonality(item.values[0].name);
                    break;
                case 'Ширина профиля':
                    setWidth(item.values[0].name);
                    break;
                case 'Высота профиля':
                    setHeight(item.values[0].name);
                    break;
                case 'Диаметр':
                    setDiameter(item.values[0].name);
                    break;
                case 'Индекс нагрузки':
                    setLoadIndex(item.values[0].name);
                    break;
                case 'Индекс максимальной скорости':
                    setSpeedIndex(item.values[0].name);
                    break;
                default:
                    break;
            }
        });
    }, [product]);

    // Преобразуем цену в формат с двумя знаками после запятой и разделяем на рубли и копейки
    const formattedPrice = Number(product.price).toFixed(2);
    const [rubles, kopecks] = formattedPrice.split('.');
    
    const multiplier = product.vendor.name === 'Arivo' || product.vendor.name === 'Kumho' ? 1.25 : 1.15
    const beforeSalePrice = (Number(product.price) * multiplier).toFixed(2);
    const [rublesSale, kopecksSale] = beforeSalePrice.split('.');

    return (
        <>
            <ComparisonAlert
                show={showAlert}
                onClose={() => setShowAlert(false)}
                message=""
                buttonText="Перейти к сравнению"
                buttonLink="/comparison"
            />
            <div className="card-product">
                <div className="card-wrapper">
                    <a href={`${process.env.REACT_APP_CLIENT_URL}/product/${product.id}`}>
                        <div className="card-image">
                            <img className="sun-image"
                                 src={seasonality === 'летние' ? Summer : seasonality === 'зимние' ? Winter : Demi}
                                 alt=""/>
                            <img className="tyre-img" src={product.images[0]} alt="Tyre"/>
                        </div>
                    </a>
                    <div className="model-info">
                        <a href={`https://toptyre.by/product/${product.id}`}>
                            <div className="model-name">
                                <p>{product.vendor.name}</p>
                                <span>{product.model}</span>
                            </div>
                        </a>
                        <div className="card-truck-info">
                        <div>
                            <img src={Garranty} alt="" />
                            <span>Гарантия</span>
                        </div>
                    </div>
                    </div>
                    <div className="product-info">
                        <ul>
                            <li>
                                <div>Сезонность</div>
                                <span>{seasonality}</span>
                            </li>
                            <li>
                                <div>Ширина профиля</div>
                                <span>{width} мм</span>
                            </li>
                            <li>
                                <div>Высота профиля</div>
                                <span>{height} мм</span>
                            </li>
                            <li>
                                <div>Диаметр</div>
                                <span>{diameter}</span>
                            </li>
                            <li>
                                <div>Индекс нагрузки</div>
                                <span>{loadIndex}</span>
                            </li>
                            <li>
                                <div>Индекс максимальной скорости</div>
                                <span>{speedIndex}</span>
                            </li>
                            <li>
                                <div>Конструкция</div>
                                <span>{construction}</span>
                            </li>
                        </ul>
                        <a href={`https://toptyre.by/product/${product.id}`} className="other-info">
                            <p>Смотреть все характеристики товара</p>
                            <img src={Vector} alt=""/>
                        </a>
                    </div>
                    <div className="product-price">
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
                            <div className="sale-block">
                                <p className="sale-p">СКИДКА {product.vendor.name === 'Arivo' || product.vendor.name === 'Kumho' ? '25%' : '15%'}</p>
                                <div className="salePrice">
                                    <p style={{fontWeight: 500, fontSize: '19px'}}>{rublesSale}</p>
                                    <span>{kopecksSale}</span>
                                </div>
                            </div>
                            <div className="price">
                                <p>{rubles}</p>
                                <div>
                                    <span>{kopecks}</span>
                                    <span className="currency">руб.</span>
                                </div>
                            </div>
                            <p className="price-text">Цена за шт.</p>
                        </div>
                        <div className="order-btn-block">
                            <div className="garanty-text">
                                <p>Гарантия {product.vendor.name === 'Kumho' || product.vendor.name === 'Arivo' ? '5 лет' : '2 года'}</p>
                                <img src={Info} alt=""/>
                            </div>
                                {inCart ? (
                                    <a href="https://toptyre.by/cart">
                                        <button id="incart" className="btn btn-warning --orange-btn btn-price">
                                            Добавлено
                                        </button>
                                    </a>
                                ) : (
                                    <button className="btn btn-warning --orange-btn btn-price"
                                            onClick={() => addToCart(product, 4)}>
                                        В корзину
                                    </button>
                                )}
                            <div className="comparison-block">
                                <input type="checkbox" checked={isChecked}
                                       onChange={(event) => handleCheckboxChange(event, product)}/>
                                <p>Сравнить</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
