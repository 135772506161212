import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ArticleProps } from '../../../interfaces/props/ArticleProps';
import Cross from '../../resources/images/cross.svg'
import { IoInformationCircleOutline } from "react-icons/io5";
import '../../resources/css/AdminPanel.css';

interface ArticleFormProps {
    action: string;
    isAdmined: boolean;
    setIsAdmined: (isAdmined: boolean) => void;
}

const ArticleForm: React.FC<ArticleFormProps> = ({ action, isAdmined, setIsAdmined }) => {
    const [articles, setArticles] = useState<ArticleProps[]>([]);
    const [selectedArticle, setSelectedArticle] = useState<ArticleProps | null>(null);
    const [title, setTitle] = useState<string>('');
    const [content1, setContent1] = useState<string>('');
    const [content2, setContent2] = useState<string>('');
    const [content3, setContent3] = useState<string>('');
    const [manufacturer, setManufacturer] = useState<string>('');
    const [image1, setImage1] = useState<File | null>(null);
    const [image2, setImage2] = useState<File | null>(null);
    const [image3, setImage3] = useState<File | null>(null);
    const [imagePreview1, setImagePreview1] = useState<string | null>(null);
    const [imagePreview2, setImagePreview2] = useState<string | null>(null);
    const [imagePreview3, setImagePreview3] = useState<string | null>(null);
    const [season, setSeason] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [brands, setBrands] = useState<any>([])
    const [loadingFilter, setLoadingFilter] = useState<boolean>(true);

    const fetchArticles = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/articles/get-articles');
            const brandsResponse = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/carfilter/get-brands')
            setArticles(response.data);
            setBrands(brandsResponse.data)
        } catch (error) {
            console.error('Ошибка при получении статей:', error);
        } finally {
            setLoadingFilter(false);
        }
    };

    useEffect(() => {
            if (selectedArticle && action === 'edit') {
                setTitle(selectedArticle.title || '');  // Если нет данных, ставим пустую строку
                setContent1(selectedArticle.content1 || '');
                setContent2(selectedArticle.content2 || '');
                setContent3(selectedArticle.content3 || '');
                setManufacturer(selectedArticle.manufacturer || '');
                setSeason(selectedArticle.season || '');
            }
            if (action !== 'edit') {
                setTitle('');
                setContent1('');
                setContent2('');
                setContent3('');
                setManufacturer('');
                setSeason('');
                setImage1(null);
                setImage2(null);
                setImage3(null);
                setImagePreview1(null);
                setImagePreview2(null);
                setImagePreview3(null);
            }
    }, [selectedArticle, action]);

    useEffect(() => {
        fetchArticles();
    }, []);

    useEffect(() => {
        // Если статьи загружены и режим редактирования, выбираем первую статью
        if (articles.length > 0 && action === 'edit') {
            setSelectedArticle(articles[0]);
        }
    }, [articles, action]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (!allowedTypes.includes(file.type)) {
                alert('Неправильный тип файла. Допустимы только JPEG, PNG и GIF.');
                e.target.value = '';
                return;
            }
            // Сохраняем файл в соответствующую переменную состояния и создаем превью
            switch (index) {
                case 1:
                    setImage1(file);
                    setImagePreview1(URL.createObjectURL(file));
                    break;
                case 2:
                    setImage2(file);
                    setImagePreview2(URL.createObjectURL(file));
                    break;
                case 3:
                    setImage3(file);
                    setImagePreview3(URL.createObjectURL(file));
                    break;
                default:
                    break;
            }
            e.target.value = '';
        }
    };

    const removeImage = (index: number) => {
        switch (index) {
            case 1:
                setImage1(null);
                setImagePreview1(null);
                break;
            case 2:
                setImage2(null);
                setImagePreview2(null);
                break;
            case 3:
                setImage3(null);
                setImagePreview3(null);
                break;
            default:
                break;
        }
    };

    const validateForm = () => {
        if (action === 'add' || action === 'edit') {
            if (!title || !content1) {
                setError('Пожалуйста, заполните все обязательные поля: Производитель, Название и Контент');
                alert(error)
                return false;
            }
        } else if (action === 'delete') {
            if (!title) {
                setError('Пожалуйста, заполните все обязательные поля: Название');
                return false;
            }
        }
        setError(null);
        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
    
        if (!validateForm()) return;
    
        const formData = new FormData();
        formData.append('title', title);
        formData.append('content1', content1);
        formData.append('content2', content2);
        formData.append('content3', content3);
        formData.append('manufacturer', manufacturer);
        formData.append('season', season);
        formData.append('publishedDate', new Date().toLocaleDateString("ru-RU"));
        
        if (image1) formData.append('image1', image1);
        if (image2) formData.append('image2', image2);
        if (image3) formData.append('image3', image3);
    
        try {
            if (action === 'add') {
                const existingArticle = articles.find((art) => art.title === title);
                if (existingArticle) {
                    setError('Статья с таким названием уже существует. Используйте другое название или перейдите во вкладку "Редактировать" и измените название статьи');
                } else {
                    await axios.post(process.env.REACT_APP_SERVER_URL + '/api/articles/post-article', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                }
    
            } else if (action === 'edit') {
                const existingArticle = articles.find((art) => art.id === selectedArticle?.id);
                if (existingArticle) {
                    await axios.put(process.env.REACT_APP_SERVER_URL + `/api/articles/put-article/${existingArticle.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                } else {
                    setError('Такой статьи не существует, перейдите во вкладку "Добавить" и добавьте новую статью');
                }
            } else if (action === 'delete') {
                const existingArticle = articles.find((art) => art.title === title);
                if (existingArticle) {
                    await axios.delete(process.env.REACT_APP_SERVER_URL + `/api/articles/delete-article/${existingArticle.id}`);
                } else {
                    setError('');
                }
            }
            await fetchArticles();
            setTitle('');
            setContent1('');
            setContent2('');
            setContent3('');
            setManufacturer('');
            setSeason('');
            setImage1(null);
            setImage2(null);
            setImage3(null);
            setImagePreview1(null);
            setImagePreview2(null);
            setImagePreview3(null);
        } catch (error: any) {
            setError('Ошибка при отправке запроса');
        } finally {
            setLoading(false);
        }
    };
    
    

    const LoadingSpinner = () => {
        return (
            <div className="loading-overlay">
                <div className="spinner-test"></div>
            </div>
        );
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            {action === 'add' ? (
                <>
                    <div className='hint-block'>
                        <IoInformationCircleOutline fontSize={20} color='#797979'/>
                        <p>Для добавления новой статьи необходимо выбрать нужного производителя, сезонность шин, ввести название статьи и текст статьи между картинками и добавить до трёх фотографий (JPEG, PNG, GIF)</p>
                    </div>
                    <form className='admin-form' onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="mySelect">Производители</label>
                            <select className="form-select form-select-lg"
                                aria-label=".form-select-lg example"
                                id="mySelect"
                                value={manufacturer} 
                                onChange={(e) => setManufacturer(e.target.value)}
                                disabled={loadingFilter}>
                                <option value="all">Все</option>
                                {brands.map((brand: any) => (
                                    <option value={brand.name} key={`${brand.id}-${brand.name}`}>{brand.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group full-width">
                            <label htmlFor="mySelect">Сезон</label>
                            <select 
                                className="form-select form-select-lg" 
                                aria-label=".form-select-lg example" 
                                onChange={(e) => setSeason(e.target.value)}
                                value={season}
                            >
                                <option value="all">Все</option>
                                <option value="winter">Зимние</option>
                                <option value="summer">Летние</option>
                                <option value="demi">Всесезонные</option>
                                <option value="thorns">Шипованные</option>
                            </select>
                        </div>
                        <div>
                            <label>Название</label>
                            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                        </div>
                        <div>
                            <label>Изображение 1</label>
                            <input type="file" onChange={(e) => handleImageChange(e, 1)}/>
                            <div className='image-preview'>
                                {imagePreview1 && <img src={imagePreview1} alt="Preview 1" width="50" />}
                                {image1 && <img className='cross-img' src={Cross} width='20' onClick={() => removeImage(1)} alt="Cross" />}
                            </div>
                        </div>
                        <div>
                            <label>Текст статьи (Первая часть)</label>
                            <textarea value={content1} onChange={(e) => setContent1(e.target.value)} required></textarea>
                        </div>
                        <div>
                            <label>Изображение 2</label>
                            <input type="file" onChange={(e) => handleImageChange(e, 2)} />
                            <div className='image-preview'>
                                {imagePreview2 && <img src={imagePreview2} alt="Preview 2" width="50" />}
                                {image2 && <img className='cross-img' src={Cross} width='20' onClick={() => removeImage(2)} alt="Cross" />}
                            </div>
                        </div>
                        <div>
                            <label>Текст статьи (Вторая часть)</label>
                            <textarea value={content2} onChange={(e) => setContent2(e.target.value)}></textarea>
                        </div>
                        <div>
                            <label>Изображение 3</label>
                            <input type="file" onChange={(e) => handleImageChange(e, 3)} />
                            <div className='image-preview'>
                                {imagePreview3 && <img src={imagePreview3} alt="Preview 3" width="50" />}
                                {image3 && <img className='cross-img' src={Cross} width='20' onClick={() => removeImage(3)} alt="Cross" />}
                            </div>
                        </div>                        <div>
                            <label>Текст статьи (Третья часть)</label>
                            <textarea value={content3} onChange={(e) => setContent3(e.target.value)}></textarea>
                        </div>
                        {error && <p className="error">{error}</p>}
                        <button type="submit" >Добавить/Редактировать</button>
                    </form>
                </>
            ) : (action === 'edit') ? (
                <>
                    <div className='hint-block'>
                        <IoInformationCircleOutline fontSize={20} color='#797979'/>
                        <p>Для редактирования статьи необходимо выбрать нужную статью из списка, ввести новое название статьи и текст статьи между картинками и изменить три картинки.</p>
                    </div>
                    <form className='admin-form' onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="mySelect">Выберите статью:</label>
                            <select className="form-select form-select-lg"
                                aria-label=".form-select-lg example"
                                id="mySelect"
                                value={selectedArticle?.id || ''}
                                onChange={(e) => {
                                    const article = articles.find(art => art.id === Number(e.target.value));
                                    setSelectedArticle(article || null);
                                }}
                            >
                                {articles.map((art) => (
                                    <option key={art.id} value={art.id}>{art.title}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Название:</label>
                            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div>
                            <label>Изображение 1</label>
                            <input type="file" onChange={(e) => handleImageChange(e, 1)} />
                            <div className='image-preview'>
                                {imagePreview1 && <img src={imagePreview1} alt="Preview 1" width="50" />}
                                {image1 && <img className='cross-img' src={Cross} width='20' onClick={() => removeImage(1)} alt="Cross" />}
                            </div>
                        </div>
                        <div>
                            <label>Текст статьи (Первая часть):</label>
                            <textarea value={content1} onChange={(e) => setContent1(e.target.value)}></textarea>
                        </div>
                        <div>
                            <label>Изображение 2</label>
                            <input type="file" onChange={(e) => handleImageChange(e, 2)} />
                            <div className='image-preview'>
                                {imagePreview2 && <img src={imagePreview2} alt="Preview 2" width="50" />}
                                {image2 && <img className='cross-img' src={Cross} width='20' onClick={() => removeImage(2)} alt="Cross" />}
                            </div>
                        </div>
                        <div>
                            <label>Текст статьи (Вторая часть):</label>
                            <textarea value={content2} onChange={(e) => setContent2(e.target.value)}></textarea>
                        </div>
                        <div>
                            <label>Изображение 3</label>
                            <input type="file" onChange={(e) => handleImageChange(e, 3)} />
                            <div className='image-preview'>
                                {imagePreview3 && <img src={imagePreview3} alt="Preview 3" width="50" />}
                                {image3 && <img className='cross-img' src={Cross} width='20' onClick={() => removeImage(3)} alt="Cross" />}
                            </div>
                        </div>
                        <div>
                            <label>Текст статьи (Третья часть):</label>
                            <textarea value={content3} onChange={(e) => setContent3(e.target.value)}></textarea>
                        </div>
                        {error && <p className="error">{error}</p>}
                        <button type="submit" disabled={!!error || !title || !content1}>Добавить/Редактировать</button>
                    </form>
                </>
            ) : (
                <>
                    <div className='hint-block'>
                        <IoInformationCircleOutline fontSize={20} color='#797979'/>
                        <p>Для удаления статьи необходимо ввести название статьи.</p>
                    </div>
                    <form className='admin-form' onSubmit={handleSubmit}>
                        <div>
                            <label>Название</label>
                            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                        </div>
                        {error && <p className="error">{error}</p>}
                        <button type="submit">Удалить</button>
                    </form>
                </>
            )}
        </>
    );
};

export default ArticleForm;
