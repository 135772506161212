import React, { useContext, useEffect, useState } from "react";
import "../../resources/css/Filter.css";
import Summer from "../../resources/images/Summer.svg";
import WhiteSummer from "../../resources/images/WhiteSummer.svg";
import Winter from "../../resources/images/Winter.svg";
import WhiteWinter from "../../resources/images/WhiteWinter.svg";
import Demi from "../../resources/images/Demi.svg";
import WhiteDemi from "../../resources/images/WhiteDemi.svg";
import Thorns from "../../resources/images/Thorns.svg";
import { FilterProps } from "../../../interfaces/props/FilterProps";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../context/AppContext";

export const Filter: React.FC<FilterProps> = ({
  name,
  category,
  isFiltered,
  setIsFiltered,
  loading,
  setLoading,
}) => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("Filter must be used within an AppContextProvider");
  }

  const { link, setLink, selectedSort, setSelectedSort } = context;
  const [selectedSeason, setSelectedSeason] = useState<string>("all");
  const [selectedWidth, setSelectedWidth] = useState<string>("all");
  const [selectedHeight, setSelectedHeight] = useState<string>("all");
  const [selectedDiameter, setSelectedDiameter] = useState<string>("all");
  const [selectedVendor, setSelectedVendor] = useState<string>("all");
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("nav-type-tab");
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedModification, setSelectedModification] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const [loadingFilter, setLoadingFilter] = useState<boolean>(true);
  const [loadingTypes, setLoadingTypes] = useState<boolean>(true);

  const [brands, setBrands] = useState<any>([]);

  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [modifications, setModifications] = useState([]);
  const [years, setYears] = useState([]);
  const [types, setTypes] = useState({ type1: [], type2: [], type3: [] });

  useEffect(() => {
    const filterType = localStorage.getItem("filterType");

    if (!filterType) {
      localStorage.setItem("filterType", "type");
    }

    const savedTab = localStorage.getItem("activeTab");
    if (savedTab) {
      setActiveTab(savedTab);
    }
    if (savedTab === "nav-car-tab" || activeTab === "nav-car-tab") {
      const storedCarTypes = localStorage.getItem("selectedCarTypes");
      if (storedCarTypes) {
        const parsedCarTypes = JSON.parse(storedCarTypes);
        setTypes(parsedCarTypes);
      }
    }
    const fetchData = async () => {
      try {
        const brandsResponse = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/carfilter/get-brands"
        );
        setBrands(brandsResponse.data);
      } catch (error) {
        console.error("Error fetching brands:", error);
      } finally {
        setLoadingFilter(false);
      }
    };
    fetchData();
  }, [activeTab]);

  useEffect(() => {
    if (
      localStorage.getItem("isCar") &&
      localStorage.getItem("isCar") === "true"
    ) {
      const storedCarTypes = localStorage.getItem("selectedCarTypes");
      if (storedCarTypes) {
        try {
          const parsedCarTypes = JSON.parse(storedCarTypes);
          setTypes(parsedCarTypes);
          console.log("Car types updated:", parsedCarTypes);
        } catch (error) {
          console.error("Ошибка при парсинге JSON:", error);
        }
      } else {
        console.log("Нет данных о типах машин в localStorage");
      }
      setLoadingTypes(false); // Установите флаг загрузки после обновления состояния
    }
  }, [isFiltered]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_SERVER_URL + "/api/carfilter/get-makes"
        );
        setMakes(response.data);
      } catch (error) {
        console.error("Error fetching makes:", error);
      } finally {
        setLoadingFilter(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedMake) {
      const fetchData = async () => {
        try {
          setLoadingFilter(true);
          const response = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/carfilter/get-models?make=${selectedMake}`
          );
          setModels(response.data);
          setSelectedModel("");
          setSelectedModification("");
          setSelectedYear("");
        } catch (error) {
          console.error("Error fetching models:", error);
        } finally {
          setLoadingFilter(false);
        }
      };
      fetchData();
    } else {
      setModels([]);
      setModifications([]);
      setYears([]);
      setSelectedModel("");
      setSelectedModification("");
      setSelectedYear("");
    }
  }, [selectedMake]);

  useEffect(() => {
    if (selectedModel) {
      const fetchData = async () => {
        try {
          setLoadingFilter(true);
          const response = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/carfilter/get-modifications?make=${selectedMake}&model=${selectedModel}`
          );
          setModifications(response.data);
          setSelectedModification("");
          setSelectedYear("");
        } catch (error) {
          console.error("Error fetching modifications:", error);
        } finally {
          setLoadingFilter(false);
        }
      };
      fetchData();
    } else {
      setModifications([]);
      setYears([]);
      setSelectedModification("");
      setSelectedYear("");
    }
  }, [selectedModel]);

  useEffect(() => {
    if (selectedModification) {
      const fetchData = async () => {
        try {
          setLoadingFilter(true);
          const response = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/carfilter/get-years?make=${selectedMake}&model=${selectedModel}&modification=${selectedModification}`
          );
          setYears(response.data);
          setSelectedYear("");
        } catch (error) {
          console.error("Error fetching years:", error);
        } finally {
          setLoadingFilter(false);
        }
      };
      fetchData();
    } else {
      setYears([]);
      setSelectedYear("");
    }
  }, [selectedModification]);

  useEffect(() => {
    if (selectedMake && selectedModel && selectedModification && selectedYear) {
      const fetchTypes = async () => {
        try {
          setLoadingFilter(true);
          const response = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              `/api/carfilter/get-types?make=${selectedMake}&model=${selectedModel}&modification=${selectedModification}&year=${selectedYear}`
          );
          setTypes(response.data);
        } catch (error) {
          console.error("Error fetching types:", error);
        } finally {
          setLoadingFilter(false);
        }
      };
      fetchTypes();
    } else {
      setTypes({ type1: [], type2: [], type3: [] });
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedType) {
      const { width, height, diameter } = parseTyreType(selectedType);
      setSelectedWidth(width);
      setSelectedHeight(height);
      setSelectedDiameter(diameter);
    }
  }, [selectedType]);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
    localStorage.setItem("activeTab", tabId);
    if (tabId === "nav-car-tab") {
      const storedCarTypes = localStorage.getItem("selectedCarTypes");
      if (storedCarTypes) {
        const parsedCarTypes = JSON.parse(storedCarTypes);
        setTypes(parsedCarTypes); // Убедитесь, что данные не теряются при смене вкладки
      }
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // const sortReq = queryParams.get("sort");
    const widthReq = queryParams.get("width");
    const heightReq = queryParams.get("height");
    const diameterReq = queryParams.get("diameter");
    const vendorReq = queryParams.get("vendor");
    const seasonReq = queryParams.get("season");

    // sortReq && setSelectedSort(sortReq)
    widthReq && setSelectedWidth(widthReq);
    heightReq && setSelectedHeight(heightReq);
    diameterReq && setSelectedDiameter(diameterReq);
    vendorReq && setSelectedVendor(vendorReq);
    seasonReq && setSelectedSeason(seasonReq);
    widthReq &&
      heightReq &&
      diameterReq &&
      setSelectedType(`${widthReq}/${heightReq} R${diameterReq}`);
  }, [navigate]);

  useEffect(() => {
    const params = [
      // selectedSort !== '' && `sort=${selectedSort}`,
      selectedWidth !== "all" && `width=${selectedWidth}`,
      selectedHeight !== "all" && `height=${selectedHeight}`,
      selectedDiameter !== "all" && `diameter=${selectedDiameter}`,
      selectedVendor !== "all" && `vendor=${selectedVendor}`,
      selectedSeason !== "all" && `season=${selectedSeason}`,
    ]
      .filter(Boolean)
      .join("&");
    const type =
      category === "passenger"
        ? "legkovye-shiny"
        : category === "truck"
        ? "gruzovye-shiny"
        : category === "agriculture"
        ? "selhoz-shiny"
        : category === "moto"
        ? "moto-shiny"
        : "legkovye-shiny";
    if (setLink) {
      setLink(`/products/${type}/${params && `?${params}`}`);
    }
  }, [
    category,
    selectedSort,
    selectedWidth,
    selectedHeight,
    selectedDiameter,
    selectedVendor,
    selectedSeason,
    link,
    selectedType,
  ]);

  const sendFilterData = (filterType: string) => {
    localStorage.setItem("filterType", filterType);

    setSelectedSort("");
    localStorage.setItem("selectedWidth", selectedWidth);
    localStorage.setItem("selectedHeight", selectedHeight);
    localStorage.setItem("selectedDiameter", selectedDiameter);
    localStorage.setItem("selectedSeason", selectedSeason);
    localStorage.setItem("selectedVendor", selectedVendor);

    if (activeTab === "nav-car-tab") {
      if (
        !localStorage.getItem("isCar") ||
        localStorage.getItem("isCar") === "false"
      ) {
        localStorage.setItem("selectedVendor", selectedVendor);
        localStorage.setItem("selectedModel", selectedModel);
        localStorage.setItem("selectedMake", selectedMake);
        localStorage.setItem("selectedModification", selectedModification);
        localStorage.setItem("selectedYear", selectedYear);
        localStorage.setItem("selectedCarTypes", JSON.stringify(types));
        localStorage.setItem("isCar", "true");
      }
    }

    // If any filter is applied, set loading and isFiltered states
    if (
      !(
        selectedWidth === "all" &&
        selectedHeight === "all" &&
        selectedDiameter === "all" &&
        selectedSeason === "all" &&
        selectedVendor === "all"
      )
    ) {
      if (setLoading) {
        setLoading(true);
      }
      if (setIsFiltered) {
        setIsFiltered(true);
      }
    }
  };

  const resetFilters = () => {
    localStorage.setItem("selectedWidth", "all");
    localStorage.setItem("selectedHeight", "all");
    localStorage.setItem("selectedDiameter", "all");
    localStorage.setItem("selectedVendor", "all");
    localStorage.setItem("selectedModel", "all");
    localStorage.setItem("selectedMake", "all");
    localStorage.setItem("selectedModification", "all");
    localStorage.setItem("selectedYear", "all");
    const emptyCarTypes = {
      type1: [],
      type2: [],
      type3: [],
    };
    localStorage.setItem("selectedCarTypes", JSON.stringify(emptyCarTypes));
    localStorage.setItem("isCar", "false");
  };

  const handleSeasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSeason(event.target.id.replace("btn-check-", ""));
    localStorage.setItem(
      "selectedSeason",
      event.target.id.replace("btn-check-", "")
    );
  };
  const handleWidthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedWidth(e.target.value);
  };
  const handleHeightChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedHeight(e.target.value);
  };
  const handleDiameterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDiameter(e.target.value);
  };
  const handleVendorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedVendor(e.target.value);
  };

  const handleMakeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMake(e.target.value);
  };
  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModel(e.target.value);
  };
  const handleModidficationChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedModification(e.target.value);
  };
  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(e.target.value);
  };

  const parseTyreType = (tyreType: string) => {
    const regex = /(\d+)\/(\d+)\sR(\d+)/;
    const match = tyreType.match(regex);

    if (match) {
      const [_, width, height, diameter] = match;
      return { width, height, diameter };
    }

    return { width: "", height: "", diameter: "" };
  };

  return (
    <>
      <div className="filter-component mt-4">
        <div className="bd-example m-0 border-0 tyres-tab-panel mb-4">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                className={`nav-link ${
                  activeTab === "nav-type-tab" ? "active" : ""
                }`}
                id="nav-type-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-type"
                type="button"
                role="tab"
                aria-controls="nav-type"
                aria-selected={activeTab === "nav-type-tab"}
                onClick={() => handleTabClick("nav-type-tab")}
              >
                По типоразмеру
              </button>
              <button
                className={`nav-link ${
                  activeTab === "nav-car-tab" ? "active" : ""
                }`}
                id="nav-car-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-car"
                type="button"
                role="tab"
                aria-controls="nav-car"
                aria-selected={activeTab === "nav-car-tab"}
                disabled={name === "Мотоциклетные шины"}
                onClick={() => handleTabClick("nav-car-tab")}
              >
                По авто
              </button>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "nav-type-tab" ? "active show" : ""
              }`}
              id="nav-type"
              role="tabpanel"
              aria-labelledby="nav-type-tab"
            >
              <div className="d-flex justify-content-between gap-2 grid-filter">
                <div className="form-group">
                  <label htmlFor="mySelect">Ширина</label>
                  <select
                    className="form-select form-select-lg"
                    aria-label=".form-select-lg example"
                    id="mySelect"
                    onChange={handleWidthChange}
                    value={selectedWidth}
                  >
                    <option value="all">Все</option>
                    <option value="135">135</option>
                    <option value="145">145</option>
                    <option value="155">155</option>
                    <option value="165">165</option>
                    <option value="175">175</option>
                    <option value="185">185</option>
                    <option value="195">195</option>
                    <option value="205">205</option>
                    <option value="215">215</option>
                    <option value="225">225</option>
                    <option value="235">235</option>
                    <option value="245">245</option>
                    <option value="255">255</option>
                    <option value="265">265</option>
                    <option value="275">275</option>
                    <option value="285">285</option>
                    <option value="295">295</option>
                    <option value="305">305</option>
                    <option value="315">315</option>
                    <option value="325">325</option>
                    <option value="335">335</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                    <option value="32">32</option>
                    <option value="33">33</option>
                    <option value="35">35</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="mySelect">Высота</label>
                  <select
                    className="form-select form-select-lg"
                    aria-label=".form-select-lg example"
                    onChange={handleHeightChange}
                    value={selectedHeight}
                  >
                    <option value="all">Все</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="35">35</option>
                    <option value="40">40</option>
                    <option value="45">45</option>
                    <option value="50">50</option>
                    <option value="55">55</option>
                    <option value="60">60</option>
                    <option value="65">65</option>
                    <option value="70">70</option>
                    <option value="75">75</option>
                    <option value="80">80</option>
                    <option value="85">85</option>
                    <option value="9,5">9,5</option>
                    <option value="10,5">10,5</option>
                    <option value="11,5">11,5</option>
                    <option value="12,5">12,5</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="mySelect">Диаметр</label>
                  <select
                    className="form-select form-select-lg"
                    aria-label=".form-select-lg example"
                    onChange={handleDiameterChange}
                    value={selectedDiameter}
                  >
                    <option value="all">Все</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                  </select>
                </div>
                <div className="form-group full-width">
                  <label htmlFor="mySelect">Производители</label>
                  <select
                    className="form-select form-select-lg"
                    aria-label=".form-select-lg example"
                    onChange={handleVendorChange}
                    value={selectedVendor}
                    disabled={loadingFilter}
                  >
                    <option value="all">Все</option>
                    {brands.map((brand: any) => (
                      <option
                        value={brand.name}
                        key={`${brand.id}-${brand.name}`}
                      >
                        {brand.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {localStorage.getItem("isCar") === "true" ? (
              <div
                className={`tab-pane fade ${
                  activeTab === "nav-car-tab" ? "active show" : ""
                }`}
                id="nav-car"
                role="tabpanel"
                aria-labelledby="nav-car-tab"
              >
                <p>
                  Шины для автомобиля{" "}
                  <span style={{ fontWeight: 600 }}>
                    {localStorage.getItem("selectedMake")}{" "}
                    {localStorage.getItem("selectedModel")}{" "}
                    {localStorage.getItem("selectedModification")}{" "}
                    {localStorage.getItem("selectedYear")}
                  </span>
                </p>
                <a
                  href={`${process.env.REACT_APP_CLIENT_URL}/products/${
                    category === "passenger"
                      ? "legkovye-shiny"
                      : category === "truck"
                      ? "gruzovye-shiny"
                      : category === "agriculture"
                      ? "selhoz-shiny"
                      : category === "moto"
                      ? "moto-shiny"
                      : "legkovye-shiny"
                  }?page=1`}
                  onClick={resetFilters}
                >
                  Изменить
                </a>
                <div
                  className="form-group full-width"
                  style={{ marginTop: "15px" }}
                >
                  <label htmlFor="mySelect">Производители</label>
                  <select
                    className="form-select form-select-lg"
                    aria-label=".form-select-lg example"
                    onChange={handleVendorChange}
                    value={selectedVendor}
                    disabled={loadingFilter}
                  >
                    <option value="all">Все</option>
                    {brands.map((brand: any) => (
                      <option
                        value={brand.name}
                        key={`${brand.id}-${brand.name}`}
                      >
                        {brand.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              <div
                className={`tab-pane fade ${
                  activeTab === "nav-car-tab" ? "active show" : ""
                }`}
                id="nav-car"
                role="tabpanel"
                aria-labelledby="nav-car-tab"
              >
                <div className="nav-car-body justify-content-between gap-2">
                  <div className="form-group">
                    <label htmlFor="makeSelect">Марка</label>
                    <select
                      className={`form-select form-select-lg ${
                        loadingFilter ? "loading" : ""
                      }`}
                      id="makeSelect"
                      value={selectedMake}
                      onChange={(e) => handleMakeChange(e)}
                      disabled={loadingFilter}
                    >
                      <option value="">Выбрать</option>
                      {makes.map((make, index) => (
                        <option key={index} value={make}>
                          {make}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="modelSelect">Модель</label>
                    <select
                      className={`form-select form-select-lg ${
                        loadingFilter ? "loading" : ""
                      }`}
                      id="modelSelect"
                      value={selectedModel}
                      onChange={(e) => handleModelChange(e)}
                      disabled={!selectedMake || loadingFilter}
                    >
                      <option value="">Выбрать</option>
                      {models.map((model, index) => (
                        <option key={index} value={model}>
                          {model}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="modificationSelect">Модификация</label>
                    <select
                      className={`form-select form-select-lg ${
                        loadingFilter ? "loading" : ""
                      }`}
                      id="modificationSelect"
                      value={selectedModification}
                      onChange={(e) => handleModidficationChange(e)}
                      disabled={!selectedModel || loadingFilter}
                    >
                      <option value="">Выбрать</option>
                      {modifications.map((modification, index) => (
                        <option key={index} value={modification}>
                          {modification}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="yearSelect">Год выпуска</label>
                    <select
                      className={`form-select form-select-lg ${
                        loadingFilter ? "loading" : ""
                      }`}
                      id="yearSelect"
                      value={selectedYear}
                      onChange={(e) => handleYearChange(e)}
                      disabled={!selectedModification || loadingFilter}
                    >
                      <option value="">Выбрать</option>
                      {years.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
            {activeTab === "nav-type-tab" ? (
              <div
                className="d-flex justify-content-between"
                style={{ marginTop: 27 }}
              >
                <div className="season-select-block">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      id="btn-check-all"
                      name="season"
                      checked={selectedSeason === "all"}
                      autoComplete="off"
                      onChange={handleSeasonChange}
                      value={selectedSeason}
                    />
                    <label
                      className="btn btn-outline-primary rounded-start"
                      htmlFor="btn-check-all"
                    >
                      Все
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="btn-check-summer"
                      autoComplete="off"
                      checked={selectedSeason === "summer"}
                      name="season"
                      onChange={handleSeasonChange}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btn-check-summer"
                    >
                      <img
                        src={`${
                          selectedSeason === "summer" ? WhiteSummer : Summer
                        }`}
                        style={{ maxWidth: 20 }}
                        alt="..."
                      />
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      id="btn-check-demi"
                      autoComplete="off"
                      name="season"
                      checked={selectedSeason === "demi"}
                      onChange={handleSeasonChange}
                      value={selectedSeason}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btn-check-demi"
                    >
                      <img
                        src={`${selectedSeason === "demi" ? WhiteDemi : Demi}`}
                        style={{ maxWidth: 20 }}
                        alt="..."
                      />
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      id="btn-check-winter"
                      autoComplete="off"
                      checked={selectedSeason === "winter"}
                      name="season"
                      onChange={handleSeasonChange}
                      value={selectedSeason}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btn-check-winter"
                    >
                      <img
                        src={`${
                          selectedSeason === "winter" ? WhiteWinter : Winter
                        }`}
                        style={{ maxWidth: 20 }}
                        alt="..."
                      />
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      id="btn-check-thorns"
                      autoComplete="off"
                      checked={selectedSeason === "thorns"}
                      name="season"
                      onChange={handleSeasonChange}
                      value={selectedSeason}
                    />
                    <label
                      className="btn btn-outline-primary rounded-end"
                      htmlFor="btn-check-thorns"
                    >
                      <img
                        src={`${selectedSeason === "thorns" ? Thorns : Thorns}`}
                        style={{ maxWidth: 20 }}
                        alt="..."
                      />{" "}
                      {/* Thorns doesn't have a white variant */}
                    </label>
                  </div>
                </div>
                {isFiltered ? (
                  <button className="btn btn-warning --orange-btn btn-filter">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </button>
                ) : (
                  <Link to={`${process.env.REACT_APP_CLIENT_URL}${link}`}>
                    <button
                      onClick={() => sendFilterData("type")}
                      className="btn btn-warning --orange-btn btn-filter"
                    >
                      Подобрать шины
                    </button>
                  </Link>
                )}
              </div>
            ) : (
              <div
                className="last-row d-flex justify-content-between gap-2"
                style={{ marginTop: 27, alignItems: "end" }}
              >
                {localStorage.getItem("selectedCarTypes") &&
                localStorage.getItem("selectedCarTypes") !==
                  '{"type1":[],"type2":[],"type3":[]}' ? (
                  <div className="form-group standart-size-input">
                    <label htmlFor="modelSelect">Типоразмерр</label>
                    <select
                      className={`form-select form-select-lg ${
                        loadingTypes ? "loading" : ""
                      }`}
                      id="typeSelect"
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                      disabled={loadingTypes}
                    >
                      <option value="">Выбрать</option>
                      {!loadingTypes && types && (
                        <>
                          <optgroup label="Заводские размеры">
                            {types.type1.map((type, index) => (
                              <option key={index} value={type}>
                                {type}
                              </option>
                            ))}
                          </optgroup>
                          {types.type2.length > 0 && (
                            <optgroup label="Замена">
                              {types.type2.map((type, index) => (
                                <option key={index} value={type}>
                                  {type}
                                </option>
                              ))}
                            </optgroup>
                          )}
                          {types.type3.length > 0 && (
                            <optgroup label="Тюнинг">
                              {types.type3.map((type, index) => (
                                <option key={index} value={type}>
                                  {type}
                                </option>
                              ))}
                            </optgroup>
                          )}
                        </>
                      )}
                    </select>
                  </div>
                ) : (
                  <div className="form-group standart-size-input">
                    <label htmlFor="modelSelect">Типоразмер</label>
                    <select
                      className={`form-select form-select-lg ${
                        loadingFilter ? "loading" : ""
                      }`}
                      id="typeSelect"
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                      disabled={!selectedYear || loadingFilter}
                    >
                      <option value="Выбрать">Выбрать</option>
                      <optgroup label="Заводские размеры">
                        {types.type1.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </optgroup>
                      {types.type2.length > 0 && (
                        <optgroup label="Замена">
                          {types.type2.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </optgroup>
                      )}
                      {types.type3.length > 0 && (
                        <optgroup label="Тюнинг">
                          {types.type3.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </optgroup>
                      )}
                    </select>
                  </div>
                )}
                <div className="season-select-block">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      id="btn-check-all"
                      name="season"
                      checked={selectedSeason === "all"}
                      autoComplete="off"
                      onChange={handleSeasonChange}
                      value={selectedSeason}
                    />
                    <label
                      className="btn btn-outline-primary rounded-start"
                      htmlFor="btn-check-all"
                    >
                      Все
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="btn-check-summer"
                      autoComplete="off"
                      checked={selectedSeason === "summer"}
                      name="season"
                      onChange={handleSeasonChange}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btn-check-summer"
                    >
                      <img
                        src={`${
                          selectedSeason === "summer" ? WhiteSummer : Summer
                        }`}
                        style={{ maxWidth: 20 }}
                        alt="..."
                      />
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      id="btn-check-demi"
                      autoComplete="off"
                      name="season"
                      checked={selectedSeason === "demi"}
                      onChange={handleSeasonChange}
                      value={selectedSeason}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btn-check-demi"
                    >
                      <img
                        src={`${selectedSeason === "demi" ? WhiteDemi : Demi}`}
                        style={{ maxWidth: 20 }}
                        alt="..."
                      />
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      id="btn-check-winter"
                      autoComplete="off"
                      checked={selectedSeason === "winter"}
                      name="season"
                      onChange={handleSeasonChange}
                      value={selectedSeason}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btn-check-winter"
                    >
                      <img
                        src={`${
                          selectedSeason === "winter" ? WhiteWinter : Winter
                        }`}
                        style={{ maxWidth: 20 }}
                        alt="..."
                      />
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      id="btn-check-thorns"
                      autoComplete="off"
                      checked={selectedSeason === "thorns"}
                      name="season"
                      onChange={handleSeasonChange}
                      value={selectedSeason}
                    />
                    <label
                      className="btn btn-outline-primary rounded-end"
                      htmlFor="btn-check-thorns"
                    >
                      <img
                        src={`${selectedSeason === "thorns" ? Thorns : Thorns}`}
                        style={{ maxWidth: 20 }}
                        alt="..."
                      />{" "}
                      {/* Thorns doesn't have a white variant */}
                    </label>
                  </div>
                </div>
                {isFiltered ? (
                  <button className="btn btn-warning --orange-btn btn-filter last-link-row">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </button>
                ) : (
                  <>
                    {!selectedType || selectedType === "Выбрать" ? (
                      <button
                        className="btn btn-warning --orange-btn btn-filter"
                        disabled
                      >
                        Подобрать шины
                      </button>
                    ) : (
                      <Link
                        to={`${process.env.REACT_APP_CLIENT_URL}${link}`}
                        className="last-link-row"
                      >
                        <button
                          onClick={() => sendFilterData("car")}
                          className="btn btn-warning --orange-btn btn-filter"
                        >
                          Подобрать шины
                        </button>
                      </Link>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
