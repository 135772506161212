import React from 'react'
import {TyresInfo} from '../components/TyresInfo'
import {TyresChoice} from '../components/TyresChoice'
import {Breadcrumbs} from '../components/Breadcrumbs'

const BREADCRUMB_ITEMS = [
    {label: 'Главная', href: '/'},
    {label: 'Каталог', href: '/products/legkovye-shiny/'},
    {label: 'Электронный знак', href: '/electronicsign'},
]

export const ElectronicSignPage = () => {
    return (
        <div style={{maxWidth: '1120px', margin: '0 auto'}}>
            <Breadcrumbs items={BREADCRUMB_ITEMS}/>
            <TyresInfo/>
            <TyresChoice/>
        </div>
    )
}
