import AskFormBackgroundImage from '../../resources/images/AskFormBackgroundImage.png'
import React, {useState} from "react";
import {DataLogin} from "../../../interfaces/DataLogin";
import '../../resources/css/AskForm.css'
import {isValidDigit, isValidText} from "../../utils/validation/Validation";

export const AskForm = () => {
    const [inputName, setName] = useState('');
    const [inputPhone, setPhone] = useState('');

    const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setName(e.target.value);
    };
    const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidDigit(e.target.value)) setPhone(e.target.value);
    };

    async function fetchData(event: any) {
        event.preventDefault();
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + '/api/support/questions', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    name: inputName,
                    phone: inputPhone
                })
            });
            const data: DataLogin = await response.json();

            if (!response.ok) {
                console.error(data.message);
                return;
            }

            window.location.reload();
        } catch (error: any) {
            console.error(error.message);
        }
    }

    return (
        <>
            <form onSubmit={fetchData} className={"pt-4 pb-3 mt-3 mb-3 border-top border-bottom border-2 ask-form"}
                  style={{maxWidth: '1120px', margin: '0 auto'}}>
                <div className="row">
                    <div className="col-md-4 themed-grid-col">
                        <h5 className="fw-medium">Оставьте Ваши данные и наш менеджер ответит на все вопросы</h5>
                        <div className="form-group mt-3">
                            <label className="form-control-label" htmlFor="formGroupExampleInput1">Имя</label>
                            <input type="text" className="form-control p-3" id="formGroupExampleInput1"
                                   required
                                   value={inputName}
                                   onChange={handleNameInputChange}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label className="form-control-label" htmlFor="formGroupExampleInput2">Телефон с
                                кодом</label>
                            <input
                                type="tel" className="form-control p-3"
                                id="formGroupExampleInput2"
                                required value={inputPhone}
                                onChange={handlePhoneInputChange}
                            />
                        </div>
                        <button className="btn btn-warning --orange-btn w-100 p-3 mt-3" type="submit">
                            Заказать звонок
                        </button>
                    </div>
                    <div className="col-md-8 themed-grid-col text-center">
                        <img src={`${AskFormBackgroundImage}`} alt="..." style={{width: '100%', maxWidth: 684}}/>
                    </div>
                </div>
            </form>
        </>
    )
}