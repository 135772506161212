import {AskForm} from "../components/AskForm";
import {TyresTest} from "../components/TyresTest";
import React, {useEffect, useState} from "react";
import GoogleLogo from "../../resources/images/google-logo.svg";
import YandexLogo from "../../resources/images/yandex-logo.svg";
import Star from "../../resources/images/star.svg";
import Like from "../../resources/images/like.svg";
import {SmallProductCard} from "../components/SmallProductCard";
import '../../resources/css/HomePage.css';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {Scrollbar} from "swiper/modules";
import {Filter} from "../components/Filter";
import {ProductProps} from "../../../interfaces/props/ProductProps";
import axios from "axios";

export const HomePage = () => {
    const [productData, setProductData] = useState<ProductProps[]>([]);
    const [loading, setLoading] = useState<boolean>(true)

    function getFilterValue(tire: ProductProps, index: number): string {
        return tire?.filters.find(filter => filter.id === index)?.values[0].name || '–'
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_SERVER_URL + '/api/products/get-random')
            .then(response => response.json())
            .then(data => {
                setProductData(data);
            })
            .catch(() => {
                setProductData([])
            })
            .finally(() => {
                setLoading(false)
            })
    }, []);


    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 767);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <>
            <div className="home-page">

                <Filter category="passenger"/>

                <h3>Лучшие предложения на шины</h3>
                {loading ? (
                    <div className="loading-spinner d-flex justify-content-center">
                        <div className="spinner-grow text-dark" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        {productData.length > 0 && (
                            <>
                                {isSmallScreen ? (
                                    <div className="row">
                                        <Swiper
                                            slidesPerView="auto"
                                            spaceBetween={16}
                                            scrollbar={{draggable: true}}
                                            modules={[Scrollbar]}
                                            className="mySwiper"
                                        >
                                            {productData.map((product, index) => (
                                                <SwiperSlide key={index}>
                                                    <SmallProductCard
                                                        key={index}
                                                        id={product.id}
                                                        imageUrl={product.images[0]}
                                                        season={getFilterValue(product, 8631)}
                                                        title={product.vendor.name}
                                                        text={product.model}
                                                        price={product.price}
                                                        product={product}
                                                    />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                ) : (
                                    <div
                                        className="row row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 row-cols-xl-4 g-4 gap-3 justify-content-around">
                                        {productData.map((product, index) => (
                                            <SmallProductCard
                                                key={index}
                                                id={product.id}
                                                imageUrl={product.images[0]}
                                                season={getFilterValue(product, 8631)}
                                                title={product.vendor.name}
                                                text={product.model}
                                                price={product.price}
                                                product={product}
                                            />
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
                <AskForm/>

                <h3>Отзывы клиентов</h3>
                <div className="row mt-4 mb-4 clients-reviews">
                    <div className="col-md-6 d-flex flex-wrap justify-content-center border-end">
                        <div className="w-100 text-center mb-2">
                            <img src={`${GoogleLogo}`} alt="..." style={{maxWidth: 130}}/>
                        </div>

                        <div className="d-flex justify-content-evenly w-100">
                            <div>
                                <div className="custom-card mb-2">
                                    <img src={`${Star}`} alt="..." style={{maxWidth: 31.5, marginRight: '2px'}}/>
                                    <h3 className="fw-bold" style={{marginBottom: '-2px', marginLeft: '2px'}}> 4,7 /
                                        5</h3>
                                </div>
                                <p>Всего 3078 отзывов</p>
                            </div>
                            <div>
                                <div className="custom-card mb-2">
                                    <img src={`${Like}`} alt="..." style={{maxWidth: 31.5, marginRight: '2px'}}/>
                                    <h3 className="fw-bold" style={{marginBottom: '-2px', marginLeft: '2px'}}>95%</h3>
                                </div>
                                <p>Клиентов реагирует</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex flex-wrap justify-content-center border-start">
                        <div className="w-100 text-center mb-2">
                            <img src={`${YandexLogo}`} alt="..." style={{maxWidth: 130}}/>
                        </div>

                        <div className="w-100 d-flex justify-content-evenly ">
                            <div>
                                <div className="custom-card mb-2">
                                    <img src={`${Star}`} alt="..." style={{maxWidth: 31.5, marginRight: '2px'}}/>
                                    <h3 className="fw-bold" style={{marginBottom: '-2px', marginLeft: '2px'}}> 4,8 /
                                        5</h3>
                                </div>
                                <p>Всего 2762 отзывов</p>
                            </div>
                            <div>
                                <div className="custom-card mb-2">
                                    <img src={`${Like}`} alt="..." style={{maxWidth: 31.5, marginRight: '2px'}}/>
                                    <h3 className="fw-bold" style={{marginBottom: '-2px', marginLeft: '2px'}}>97%</h3>
                                </div>
                                <p>Клиентов реагирует</p>
                            </div>
                        </div>
                    </div>
                </div>


                <TyresTest/>
            </div>
        </>
    )
}