import React, {useContext, useState} from 'react'
import {Breadcrumbs} from '../components/Breadcrumbs'
import {OrderBlock} from '../components/OrderBlock'
import '../../resources/css/OrderPage.css'
import Asterisk from '../../resources/images/asterisk.svg'
import SuccessfulReg from '../../resources/images/SuccessfulReg.svg'
import Phone from '../../resources/images/Phone.svg'
import ArrowBack from "../../resources/images/arrowBack.svg"
import classNames from 'classnames'
import {authFetch} from "../../utils/fetch's/authFetch";
import { AppContext } from '../context/AppContext'

const BREADCRUMB_ITEMS = [
    {label: 'Главная', href: '/'},
    {label: 'Корзина', href: '/cart'},
    {label: 'Оформление заказа', href: '/order'},
]

export const OrderPage = () => {
    const [orderNumber, setOrderNumber] = useState<number>(0)
    const [inputName, setInputName] = useState<string>('')
    const [inputPhone, setInputPhone] = useState<string>('')
    const [inputUnp, setInputUnp] = useState<string>('')
    const [inputOrganization, setInputOrganization] = useState<string>('')
    const [inputTitleEDI, setInputTitleEDI] = useState<string>('')
    const [inputNumberGLN, setInputNumberGLN] = useState<string>('')
    const [inpuEmail, setInputEmail] = useState<string>('')
    const [inpuCity, setInputCity] = useState<string>('')
    const [inpuTypeStreet, setInputTypeStreet] = useState<string>('')
    const [inputStreetName, setInputStreetName] = useState<string>('')
    const [inputHouse, setInputHouse] = useState('');
    const [InputCorpus, setInputCorpus] = useState('');
    const [inputFlat, setInputFlat] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [comment, setComment] = useState<string>('');

    const [activeBtn, setActiveBtn] = useState<string>('Самовывоз')
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isOrder, setIsOrder] = useState<boolean>(false)
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('Card должен быть использован внутри AppContextProvider');
    }

    const {setCart} = context;

    let cartProducts = JSON.parse(localStorage.getItem("cart") || '[]');
    let orderPrice;
    let salePrice;
    if (cartProducts && cartProducts.length > 0) {
        const prodPrices = cartProducts.map((item: any) => item.price * item.quantity);
        const salePrices = cartProducts.map((item: any) => item.price * item.quantity * (item.vendor === 'Kumho' || item.vendor === 'Arivo' ? 0.25 : 0.15));
        orderPrice = prodPrices.reduce((a: number, e: number) => e + a);
        salePrice = salePrices.reduce((a: number, e: number) => e + a);
    }

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
    };

    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
    };

    const postData = async (event: any)=> {
        event.preventDefault();
        setLoading(true)
        const generatedOrderNumber = Math.floor(100000 + Math.random() * 900000);
        setOrderNumber(generatedOrderNumber);
        let orderPrice = 0;
        if (cartProducts && cartProducts.length > 0) {
            const prodPrices = cartProducts.map((item: any) => item.price * item.quantity);
            orderPrice = prodPrices.reduce((a: number, e: number) => e + a);
            if (paymentMethod === 'Карта рассрочки') {
                orderPrice = orderPrice * 1.06;
            }
        }
        try {
            let htmlString = cartProducts
                .map(
                    (item: any) =>
                        `
                            Наименование производителя: ${item.vendor}
                            Наименование модели: ${item.model}
                            Поставщик: ${item.supplier}
                            Цена: ${(parseFloat(item.price) || 0).toFixed(2)}
                            Количество: ${item.quantity}
                            Ссылка: ${item.url}
                            Как была выбрана: ${item.from}
                        `
                )
                .join("");

            const data = {
                orderNumber: generatedOrderNumber,
                name: inputName,
                phone: inputPhone,
                city: inpuCity,
                order: cartProducts,
                paymentMethod: paymentMethod,
                comment: comment,
                orderPrice: orderPrice,
                htmlContent: htmlString,
                ...(isChecked && {
                    unp: inputUnp,
                    organization: inputOrganization,
                    titleEDI: inputTitleEDI,
                    numberGLN: inputNumberGLN,
                    email: inpuEmail,
                }),
                ...(activeBtn === 'Доставка' && {
                    typeStreet: inpuTypeStreet,
                    streetName: inputStreetName,
                    house: inputHouse,
                    corpus: InputCorpus,
                    flat: inputFlat,
                }),
            };


            const response = await authFetch(process.env.REACT_APP_SERVER_URL + "/api/order/post", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error("Ошибка при отправке формы");
            }
            localStorage.setItem("cart", JSON.stringify([]));
            setCart([])
            setIsOrder(true)
            window.scrollTo(0, 0);
            // window.location.reload();
        } catch (error) {
            console.error("Ошибка при отправке формы:", error);
        }
    };

    const handleChange = (type: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (type === 'tel') {
            if (/^[+]?\d*$/.test(newValue)) {
                setInputPhone(newValue)
            }
        } else {
            if (/^\d*$/.test(newValue)) {
                if (type === 'house') {
                    setInputHouse(newValue);
                } else if (type === 'corpus') {
                    setInputCorpus(newValue);
                } else if (type === 'flat') {
                    setInputFlat(newValue);
                }
            }
        }
    };

    const handlePaymentChange = (event: any) => {
        setPaymentMethod(event.target.value);
    };

    return (
        <>
            {isOrder ? (
                <div>
                    <div className='cart-emty-wrap'>
                        <a href="/products?category=passenger&page=1">
                            <img src={ArrowBack} alt="ArrowBack" />
                            <p>Вернуться к покупкам</p>
                        </a>
                        <div>
                            <img src={SuccessfulReg} alt="SmileySad" />
                            <h2>Ваш заказ №{orderNumber} принят!</h2>
                            <div>
                                <img src={Phone} alt="BluePhone" />
                                <span>Наш специалист скоро свяжется с Вами.</span>
                            </div>
                            <span style={{textAlign: 'center'}}>ВАЖНО! Данное сообщение не является подтверждением заказа и резервом товара.</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <Breadcrumbs items={BREADCRUMB_ITEMS}/>
                    <div className='order-wrap'>
                        <h1>Оформление заказа</h1>
                        <div className='order-info-wrap'>
                            <form onSubmit={postData}>
                                <div>
                                    <div className="row mb-3">
                                        <label htmlFor="inputName3" className="col-sm-2 col-form-label">
                                            ФИО
                                            <img src={Asterisk} alt="Asterisk"/>
                                        </label>
                                        <div className="col-sm-10">
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="inputName3" value={inputName} 
                                                onChange={(e) => setInputName(e.target.value)} required/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="inputPhone3" className="col-sm-2 col-form-label">
                                            Телефон
                                            <img src={Asterisk} alt="Asterisk"/>
                                        </label>
                                        <div className="col-sm-10">
                                        <input type="tel" value={inputPhone} id="inputPhone3"
                                                        onChange={(e) => handleChange('tel', e)} className="form-control"
                                                        aria-label="Tel" required/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={handleCheckboxChange}
                                                id="gridCheck"/>
                                            <label className="form-check-label" htmlFor="gridCheck">
                                                Заказ для юридического лица
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {isChecked && (
                                    <div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputUNP3" className="col-sm-2 col-form-label">
                                                УНП
                                                <img src={Asterisk} alt="Asterisk"/>
                                            </label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputUNP3" value={inputUnp} onChange={(e) => setInputUnp(e.target.value)} required/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputOrganization3" className="col-sm-2 col-form-label">
                                                Организация
                                                <img src={Asterisk} alt="Asterisk"/>
                                            </label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputOrganization3" value={inputOrganization} onChange={(e) => setInputOrganization(e.target.value)} required/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputTitle3" className="col-sm-2 col-form-label">
                                                Наименование EDI - провайдера
                                            </label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputTitle3" value={inputTitleEDI} onChange={(e) => setInputTitleEDI(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputNumber3" className="col-sm-2 col-form-label">
                                                Номер GLN
                                            </label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputNumber3" value={inputNumberGLN} onChange={(e) => setInputNumberGLN(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                                                Эл. почта
                                            </label>
                                            <div className="col-sm-10">
                                                <input type="email" className="form-control" id="inputEmail3" value={inpuEmail} onChange={(e) => setInputEmail(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <h3>Доставка</h3>
                                    <div className="row mb-3">
                                        <label htmlFor="inputCity3" className="col-sm-2 col-form-label">Город доставки</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputCity3"/>
                                        </div>
                                    </div>
                                    <div className='order-btns'>
                                        <button type="button"
                                                className={classNames("btn btn-primary", {['active']: activeBtn === 'Самовывоз'})}
                                                onClick={() => setActiveBtn('Самовывоз')}>Самовывоз
                                        </button>
                                        <button type="button"
                                                className={classNames("btn btn-primary", {['active']: activeBtn === 'Доставка'})}
                                                onClick={() => setActiveBtn('Доставка')}>Доставка
                                        </button>
                                    </div>
                                    {activeBtn === 'Самовывоз' ? (
                                        <div className='street-block'>
                                            <div>
                                                <h4>Самовывоз из магазина TopTyre</h4>
                                                <p>Адрес: <span>г.Минск, ул. Одинцова 20Б</span></p>
                                                <a href="https://toptyre.by/contacts">Показать адрес на карте</a>
                                            </div>
                                            <p>Доставка: <span>Уточняйте у менеджера по телефону +375 (29) 115-26-26</span></p>
                                        </div>
                                    ) : (
                                        <div style={{marginTop: '20px'}}>
                                            <div className="row mb-3">
                                                <label htmlFor="inputTypeStreet3" className="col-sm-2 col-form-label">
                                                    Тип улицы
                                                    <img src={Asterisk} alt="Asterisk"/>
                                                </label>
                                                <div className="col-sm-10">
                                                    <select className="form-select" aria-label="Default select example" value={inpuTypeStreet} onChange={(e) => setInputTypeStreet(e.target.value)} required>
                                                        <option value='Улица' selected>Улица</option>
                                                        <option value="Переулок">Переулок</option>
                                                        <option value="Проспект">Проспект</option>
                                                        <option value="Проезд">Проезд</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label htmlFor="inputStreet3" className="col-sm-2 col-form-label">
                                                    Улица
                                                    <img src={Asterisk} alt="Asterisk"/>
                                                </label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="inputStreet3" value={inputStreetName} onChange={(e) => setInputStreetName(e.target.value)} required/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="inputHouse3" className="col-sm-2 col-form-label">
                                                        Дом
                                                        <img src={Asterisk} alt="Asterisk"/>
                                                    </label>
                                                    <input type="text" value={inputHouse}
                                                        onChange={(e) => handleChange('house', e)} className="form-control"
                                                        aria-label="House" required/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="inputCorpus3"
                                                        className="col-sm-2 col-form-label">Корпус</label>
                                                    <input type="text" value={InputCorpus}
                                                        onChange={(e) => handleChange('corpus', e)} className="form-control"
                                                        aria-label="Corpus"/>
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="inputFlat3" className="col-sm-2 col-form-label">Офис /
                                                        кв.</label>
                                                    <input type="text" value={inputFlat}
                                                        onChange={(e) => handleChange('flat', e)} className="form-control"
                                                        aria-label="Flat"/>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <fieldset className="row mb-3">
                                    <h3>Способ оплаты</h3>
                                    <div className="col-sm-10 radio-block">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1"
                                                value="Банковская карта" onChange={handlePaymentChange}/>
                                            <label className="form-check-label" htmlFor="gridRadios1">
                                                Банковская карта
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2"
                                                value="Наличные" onChange={handlePaymentChange}/>
                                            <label className="form-check-label" htmlFor="gridRadios2">
                                                Наличные
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios3"
                                                value="Карта рассрочки" onChange={handlePaymentChange}/>
                                            <label className="form-check-label" htmlFor="gridRadios3">
                                                Карта рассрочки
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>
                                <div>
                                    <span style={{fontSize: '16px'}}>Итоговая стоимость:</span>
                                    <p style={{fontSize: '20px', fontWeight: '500'}}>{cartProducts.length > 0 ? (paymentMethod === 'Карта рассрочки' ? Number(orderPrice * 1.06).toFixed(2) : Number(orderPrice).toFixed(2)) : 0} руб.</p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Комментарий</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} value={comment} onChange={(e) => handleCommentChange(e)}></textarea>
                                </div>
                                {loading ? (
                                    <button className="btn btn-warning --orange-btn btn-filter">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                ) : (        
                                    <button className="btn btn-warning --orange-btn btn-price" type='submit' disabled={cartProducts.length < 1}>
                                        Оформить заказ
                                    </button>
                                )}
                            </form>
                            {cartProducts.length > 0 ? (
                                <OrderBlock noBtn={true} paymentMethod={paymentMethod}/>
                            ) : (
                                <div className='no-cart'>
                                    <p>Корзина пуста</p>
                                    <a href="/products?category=passenger&page=1">Перейти в каталог</a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
