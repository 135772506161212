import {DataMessage} from "../../../../interfaces/DataMessage";

export async function fetchDataForgotPassword(
    resetInputMail: string,
    setErrorMessage: (message: string) => void,
    setShowNewPassword: (show: boolean) => void,
    setIsButtonDisabled: (disabled: boolean) => void,
    setTimeRemaining: (time: number) => void
): Promise<void> {
    try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + '/api/auth/forgot-password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: resetInputMail,
            }),
        });
        const data: DataMessage = await response.json();
        if (!response.ok) {
            alert(data.message);
            return;
        }

        setShowNewPassword(true);
        setErrorMessage('');
        setIsButtonDisabled(true);
        setTimeRemaining(60);
    } catch (error: any) {
        setErrorMessage(error.message);
    }
}
