import React, {useState} from "react";
import {OnlyStylesProps} from "../../../interfaces/props/OnlyStylesProps";
import {convertTireSize_EURO_to_USA, convertTireSize_USA_to_EURO} from "../../utils/calc/custom-calc";

export const TranslatorStandardSizes: React.FC<OnlyStylesProps> = ({styles}) => {
    const [selectedUSATranslateD, setSelectedUSATranslateD] = useState<string>('22');
    const [selectedUSATranslateA, setSelectedUSATranslateA] = useState<string>('8');
    const [selectedUSATranslateC, setSelectedUSATranslateC] = useState<string>('18');

    const [selectedEUROTranslateA, setSelectedEUROTranslateA] = useState<string>('195');
    const [selectedEUROTranslateB, setSelectedEUROTranslateB] = useState<string>('70');
    const [selectedEUROTranslateC, setSelectedEUROTranslateC] = useState<string>('16');

    const [resultEUROTranslateA, setResultEUROTranslateA] = useState<string>('203.2');
    const [resultEUROTranslateB, setResultEUROTranslateB] = useState<string>('25');
    const [resultEUROTranslateC, setResultEUROTranslateC] = useState<string>('18');

    const [resultUSATranslateD, setResultUSATranslateD] = useState<string>('26.75');
    const [resultUSATranslateA, setResultUSATranslateA] = useState<string>('7.68');
    const [resultUSATranslateC, setResultUSATranslateC] = useState<string>('16');

    const [newStandardSize_EURO, setNewStandardSize_EURO] = useState<string>(
        `${selectedEUROTranslateA}/${selectedEUROTranslateB} R${selectedEUROTranslateC}`
    );

    const [newStandardSize_USA, setNewStandardSize_USA] = useState<string>(
        `${selectedEUROTranslateA}/${selectedEUROTranslateB} R${selectedEUROTranslateC}`
    );

    const translate_EURO_to_USA = async (event: React.FormEvent) => {
        event.preventDefault();

        const result = convertTireSize_EURO_to_USA(
            selectedEUROTranslateA,
            selectedEUROTranslateB,
            selectedEUROTranslateC
        );

        setResultUSATranslateD(result.americanDiameter.toFixed(2).toString());
        setResultUSATranslateA(result.americanWidthInches.toFixed(2).toString());
        setResultUSATranslateC(result.americanDisk.toString());

    }

    const translate_USA_to_EURO = async (event: React.FormEvent) => {
        event.preventDefault();

        const result = convertTireSize_USA_to_EURO(
            selectedUSATranslateD,
            selectedUSATranslateA,
            selectedUSATranslateC
        );

        setResultEUROTranslateA(result.europeanWidth.toFixed(1).toString());
        setResultEUROTranslateB(result.profile.toFixed(0).toString());
        setResultEUROTranslateC(result.europeanDisk.toString());
    }

    return (
        <>
            <div className={styles}>
                <div className="text-center m-bottom-15">
                    <p>Укажите прежний типоразмер</p>
                </div>
                <div className="tyres-tab-panel">
                    <nav>
                        <div className="nav nav-tabs " id="nav-tab" role="tablist">
                            <button className="nav-link" id="nav-translate-euro-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-translate-euro" type="button" role="tab"
                                    aria-controls="nav-translate-euro"
                                    aria-selected="false">ЕВРО - США
                            </button>
                            <button className="nav-link active" id="nav-translate-usa-tab" data-bs-toggle="tab"
                                    data-bs-target="#nav-translate-usa" type="button" role="tab"
                                    aria-controls="nav-translate-usa"
                                    aria-selected="true">США - ЕВРО
                            </button>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <form className="tab-pane fade" id="nav-translate-euro" role="tabpanel"
                              aria-labelledby="nav-translate-euro-tab" onSubmit={translate_EURO_to_USA}>
                            <div className="border-card">
                                <p className="m-bottom-10">Европейский размер</p>
                                <div className="row">
                                    <div className="d-flex col-4">
                                        <span className="label_A">A</span>
                                        <select
                                            className="form-select form-select-lg"
                                            aria-label=".form-select-lg example"
                                            onChange={(e) => setSelectedEUROTranslateA(e.target.value)}
                                            value={selectedEUROTranslateA}
                                        >
                                            <option value="135">135</option>
                                            <option value="145">145</option>
                                            <option value="155">155</option>
                                            <option value="165">165</option>
                                            <option value="175">175</option>
                                            <option value="185">185</option>
                                            <option value="195">195</option>
                                            <option value="205">205</option>
                                            <option value="215">215</option>
                                            <option value="225">225</option>
                                            <option value="235">235</option>
                                            <option value="245">245</option>
                                            <option value="255">255</option>
                                            <option value="265">265</option>
                                            <option value="275">275</option>
                                            <option value="285">285</option>
                                            <option value="295">295</option>
                                            <option value="305">305</option>
                                            <option value="315">315</option>
                                            <option value="325">325</option>
                                            <option value="335">335</option>
                                            <option value="345">345</option>
                                            <option value="355">355</option>
                                            <option value="365">365</option>
                                            <option value="375">375</option>
                                        </select>
                                    </div>
                                    <div className="d-flex col-4">
                                        <span className="label_B">B</span>
                                        <select
                                            className="form-select form-select-lg"
                                            aria-label=".form-select-lg example"
                                            onChange={(e) => setSelectedEUROTranslateB(e.target.value)}
                                            value={selectedEUROTranslateB}
                                        >
                                            <option value="20">20</option>
                                            <option value="25">25</option>
                                            <option value="30">30</option>
                                            <option value="35">35</option>
                                            <option value="40">40</option>
                                            <option value="45">45</option>
                                            <option value="50">50</option>
                                            <option value="55">55</option>
                                            <option value="60">60</option>
                                            <option value="65">65</option>
                                            <option value="70">70</option>
                                            <option value="75">75</option>
                                            <option value="80">80</option>
                                            <option value="85">85</option>
                                            <option value="90">90</option>
                                        </select>
                                    </div>
                                    <div className="d-flex col-4">
                                        <span className="label_C">C</span>
                                        <select
                                            className="form-select form-select-lg"
                                            aria-label=".form-select-lg example"
                                            onChange={(e) => setSelectedEUROTranslateC(e.target.value)}
                                            value={selectedEUROTranslateC}
                                        >
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                        </select>
                                    </div>
                                </div>
                                <a href='/products' className="--orange-hover d-flex align-items-center mt-2" style={{marginLeft: 0}}>
                                    <span>Перейти в каталог</span>
                                    <i className="bi bi-arrow-right-circle ms-2"></i>
                                </a>
                            </div>
                            <button className="btn btn-warning --orange-btn w-100" type="submit">Рассчитать</button>
                            <div className="result-block">
                                <p className="m-bottom-10">Американский размер</p>
                                <div className="row">
                                <div className="col-4">
                                        <label>Диаметр</label>
                                        <div className="d-flex">
                                            <span className="label_D">D</span>
                                            <p>{resultUSATranslateD} дюйма</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label>Ширина</label>
                                        <div className="d-flex">
                                            <span className="label_A">A</span>
                                            <p>{resultUSATranslateA} дюйма</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label>Диск</label>
                                        <div className="d-flex">
                                            <span className="label_C">C</span>
                                            <p>{resultUSATranslateC} дюйма</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form className="tab-pane fade active show" id="nav-translate-usa" role="tabpanel"
                              aria-labelledby="nav-translate-usa-tab" onSubmit={translate_USA_to_EURO}>
                            <div className="border-card">
                                <p className="m-bottom-10">Американский размер</p>
                                <div className="row">
                                    <div className="d-flex col-4">
                                        <span className="label_D">D</span>
                                        <select
                                            className="form-select form-select-lg"
                                            aria-label=".form-select-lg example"
                                            onChange={(e) => setSelectedUSATranslateD(e.target.value)}
                                            value={selectedUSATranslateD}
                                        >
                                            <option value="20">20,0</option>
                                            <option value="20.5">20,5</option>
                                            <option value="21">21,0</option>
                                            <option value="21.5">21,5</option>
                                            <option value="22">22,0</option>
                                            <option value="22.5">22,5</option>
                                            <option value="23">23,0</option>
                                            <option value="23.5">23,5</option>
                                            <option value="24">24,0</option>
                                            <option value="24.5">24,5</option>
                                            <option value="25">25,0</option>
                                            <option value="25.5">25,5</option>
                                            <option value="26">26,0</option>
                                            <option value="26.5">26,5</option>
                                            <option value="27">27,0</option>
                                            <option value="27.5">27,5</option>
                                            <option value="28">28,0</option>
                                            <option value="28.5">28,5</option>
                                            <option value="29">29,0</option>
                                            <option value="29.5">29,5</option>
                                            <option value="30">30,0</option>
                                            <option value="30.5">30,5</option>
                                            <option value="31">31,0</option>
                                            <option value="31.5">31,5</option>
                                            <option value="32">32,0</option>
                                            <option value="32.5">32,5</option>
                                            <option value="33">33,0</option>
                                            <option value="33.5">33,5</option>
                                            <option value="34">34,0</option>
                                            <option value="34.5">34,5</option>
                                            <option value="35">35,0</option>
                                            <option value="35.5">35,5</option>
                                            <option value="36">36,0</option>
                                            <option value="36.5">36,5</option>
                                            <option value="37">37,0</option>
                                            <option value="37.5">37,5</option>
                                            <option value="38">38,0</option>
                                            <option value="38.5">38,5</option>
                                            <option value="39">39,0</option>
                                            <option value="39.5">39,5</option>
                                            <option value="40">40,0</option>
                                            <option value="40.5">40,5</option>
                                            <option value="41">41,0</option>
                                            <option value="41.5">41,5</option>
                                            <option value="42">42,0</option>
                                            <option value="42.5">42,5</option>
                                            <option value="43">43,0</option>
                                            <option value="43.5">43,5</option>
                                            <option value="44">44,0</option>
                                            <option value="44.5">44,5</option>
                                            <option value="45">45,0</option>
                                            <option value="45.5">45,5</option>
                                            <option value="46">46,0</option>
                                            <option value="46.5">46,5</option>
                                            <option value="47">47,0</option>
                                            <option value="47.5">47,5</option>
                                            <option value="48">48,0</option>
                                            <option value="48.5">48,5</option>
                                            <option value="49">49,0</option>
                                            <option value="49.5">49,5</option>
                                            <option value="50">50,0</option>
                                            <option value="50.5">50,5</option>
                                            <option value="51">51,0</option>
                                            <option value="51.5">51,5</option>
                                            <option value="52">52,0</option>
                                            <option value="52.5">52,5</option>
                                            <option value="53">53,0</option>
                                            <option value="53.5">53,5</option>
                                            <option value="54">54,0</option>
                                            <option value="54.5">54,5</option>
                                            <option value="55">55,0</option>
                                        </select>
                                    </div>
                                    <div className="d-flex col-4">
                                        <span className="label_A">A</span>
                                        <select
                                            className="form-select form-select-lg"
                                            aria-label=".form-select-lg example"
                                            onChange={(e) => setSelectedUSATranslateA(e.target.value)}
                                            value={selectedUSATranslateA}
                                        >
                                            <option value="5.5">5,5</option>
                                            <option value="6">6,0</option>
                                            <option value="6.5">6,5</option>
                                            <option value="7">7,0</option>
                                            <option value="7.5">7,5</option>
                                            <option value="8">8,0</option>
                                            <option value="8.5">8,5</option>
                                            <option value="9">9,0</option>
                                            <option value="9.5">9,5</option>
                                            <option value="10">10,0</option>
                                            <option value="10.5">10,5</option>
                                            <option value="11">11,0</option>
                                            <option value="11.5">11,5</option>
                                            <option value="12">12,0</option>
                                            <option value="12.5">12,5</option>
                                            <option value="13">13,0</option>
                                            <option value="13.5">13,5</option>
                                            <option value="14">14,0</option>
                                            <option value="14.5">14,5</option>
                                        </select>
                                    </div>
                                    <div className="d-flex col-4">
                                        <span className="label_C">C</span>
                                        <select
                                            className="form-select form-select-lg"
                                            aria-label=".form-select-lg example"
                                            onChange={(e) => setSelectedUSATranslateC(e.target.value)}
                                            value={selectedUSATranslateC}
                                        >
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                        </select>
                                    </div>
                                    <a href='/products' className="--orange-hover d-flex align-items-center mt-2">
                                        <span>Перейти в каталог</span>
                                        <i className="bi bi-arrow-right-circle ms-2"></i>
                                    </a>
                                </div>
                            </div>
                            <button className="btn btn-warning --orange-btn w-100" type="submit">Рассчитать</button>
                            <div className="result-block">
                                <p className="m-bottom-10">Европейский размер</p>
                                <div className="row">
                                    <div className="col-4">
                                        <label>Ширина</label>
                                        <div className="d-flex">
                                            <span className="label_A">A</span>
                                            <p>{resultEUROTranslateA} мм</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label>Профиль</label>
                                        <div className="d-flex">
                                            <span className="label_B">B</span>
                                            <p>{resultEUROTranslateB} %</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label>Диск</label>
                                        <div className="d-flex">
                                            <span className="label_C">C</span>
                                            <p>{resultEUROTranslateC} дюймов</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </>
    )
}