import React, {useEffect, useState} from 'react';
import ArticleForm from './ArticleForm';
import '../../resources/css/AdminPanel.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface AdminPanelProps {
    isAdmined: boolean;
    setIsAdmined: (isAdmined: boolean) => void
}

const AdminPanel: React.FC<AdminPanelProps> = ({isAdmined, setIsAdmined}) => {
    const [action, setAction] = useState<string>('add')
    const navigate = useNavigate();

    useEffect(() => {
        const checkAdminAccess = async () => {
            try {
                const token = sessionStorage.getItem('__access-token');
                if (!token) throw new Error('No token');
    
                const response = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/auth/admin', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
    
                if (response.data.message === 'Welcome, Admin!') {
                    setIsAdmined(true);
                } else {
                    setIsAdmined(false);
                }
            } catch (error: any) {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    // Тихо удаляем роль, без вывода в консоль
                    localStorage.removeItem('role');
                    setIsAdmined(false);
                }
            }
        };
        checkAdminAccess();
    }, [navigate]);
    

    return (
        <>
            {isAdmined && (
                <div className='panel-wrap'>
                    <h1>Admin Panel</h1>
                    <p>Выберете нужное действие: </p>
                    <div className='action-btns'>
                        <button className={`${action === 'add' && 'active'}`} onClick={() => setAction('add')} key='add'>Добавить статью</button>
                        <button className={`${action === 'edit' && 'active'}`} onClick={() => setAction('edit')} key='edit'>Редактровать статью</button>
                        <button className={`${action === 'delete' && 'active'}`} onClick={() => setAction('delete')} key='delete'>Удалить статью</button>
                    </div>
                    <ArticleForm action={action} isAdmined={isAdmined} setIsAdmined={setIsAdmined}/>
                </div>
            )}
        </>
    );
};

export default AdminPanel;
