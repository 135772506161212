import Danger from "../../resources/images/Danger.svg";
import RedPhone from "../../resources/images/RedPhone.svg";
import React, {useState} from "react";
import {isValidDigit, isValidText} from "../../utils/validation/Validation";
import '../../resources/css/LeavingRequestForm.css'
import {DataMessage} from "../../../interfaces/DataMessage";

export const LeavingRequestForm = () => {
    const [inputName, setName] = useState('');
    const [inputPhone, setPhone] = useState('');
    const [inputComment, setComment] = useState('');

    const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setName(e.target.value);
    };
    const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidDigit(e.target.value)) setPhone(e.target.value);
    };
    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
    };

    async function fetchData(event: any) {
        event.preventDefault();
        try {
            const response = await fetch(process.env.REACT_APP_SERVER_URL + '/api/support/products', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    name: inputName,
                    phone: inputPhone,
                    comment: inputComment,
                })
            });
            const data: DataMessage = await response.json();

            if (!response.ok) {
                console.error(data.message);
                return;
            }
            window.location.reload();
        } catch (error: any) {
            console.error(error.message);
        }
    }

    return (
        <>
            <div className="leaving-request-form d-flex flex-column align-items-center border-top border-1">
                <div className="d-flex justify-content-between w-100 gap-4">
                    <div className="d-flex justify-content-between align-items-start">
                        <img src={`${Danger}`} alt="..." style={{paddingRight: 10, maxWidth: 35}}/>
                        <span>Для уточнения наличия нужного товара Вы можете заполнить заявку и наш менеджер обязательно свяжется с Вами</span>
                    </div>
                    <div className="call">
                        <p>
                            <img src={`${RedPhone}`} alt="..." style={{paddingRight: 10}}/>
                            ОСТАЛИСЬ ВОПРОСЫ ?
                        </p>
                        <p>Звоните нам 80 (17) 686-55-77</p>
                    </div>
                </div>
                <form className="w-100" style={{maxWidth: 449}} onSubmit={fetchData}>
                    <div className="form-group mt-3">
                        <label className="form-control-label" htmlFor="inputLeavingRequestName">
                            Имя
                            <span className="--orange-txt fs-5"> *</span>
                        </label>
                        <input
                            type="text"
                            className="form-control p-3"
                            id="inputLeavingRequestName" required
                            value={inputName}
                            onChange={handleNameInputChange}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="inputLeavingRequestPhone">
                            Номер телефона
                            <span className="--orange-txt fs-5"> *</span>
                        </label>
                        <input
                            type="tel" required
                            id="inputLeavingRequestPhone"
                            className="form-control p-3"
                            aria-describedby="passwordHelpBlock"
                            value={inputPhone}
                            onChange={handlePhoneInputChange}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label className="form-control-label" htmlFor="inputLeavingRequestComment">Комментарий</label>
                        <textarea className="form-control p-3" id="inputLeavingRequestComment" rows={4}
                                  value={inputComment} onChange={handleCommentChange}
                        />
                    </div>
                    <button className="btn btn-warning --orange-btn w-100 mt-3" type="submit">
                        Отправить заявку
                    </button>
                </form>
            </div>
        </>
    )
}