import React, { useEffect, useState } from 'react'
import '../../resources/css/TyresInfo.css'
import Accident from '../../resources/images/accident.svg'

export const TyresInfo = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 700);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    return (
        <div className='info-tyres-wrap'>
            <h1>Электронный знак</h1>
            <div className='tyres-accident'>
                <div className='accident-info'>
                    <div>
                        <img src={Accident} alt=""/>
                    </div>
                    <span>КАЖДАЯ 7 АВАРИЯ ПРОИСХОДИТ ИЗ-ЗА ПОВРЕЖДЕНИЯ ШИН</span>
                </div>
                {!isMobile && (
                    <div className='accident-info'>
                        <div>
                            <p>47%</p>
                        </div>
                        <span>ИЗ НИХ - ПО ВИНЕ ИЗНОШЕННОСТИ, ПЛОХОГО КАЧЕСТВА</span>
                    </div>
                )}
                <div className='accident-info'>
                    <div>
                        <p>30%</p>
                    </div>
                    <span>ИЗ НИХ - ИЗ-ЗА НЕСООТВЕТСТВИЯ ШИН ПОГОДЕ</span>
                </div>
            </div>
        </div>
    )
}
