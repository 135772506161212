import React from 'react';
import '../../resources/css/ComparisonAlert.css'
import {ComparisonAlertProps} from "../../../interfaces/props/ComparisonAlertProps";

export const ComparisonAlert: React.FC<ComparisonAlertProps> = ({show, onClose, message, buttonText, buttonLink}) => {
    return (
        <a href={buttonLink}
           className={`alert comparison-alert fade show z-3 ${show ? '' : 'd-none'}`}
           role="alert"
        >
            <span>{buttonText}</span>
        </a>
    );
};