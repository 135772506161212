import '../../resources/css/TireTestPage.css'
import BlueWhell from "../../resources/images/BlueWheel.png";
import React, {useEffect, useState} from "react";
import {Breadcrumbs} from "../components/Breadcrumbs";
import {Pagination} from "../components/Pagination";
import {TestCardItem} from "../components/TestCardItem";
import WhiteSummer from "../../resources/images/WhiteSummer.svg";
import Summer from "../../resources/images/Summer.svg";
import WhiteDemi from "../../resources/images/WhiteDemi.svg";
import Demi from "../../resources/images/Demi.svg";
import WhiteWinter from "../../resources/images/WhiteWinter.svg";
import Winter from "../../resources/images/Winter.svg";
import Thorns from "../../resources/images/Thorns.svg";
import axios from 'axios';
import AdminPanel from '../components/AdminPanel';
import { ArticleProps } from '../../../interfaces/props/ArticleProps';

const BREADCRUMB_ITEMS = [
    {label: 'Главная', href: '/'},
    {label: 'Тесты шин', href: '/tests'},
];


export const TireTestPage = () => {
    const [articles, setArticles] = useState<ArticleProps[]>([])
    const [selectedSeason, setSelectedSeason] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isAdmined, setIsAdmined] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)

    const [title, setTitle] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [selectedYear, setSelectedYear] = useState('all');
    const [selectedVendor, setSelectedVendor] = useState('all');

    const [loadingFilter, setLoadingFilter] = useState<boolean>(true);
    const [brands, setBrands] = useState<any>([])

    const itemsPerPage = 12;
    const totalItems = articles.length; // Общее количество элементов

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedYear(event.target.value);
    };

    const handleVendor = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedVendor(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const brandsResponse = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/carfilter/get-brands')
                setBrands(brandsResponse.data)
            } catch (error) {
                console.error('Error fetching brands:', error);
            } finally {
                setLoadingFilter(false);
            }
        }
        fetchData()
    }, [])

    const handleFilterSubmit = async () => {
        setLoading(true)
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/articles/filter', {
                params: {
                    vendor: selectedVendor !== 'all' ? selectedVendor : undefined,
                    year: selectedYear !== 'all' ? selectedYear : undefined,
                    season: selectedSeason !== 'all' ? selectedSeason : undefined,
                    currentPage,
                    itemsPerPage,
                },
            });
            setArticles(response.data);
            setLoading(false)
        } catch (error) {
            console.error('Ошибка при фильтрации статей:', error);
        }
    };

    const handleSeasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedSeason(event.target.id.replace("btn-check-", ""));
    };

    // Функция, которая будет вызываться при изменении страницы
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    // Логика для расчета индексов начала и конца элементов для текущей страницы
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/articles/get-articles')
            setArticles(response.data)
            setLoading(false)
        }
        fetchData()
    }, [])
    return (
        <>
            <Breadcrumbs items={BREADCRUMB_ITEMS}/>
            <div className="tire-test-page">
                <h3>Тест шин</h3>

                <div className="row tire-test justify-content-between mb-3">
                    <img className="col-xl-3" src={`${BlueWhell}`} alt='...'/>
                    <div className="col-xl-9">
                        <div className="tyres-tab-panel">
                            <div className="d-flex justify-content-between">
                                <div className="form-group">
                                    <label htmlFor="mySelect">Производители</label>
                                    <select className="form-select form-select-lg"
                                            aria-label=".form-select-lg example"
                                            id="mySelect"
                                            value={selectedVendor}
                                            onChange={handleVendor}
                                            disabled={loadingFilter}>
                                        <option value="all">Все</option>
                                        {brands.map((brand: any) => (
                                            <option value={brand.name} key={`${brand.id}-${brand.name}`}>{brand.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mySelect">Год публикации</label>
                                    <select
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        value={selectedYear}
                                        onChange={handleChange}
                                    >
                                        <option value="all">Все</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between" style={{marginTop: 25}}>
                                <div className="season-select-block">
                                    <div className="btn-group" role="group"
                                         aria-label="Basic radio toggle button group">
                                        <input type="radio" className="btn-check" id="btn-check-all" name="season"
                                               defaultChecked
                                               autoComplete="off" onChange={handleSeasonChange}/>
                                        <label className="btn btn-outline-primary rounded-start"
                                               htmlFor="btn-check-all">
                                            Все
                                        </label>

                                        <input type="radio" className="btn-check" id="btn-check-summer"
                                               autoComplete="off"
                                               name="season" onChange={handleSeasonChange}/>
                                        <label className="btn btn-outline-primary" htmlFor="btn-check-summer">
                                            <img src={`${selectedSeason === "summer" ? WhiteSummer : Summer}`}
                                                 style={{maxWidth: 20}}
                                                 alt="..."/>
                                        </label>
                                        <input type="radio" className="btn-check" id="btn-check-demi" autoComplete="off"
                                               name="season"
                                               onChange={handleSeasonChange}/>
                                        <label className="btn btn-outline-primary" htmlFor="btn-check-demi">
                                            <img src={`${selectedSeason === "demi" ? WhiteDemi : Demi}`}
                                                 style={{maxWidth: 20}}
                                                 alt="..."/>
                                        </label>
                                        <input type="radio" className="btn-check" id="btn-check-winter"
                                               autoComplete="off"
                                               name="season" onChange={handleSeasonChange}/>
                                        <label className="btn btn-outline-primary" htmlFor="btn-check-winter">
                                            <img src={`${selectedSeason === "winter" ? WhiteWinter : Winter}`}
                                                 style={{maxWidth: 20}}
                                                 alt="..."/>
                                        </label>
                                        <input type="radio" className="btn-check" id="btn-check-thorns"
                                               autoComplete="off"
                                               name="season" onChange={handleSeasonChange}/>
                                        <label className="btn btn-outline-primary rounded-end"
                                               htmlFor="btn-check-thorns">
                                            <img src={`${selectedSeason === "thorns" ? Thorns : Thorns}`}
                                                 style={{maxWidth: 20}}
                                                 alt="..."/> {/* Thorns doesn't have a white variant */}
                                        </label>
                                    </div>
                                </div>
                                <button className="btn btn-warning --orange-btn btn-filter" onClick={handleFilterSubmit}>Подобрать шины</button>
                            </div>
                        </div>
                    </div>
                </div>

                <AdminPanel isAdmined={isAdmined} setIsAdmined={setIsAdmined}/>
                    <>
                        {loading ? (
                            <div className="loading-spinner d-flex justify-content-center">
                                <div className="spinner-grow text-dark" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <h5 style={{fontSize: '22px', fontWeight: '400'}}>Найдено {articles.length} теста(ов)</h5>
                                <div
                                    className="mb-4 row row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 row-cols-xl-4 g-4 gap-1 m-0 justify-content-around">
                                    {articles.map((item) => (
                                        <TestCardItem
                                            key={item.id}
                                            id={item.id}
                                            image1={item.image1}
                                            title={item.title}
                                            publishedDate={item.publishedDate}
                                        />
                                    ))}
                                </div>
                                
                                <Pagination
                                    totalItems={totalItems}
                                    itemsPerPage={itemsPerPage}
                                    onPageChange={handlePageChange}
                                />
                            </>
                        )}
                    </>
            </div>
        </>
    )
}
