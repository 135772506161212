import React, { useEffect, useState } from 'react'
import {ProductInfo} from '../components/ProductInfo'
import '../../resources/css/ProductPage.css'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { AskForm } from '../components/AskForm'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { ProductProps } from '../../../interfaces/props/ProductProps'

export const ProductPage = () => {
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation();
    const defaultProduct: ProductProps = {
        id: 0,
        model: '',
        vendor: { name: '' },
        images: [],
        price: '',
        filters: [{ name: '', id: 0, values: [{ name: '' }] }],
        supplierInfo: '',
        quantity: ''
    };

    const [product, setProduct] = useState<ProductProps>(defaultProduct);
    const { productId } = useParams();
    const BreadcrumbItems = [
        {label: 'Главная', href: '/'},
        {
          label: 'Каталог',
          href: `/products/legkovye-shiny/`,
        },
        {label: 'Карточка товара', href: `/product/${productId}`},
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/api/products/get-product?productId=${productId}`
              );
              setProduct(response.data);
              setLoading(false)
            } catch (error) {
              console.error("Ошибка при получении данных:", error);
            }
          };
        fetchData();
    }, [productId])
    return (
        <div className='product-page-container'>
            <Breadcrumbs items={BreadcrumbItems} />
            {loading ? (
              <div className="loading-spinner d-flex justify-content-center">
                <div className="spinner-grow text-dark" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <ProductInfo product={product}/>
            )}
            <AskForm />
        </div>
    )
}
