import Danger from "../../resources/images/Danger.svg";
import React, {useRef, useState} from "react";
import {fetchDataResetPassword} from "../../utils/fetch's/apiRequests/fetchDataResetPassword";
import {fetchDataVerificationCode} from "../../utils/fetch's/apiRequests/fetchDataVerificationCode";
import {handleVerificationCodeChange} from "../../utils/handles/handleVerificationCodeChange";
import {useTimer} from "../../utils/timer/timer";
import {isValidText} from "../../utils/validation/Validation";
import {fetchLoginData} from "../../utils/fetch's/apiRequests/fetchLoginData";
import {fetchRegisterData} from "../../utils/fetch's/apiRequests/fetchRegisterData";
import {fetchDataForgotPassword} from "../../utils/fetch's/apiRequests/fetchDataForgotPassword";
import {useNavigate} from "react-router-dom";
import {resetStates} from "../../utils/resetStates/resetStates";

export const LoginFormModal: React.FC<{ modalName: string }> = ({modalName}) => {

    const [inputMail, setMail] = useState('');
    const [inputPassword, setPassword] = useState('');

    const [registerInputMail, setRegisterMail] = useState('');
    const [registerInputFirstName, setRegisterFirstName] = useState('');
    const [registerInputLastName, setRegisterLastName] = useState('');
    const [registerInputPatronymic, setRegisterPatronymic] = useState('');
    const [registerInputPassword, setRegisterPassword] = useState('');

    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const [registerErrorMessage, setRegisterErrorMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const [verificationCode, setVerificationCode] = React.useState<string[]>(Array(6).fill(''));

    const [showResetPassword, setShowResetPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [resetInputMail, setResetMail] = useState('');
    const [resetInputPassword, setResetPassword] = useState('');

    const navigate = useNavigate();
    const handleFirstNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setRegisterFirstName(e.target.value);
    };
    const handleLastNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setRegisterLastName(e.target.value);
    };

    const handlePatronymicInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isValidText(e.target.value)) setRegisterPatronymic(e.target.value);
    };

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        await fetchLoginData(inputMail, inputPassword, setLoginErrorMessage, navigate);
    };
    const handleRegister = async (event: React.FormEvent) => {
        event.preventDefault();
        await fetchRegisterData(
            registerInputFirstName,
            registerInputLastName,
            registerInputPatronymic,
            registerInputMail,
            registerInputPassword,
            setRegisterErrorMessage,
            navigate
        );
    };
    const handleForgotPassword = async (event: React.FormEvent) => {
        event.preventDefault();
        await fetchDataForgotPassword(
            resetInputMail,
            setErrorMessage,
            setShowNewPassword,
            setIsButtonDisabled,
            setTimeRemaining
        );
    };
    const handleResetPassword = async (event: React.FormEvent) => {
        event.preventDefault();
        await fetchDataResetPassword(resetInputMail, resetInputPassword, setErrorMessage, navigate);
    };
    const handleVerifyCode = async (event: React.FormEvent) => {
        event.preventDefault();
        await fetchDataVerificationCode(resetInputMail, verificationCode, setErrorMessage, setShowResetPassword);
    };

    const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        handleVerificationCodeChange(index, event, verificationCode, setVerificationCode, inputsRef);
    };

    const [isButtonDisabled, timeRemaining, handleReset, setTimeRemaining, setIsButtonDisabled] = useTimer();

    const handleSendPasswordReset = async (event: React.FormEvent) => {
        event.preventDefault();
        await handleForgotPassword(event);
        handleReset();
    };
    return (
        <>
            <div className={`modal fade login-form`}
                 id={`staticBackdropLogin-${modalName}`}
                 aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false"
                 aria-labelledby="staticBackdropLabel">
                <div className="modal-dialog modal-dialog-centered" style={{maxWidth: 496}}>
                    <form className="modal-content" onSubmit={handleLogin}>
                        <div className="modal-body mt-2" style={{paddingLeft: 54, paddingRight: 54,}}>
                            <div className="d-flex justify-content-between">
                                <h5 className="modal-title" id="staticBackdropLabel">Личный кабинет</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => resetStates([setMail, setPassword, setRegisterMail, setRegisterFirstName, setRegisterLastName, setRegisterPatronymic, setRegisterPassword, setLoginErrorMessage, setRegisterErrorMessage, setErrorMessage, setResetMail, setResetPassword])}>
                                </button>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor={`inputLoginEmail-${modalName}`}>Эл. почта
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input type="email" className="form-control p-3" id={`inputLoginEmail-${modalName}`}
                                       required
                                       value={inputMail} onChange={(e) => setMail(e.target.value)}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor={`inputLoginPassword-${modalName}`}>Пароль
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input type="password" id={`inputLoginPassword-${modalName}`}
                                       className="form-control p-3"
                                       aria-describedby="passwordHelpBlock" required
                                       value={inputPassword} onChange={(e) => setPassword(e.target.value)}
                                />
                                <small id="passwordHelpBlock"
                                       className="form-text text-muted d-flex justify-content-end">
                                    <button className="btn --orange-txt" style={{paddingRight: 0}}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#staticBackdropResetPassword-${modalName}`}>
                                        Забыли пароль?
                                    </button>
                                </small>
                            </div>
                            <p className={`error-message d-flex align-items-center --orange-txt ${loginErrorMessage === '' ? 'd-none' : ''}`}>
                                <img src={`${Danger}`} alt="..." style={{paddingRight: 5}}/>
                                {loginErrorMessage}
                            </p>
                            <div className="d-flex flex-wrap justify-content-between mt-3 mb-2">
                                <button className="btn btn-warning --orange-btn w-100 p-3 mb-3" type="submit">Войти
                                </button>
                                <p className="last-small-text">У вас нет аккаунта? </p>
                                <button type="button" className="btn btn-outline"
                                        data-bs-target={`#registerModal-${modalName}`}
                                        data-bs-toggle="modal"
                                        data-bs-dismiss="modal">
                                    Зарегистрироваться
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="modal fade register-form" id={`registerModal-${modalName}`} aria-hidden="true"
                 aria-labelledby="staticBackdropLabel" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered" style={{maxWidth: 496}}>
                    <form className="modal-content" onSubmit={handleRegister}>
                        <div className="modal-body" style={{paddingLeft: 54, paddingRight: 54,}}>
                            <div className="d-flex justify-content-between">
                                <h5 className="modal-title" id="staticBackdropLabel">Регистрация</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => resetStates([setMail, setPassword, setRegisterMail, setRegisterFirstName, setRegisterLastName, setRegisterPatronymic, setRegisterPassword, setLoginErrorMessage, setRegisterErrorMessage, setErrorMessage, setResetMail, setResetPassword])}>
                                </button>
                            </div>

                            <div className="form-group mt-3">
                                <label className="form-control-label" htmlFor={`inputRegisterName-${modalName}`}>
                                    Имя
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input type="text" className="form-control p-3"
                                       id={`inputRegisterName-${modalName}`}
                                        required value={registerInputFirstName}
                                       onChange={handleFirstNameInputChange}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label className="form-control-label"
                                       htmlFor={`inputRegisterLastname-${modalName}`}>
                                    Фамилия
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input type="text" className="form-control p-3"
                                       id={`inputRegisterLastname-${modalName}`}
                                        required
                                       value={registerInputLastName}
                                       onChange={handleLastNameInputChange}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label className="form-control-label"
                                       htmlFor={`inputRegisterPatronymic-${modalName}`}>
                                    Отчество
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input type="text" className="form-control p-3"
                                       id={`inputRegisterPatronymic-${modalName}`}
                                       required
                                       value={registerInputPatronymic}
                                       onChange={handlePatronymicInputChange}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label htmlFor={`inputRegisterMail-${modalName}`}>
                                    Эл. почта
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input type="email" className="form-control p-3"
                                       id={`inputRegisterMail-${modalName}`}
                                       required
                                       value={registerInputMail} onChange={(e) => setRegisterMail(e.target.value)}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label htmlFor={`inputRegisterPassword-${modalName}`}>
                                    Пароль
                                    <span className="--orange-txt fs-5"> *</span>
                                </label>
                                <input type="password" id={`inputRegisterPassword-${modalName}`}
                                       className="form-control p-3"
                                       aria-describedby="passwordHelpBlock" required
                                       value={registerInputPassword}
                                       onChange={(e) => setRegisterPassword(e.target.value)}
                                />
                            </div>
                            <p className={`error-message d-flex align-items-center --orange-txt mt-3 ${registerErrorMessage === '' ? 'd-none' : ''}`}>
                                <img src={`${Danger}`} alt="..." style={{paddingRight: 5}}/>
                                {registerErrorMessage}
                            </p>
                            <div className="d-flex flex-wrap justify-content-between mt-3">
                                <button className="btn btn-warning --orange-btn w-100 p-3"
                                        type="submit">Зарегистрироваться
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="modal fade reset-password-form" id={`staticBackdropResetPassword-${modalName}`}
                 data-bs-backdrop="static"
                 data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{maxWidth: 496}}>
                    {!showNewPassword ? (
                        <>
                            <form className="modal-content" onSubmit={handleForgotPassword}>
                                <div className="modal-body" style={{paddingLeft: 54, paddingRight: 54,}}>
                                    <div className="d-flex justify-content-between">
                                        <h5 className="modal-title fw-bold" id="staticBackdropLabel">Восстановление
                                            пароля</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => resetStates([setMail, setPassword, setRegisterMail, setRegisterFirstName, setRegisterLastName, setRegisterPatronymic, setRegisterPassword, setLoginErrorMessage, setRegisterErrorMessage, setErrorMessage, setResetMail, setResetPassword])}>

                                        </button>
                                    </div>
                                    <p>На указанную вами почту будет отпралено письмо с восстановлением пароля</p>
                                    <div className="form-group mt-3">
                                        <label htmlFor={`inputResetPasswordMail-${modalName}`}>
                                            Эл. почта
                                            <span className="--orange-txt fs-5"> *</span>
                                        </label>
                                        <input type="email" className="form-control p-3"
                                               id={`inputResetPasswordMail-${modalName}`}
                                               required
                                               value={resetInputMail} onChange={(e) => setResetMail(e.target.value)}
                                        />
                                    </div>
                                    <div className="d-flex flex-wrap justify-content-between mt-3">
                                        <button className="btn btn-warning --orange-btn w-100 p-3"
                                                type="submit">Отправить письмо
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </>
                    ) : (
                        <>
                            {!showResetPassword ?
                                <>
                                    <form className="modal-content" onSubmit={handleVerifyCode}>
                                        <div className="modal-body" style={{paddingLeft: 54, paddingRight: 54,}}>
                                            <div className="d-flex justify-content-between">
                                                <h5 className="modal-title fw-bold"
                                                    id="staticBackdropLabel">Восстановление
                                                    пароля</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                            </div>
                                            <label htmlFor="verificationCode">Код верификации <span
                                                className="--orange-txt fs-5">*</span></label>
                                            <div className='d-flex'>
                                                {Array.from({length: 6}, (_, index) => (
                                                    <input
                                                        key={index}
                                                        type="text"
                                                        id={`inputResetPasswordResetPassword${index}`}
                                                        className="form-control p-3 mx-1 text-center"
                                                        aria-describedby="passwordHelpBlock"
                                                        required
                                                        value={verificationCode[index]}
                                                        onChange={(e) => handleChange(index, e)}
                                                        ref={(el) => inputsRef.current[index] = el}
                                                        maxLength={1}
                                                    />
                                                ))}
                                            </div>
                                            <p className={`error-message d-flex align-items-center --orange-txt mt-1 ${errorMessage === '' ? 'd-none' : ''}`}>
                                                <img src={`${Danger}`} alt="..." style={{paddingRight: 5}}/>
                                                {errorMessage}
                                            </p>
                                            <div className="d-flex flex-wrap justify-content-between mt-3 mb-2">
                                                <button className="btn btn-warning --orange-btn w-100 p-3 mb-3"
                                                        type="submit">Отправить
                                                </button>
                                                <p className="last-small-text">Письмо не пришло?</p>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline"
                                                    onClick={handleSendPasswordReset}
                                                    disabled={isButtonDisabled}
                                                >
                                                    {isButtonDisabled ? `Отправить (${timeRemaining} с)` : 'Отправить'}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </>
                                :
                                <>
                                    <form className="modal-content" onSubmit={handleResetPassword}>
                                        <div className="modal-body mt-2"
                                             style={{paddingLeft: 54, paddingRight: 54,}}>
                                            <div className="d-flex justify-content-between">
                                                <h5 className="modal-title" id="staticBackdropLabel">Восстановление
                                                    пароля</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                            </div>
                                            <div className="form-group mt-3">
                                                <label htmlFor={`inputResetPassword-${modalName}`}>
                                                    Новый пароль
                                                    <span className="--orange-txt fs-5"> *</span>
                                                </label>
                                                <input type="password" id={`inputResetPassword-${modalName}`}
                                                       className="form-control p-3"
                                                       aria-describedby="passwordHelpBlock" required
                                                       value={resetInputPassword}
                                                       onChange={(e) => setResetPassword(e.target.value)}
                                                />
                                            </div>
                                            <div className="d-flex flex-wrap justify-content-between mt-3 mb-2">
                                                <button className="btn btn-warning --orange-btn w-100 p-3 mb-3"
                                                        type="submit">Войти
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            }
                        </>
                    )}
                </div>
            </div>
        </>

    )
}