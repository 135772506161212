import React, {useEffect, useState} from 'react'
import {Breadcrumbs} from '../components/Breadcrumbs'
import '../../resources/css/ComparisonPage.css'
import Smile from '../../resources/images/SadSmiley2.svg'
import {MiniCard} from '../components/MiniCard'
import {AskForm} from '../components/AskForm'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {ProductProps} from "../../../interfaces/props/ProductProps";

const BREADCRUMB_ITEMS = [
    {label: 'Главная', href: '/'},
    {label: 'Каталог', href: '/products/legkovye-shiny/'},
    {label: 'Сравнение товара', href: '/comparison'},
]


export const ComparisonPage = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [columns, setColumns] = useState(2);
    const [productData, setProductData] = useState<ProductProps[] | null>(null);
    const [productDataLengthThirdStyle, setProductDataLengthThirdStyle] = useState('');
    const [productDataLengthSecondStyle, setProductDataLengthSecondStyle] = useState('');

    const [selectedFirstTyre, setSelectedFirstTyre] = useState<ProductProps>();
    const [selectedSecondTyre, setSelectedSecondTyre] = useState<ProductProps>();
    const [selectedThirdTyre, setSelectedThirdTyre] = useState<ProductProps>();

    useEffect(() => {
        const productCount = document.querySelectorAll('.card').length;
        setColumns(productCount + 1);
    }, []);
    const gridStyle: React.CSSProperties = {
        '--columns': columns.toString(),
    } as React.CSSProperties;

    useEffect(() => {
        const storedData = sessionStorage.getItem('__product-data-comparison');

        if (storedData) {
            const storedDataJson = JSON.parse(storedData);
            setProductData(storedDataJson);
            setProductDataLengthThirdStyle(storedDataJson.length === 2 || storedDataJson.length === 1 ? 'd-none' : '');
            setProductDataLengthSecondStyle(storedDataJson.length === 1 ? 'd-none' : '');
        }
        setLoading(false)
    }, []);

    useEffect(() => {
        if (productData) {
            setSelectedFirstTyre(productData[0]);
            setSelectedSecondTyre(productData[1] || productData[0]);
            setSelectedThirdTyre(productData[2] || productData[1] || productData[0]);
        }
    }, [productData]);

    const handleTyreChangeFirst = (tyre: ProductProps) => {
        setSelectedFirstTyre(tyre);
    };
    const handleTyreChangeSecond = (tyre: ProductProps) => {
        setSelectedSecondTyre(tyre);
    };
    const handleTyreChangeThird = (tyre: ProductProps) => {
        setSelectedThirdTyre(tyre);
    };

    function handleClearProductData() {
        sessionStorage.removeItem('__product-data-comparison');
        window.location.reload();
    }

    function getFirstFilter(index: number): string {
        return selectedFirstTyre?.filters.find(filter => filter.id === index)?.values[0].name || '–'
    }

    function getSecondFilter(index: number): string {
        return selectedSecondTyre?.filters.find(filter => filter.id === index)?.values[0].name || '–'
    }

    function getThirdFilter(index: number): string {
        return selectedThirdTyre?.filters.find(filter => filter.id === index)?.values[0].name || '–'
    }


    return (
        <div>
            <Breadcrumbs items={BREADCRUMB_ITEMS}/>
            <div className='comparison-wrap'>
                <h1>Сравнение товаров : Легковые шины</h1>
                <div className="actions-group">
                    <div className='group1 d-flex flex-column'>
                        <a className="btn" href='/products'>
                            <i className="bi bi-plus-square me-2"></i>
                            <span>Добавить товар</span>
                        </a>
                        {productData ? (
                            <a className="btn" onClick={handleClearProductData}>
                                <i className="bi bi-trash3 me-2"></i>
                                <span>Очистить список</span>
                            </a>
                        ) : (<></>)}
                    </div>
                </div>

                {loading ? (
                    <div className="loading-spinner d-flex justify-content-center">
                        <div className="spinner-grow text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        {productData && selectedFirstTyre ? (
                            <>
                                <div className="products-group">
                                    {productData.length === 1 ? (
                                        <>
                                            <MiniCard tyres={productData} onTyreChange={handleTyreChangeFirst}/>
                                        </>
                                    ) : (
                                        <>
                                            {productData.length === 2 ? (
                                                <>
                                                    <MiniCard tyres={productData} onTyreChange={handleTyreChangeFirst}/>
                                                    <MiniCard tyres={productData} onTyreChange={handleTyreChangeSecond}/>
                                                </>
                                            ) : (
                                                <>
                                                    <MiniCard tyres={productData} onTyreChange={handleTyreChangeFirst}/>
                                                    <MiniCard tyres={productData} onTyreChange={handleTyreChangeSecond}/>
                                                    <MiniCard tyres={productData} onTyreChange={handleTyreChangeThird}/>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="table-container">
                                    <table className="table table-striped">
                                        <tbody>
                                        {[
                                            {
                                                label: 'Шипы',
                                                values: [getFirstFilter(8632), getSecondFilter(8632), getThirdFilter(8632),]
                                            },
                                            {
                                                label: 'Сезонность',
                                                values: [getFirstFilter(8631), getSecondFilter(8631), getThirdFilter(8631),]
                                            },
                                            {
                                                label: 'Тип автомобиля',
                                                values: [getFirstFilter(8633), getSecondFilter(8633), getThirdFilter(8633),]
                                            },
                                            {
                                                label: 'Ширина профиля',
                                                values: [getFirstFilter(8628), getSecondFilter(8628), getThirdFilter(8628),]
                                            },
                                            {
                                                label: 'Высота профиля',
                                                values: [getFirstFilter(8629), getSecondFilter(8629), getThirdFilter(8629),]
                                            },
                                            {
                                                label: 'Диаметр',
                                                values: [getFirstFilter(8630), getSecondFilter(8630), getThirdFilter(8630),]
                                            },
                                            {
                                                label: 'Индекс нагрузки',
                                                values: [getFirstFilter(8637), getSecondFilter(8637), getThirdFilter(8637),]
                                            },
                                            {
                                                label: 'Максимальная нагрузка',
                                                values: [getFirstFilter(8638), getSecondFilter(8638), getThirdFilter(8638),]
                                            },
                                            {
                                                label: 'Индекс максимальной скорости',
                                                values: [getFirstFilter(8639), getSecondFilter(8639), getThirdFilter(8639),]
                                            },
                                            {
                                                label: 'Конструкция',
                                                values: [getFirstFilter(8634), getSecondFilter(8634), getThirdFilter(8634),]
                                            },
                                            {
                                                label: 'Run Flat',
                                                values: [getFirstFilter(8635), getSecondFilter(8635), getThirdFilter(8635),]
                                            },
                                            {
                                                label: 'Способ герметизации',
                                                values: [getFirstFilter(8636), getSecondFilter(8636), getThirdFilter(8636),]
                                            }
                                        ].map((item, index) => (
                                            <tr key={index}>
                                                <td className="header">{item.label}</td>
                                                <td>{item.values[0]}</td>
                                                <td className={productDataLengthSecondStyle}>{item.values[1]}</td>
                                                <td className={productDataLengthThirdStyle}>{item.values[2]}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                </div>
                            </>
                        ) : (
                            <div className="text-center">
                                <img src={Smile as string} alt='...'/>
                                <h3 className="mt-2">Вы еще не выбрали товары для сравнения</h3>
                            </div>
                        )}
                    </>
                )}
                <AskForm/>
            </div>
        </div>
    )
}
