function isValidText(value: string) {
    return /^[A-Za-zА-Яа-я\s-]*$/.test(value);
}

function isValidDigit(value: string) {
    return /^\+?\d*$/.test(value);
}

function isValidGosNumber(value: string) {
    return /^[A-Za-z0-9]+$/.test(value);
}

function isValidPasswordLength(value: string, minLength: number = 6) {
    return value.length >= minLength;
}

export {
    isValidText,
    isValidDigit,
    isValidPasswordLength,
    isValidGosNumber
}