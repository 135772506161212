import {Header} from "../views/components/Header";
import {Outlet} from "react-router-dom";
import React from "react";

export const NotFoundLayout = () => {
    return (
        <>
            <Header styles={'not-found-header'}/>
            <main className="container container-1120">
                <Outlet/>
            </main>
        </>
    )
}