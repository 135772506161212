import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Breadcrumbs} from "../components/Breadcrumbs";
import { ArticleProps } from "../../../interfaces/props/ArticleProps";
import Cap from '../../resources/images/cap.png'
import axios from "axios";
import '../../resources/css/TireTestArticlePage.css'

export const TireTestArticlePage = () => {
    const {article_id} = useParams();
    
    const defaultArticle: ArticleProps = {
        id: 0,
        title: '',
        content1: '',
        content2: '',
        content3: '',
        manufacturer: '',
        publishedDate: '',
        image1: '',
        image2: '',
        image3: '',
    };    
    const [article, setArticle] = useState<ArticleProps>(defaultArticle);
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                  `${process.env.REACT_APP_SERVER_URL}/api/articles/get-article-info?articleId=${article_id}`
                );
                setArticle(response.data);
            } catch (error) {
                console.error("Ошибка при получении данных:", error);
            } finally {
                setLoading(false);
            }
        }
        fetchData()
    }, [article_id]);
    
    const BREADCRUMB_ITEMS = [
        {label: 'Главная', href: '/'},
        {label: 'Тесты шин', href: '/tests'},
        {label: 'Статья', href: `/tests/article/${article_id}`},
    ];

    return (
        <>
            <Breadcrumbs items={BREADCRUMB_ITEMS}/>
            {loading ? (
              <div className="loading-spinner d-flex justify-content-center">
                <div className="spinner-grow text-dark" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
                article ? (
                    <div className="tire-test-article-component">
                        <h1 className="article-title">{article.title}</h1>
                        <p className="publication-date">Дата публикации: {article.publishedDate}</p>
                        
                        <div className="content1">
                            {article.image1 && (
                                <img
                                    src={article.image1 ? `${process.env.REACT_APP_SERVER_URL}${article.image1}` : Cap}
                                    alt="Article Image"
                                />
                            )}
                            <p style={{whiteSpace: 'pre-wrap'}}>{article.content1}</p>
                        </div>
                        <div className="content2">
                            {article.image2 && (
                                <img
                                    src={article.image2 ? `${process.env.REACT_APP_SERVER_URL}${article.image2}` : Cap}
                                    alt="Article Image"
                                />
                            )}
                            <p style={{whiteSpace: 'pre-wrap'}}>{article.content2}</p>
                        </div>
                        <div className="content3">
                            {article.image3 && (
                                <img
                                    src={article.image3 ? `${process.env.REACT_APP_SERVER_URL}${article.image3}` : Cap}
                                    alt="Article Image"
                                />
                            )}
                            <p style={{whiteSpace: 'pre-wrap'}}>{article.content3}</p>
                        </div>
                    </div>
                ) : (
                    <div>Статья не найдена</div>
                )
            )}
        </>
    )
}