import {useState, useEffect} from 'react';

export const useTimer = (initialTime: number = 99): [boolean, number, () => void, (value: (((prevState: number) => number) | number)) => void, (value: (((prevState: boolean) => boolean) | boolean)) => void] => {
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [timeRemaining, setTimeRemaining] = useState<number>(initialTime);

    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval> | null = null;

        const startTimer = () => {
            intervalId = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000);
        };

        startTimer();

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    useEffect(() => {
        if (timeRemaining === 0) {
            setIsButtonDisabled(false);
            setTimeRemaining(initialTime);
        }
    }, [timeRemaining, initialTime]);

    const handleReset = () => {
        setIsButtonDisabled(true);
        setTimeRemaining(initialTime);
    };

    return [isButtonDisabled, timeRemaining, handleReset, setTimeRemaining, setIsButtonDisabled];
};
