export const NotFoundPage = () => {
    return (
        <div className="d-flex justify-content-center mt-5 mb-5">
            <div className="text-center mt-5" style={{maxWidth: 715}}>
                <h3 className="mt-2">Мы уже работаем над устранением проблемы</h3>
                <h1 className="heading404 mt-2" style={{color: '#4B6EFF'}}>404</h1>
                <p className="mt-3">Попробуйте обновить страницу через некоторое время либо вернуться на главную</p>
                <p className="fw-medium mt-3">Извините за временные неудобства</p>
                <a href="/" className="btn btn-warning --orange-btn w-100 p-3 mt-5" type="button"
                        style={{maxWidth: 283}}>Перейти на главную
                </a>
            </div>
        </div>
    )
}