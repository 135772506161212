import {Header} from "../views/components/Header";
import {Outlet} from "react-router-dom";
import {Footer} from "../views/components/Footer";
import React from "react";
import {Slider} from "../views/components/Slider";

export const HomeLayout = () => {
    return (
        <>
            <Header styles={'home-header'}/>
            <Slider/>
            <main className="container container-1120">
                <Outlet/>
            </main>
            <Footer/>
        </>
    )
}