import React from 'react'
import '../../resources/css/OrderBlock.css'

interface OrderBlockProps {
    noBtn?: boolean;
    paymentMethod?: string;
}

export const OrderBlock:React.FC<OrderBlockProps> = ({noBtn, paymentMethod}) => {
    const cartProducts = JSON.parse(localStorage.getItem("cart") || '[]');
    let orderPrice;
    let salePrice;
    if (cartProducts && cartProducts.length > 0) {
        const prodPrices = cartProducts.map((item: any) => item.price * item.quantity);
        const salePrices = cartProducts.map((item: any) => item.price * item.quantity * (item.vendor === 'Kumho' || item.vendor === 'Arivo' ? 0.25 : 0.15));
        orderPrice = prodPrices.reduce((a: number, e: number) => e + a);
        salePrice = salePrices.reduce((a: number, e: number) => e + a);
    }
    return (
        <div className='cart-order'>
            <h2>Заказ</h2>
            <ul>
                <li>
                    <p>Количество</p>
                    <p>{cartProducts.length}</p>
                </li>
                <li>
                    <p>Стоимость</p>
                    <p>{cartProducts.length > 0 ? Number(orderPrice).toFixed(2) : 0} руб.</p>
                    {/* <p>{cartProducts.length > 0 ? (paymentMethod === 'Карта рассрочки' ? Number(orderPrice * 1.06).toFixed(2) : Number(orderPrice).toFixed(2)) : 0} руб.</p> */}
                </li>
                <li>
                    <p>Скидка</p>
                    <p>-{cartProducts.length > 0 ? (paymentMethod === 'Карта рассрочки' ? (salePrice - (orderPrice * 0.06)).toFixed(2) : salePrice.toFixed(2))  : 0} руб.</p>
                </li>
                <li>
                    <p>Доставка в магазин</p>
                    <p>Бесплатно</p>
                </li>
            </ul>
            <input type="text" placeholder='Введите промокод'/>
            <div>
                <h2>Итого к оплате</h2>
                <h2>{cartProducts.length > 0 ? (paymentMethod === 'Карта рассрочки' ? Number(orderPrice * 1.06).toFixed(2) : Number(orderPrice).toFixed(2)) : 0} руб.</h2>
            </div>
            {!noBtn && (
                <a href="/order">
                    <button className="btn btn-warning --orange-btn btn-price">
                        Перейти к оформлению
                    </button>
                </a>
            )}
        </div>
    )
}
