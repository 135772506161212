import React from "react";
import { LoginFormProps } from "../../../interfaces/props/LoginFormProps";
import '../../resources/css/Forms.css';
import { useNavigate } from "react-router-dom";

const isAuthenticated = () => {
    return !!sessionStorage.getItem('__access-token');
};

export const LoginForm: React.FC<LoginFormProps> = ({ styles, i_content, modalName }) => {
    const navigate = useNavigate();

    const handleClick = (): void => {
        if (isAuthenticated()) {
            navigate('/profile');
        } else {
            const modal = document.getElementById('staticBackdropLogin-' + modalName);
            if (modal) {
                modal.style.display = 'block';
            } else {
                console.error(`Modal with id "${modalName}" not found.`);
            }
        }
    };

    const isAuthenticatedStatus = isAuthenticated(); // Save the authentication status to avoid multiple calls

    return (
        <>
            <button type="button" className={styles}
                    data-bs-toggle={isAuthenticatedStatus ? "" : "modal"}
                    data-bs-target={isAuthenticatedStatus ? "" : `#staticBackdropLogin-${modalName}`}
                    onClick={handleClick}>
                {i_content ? <i className={i_content}></i> : <></>}
                Личный кабинет
            </button>
        </>
    );
};
