import React from 'react';
import slide_1 from '../../resources/images/slider-1.png';
import slide_2 from '../../resources/images/slider-2.png';
import slide_3 from '../../resources/images/slider-3.png';
import slide_4 from '../../resources/images/slider-4.png';
import slide_5 from '../../resources/images/slider-5.png';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore from 'swiper';
import {Autoplay} from "swiper/modules";

SwiperCore.use([Autoplay]);


export const Slider: React.FC = () => {
    return (
        <div className="swiper-container main-slider">

            <Swiper
                spaceBetween={10}
                slidesPerView={1}
                loop={true}
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: false
                }}
                centeredSlides={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        centeredSlides: true,
                    },
                    700: {
                        slidesPerView: 1,
                    },
                }}
            >
                <SwiperSlide><img src={slide_1} className="d-block w-100 slider-slide" alt="Slide 1"/></SwiperSlide>
                <SwiperSlide><img src={slide_2} className="d-block w-100 slider-slide" alt="Slide 2"/></SwiperSlide>
                <SwiperSlide><img src={slide_3} className="d-block w-100 slider-slide" alt="Slide 3"/></SwiperSlide>
                <SwiperSlide><img src={slide_4} className="d-block w-100 slider-slide" alt="Slide 4"/></SwiperSlide>
                <SwiperSlide><img src={slide_5} className="d-block w-100 slider-slide" alt="Slide 5"/></SwiperSlide>
            </Swiper>
        </div>
    );
};
