import React, {useContext, useEffect, useState} from 'react'
import '../../resources/css/CartPage.css'
import Trash from '../../resources/images/trash.svg'
import Phone from "../../resources/images/RedPhone.svg"
import Cross from "../../resources/images/cross.svg"
import SmileySad from "../../resources/images/SadSmiley2.svg"
import ArrowBack from "../../resources/images/arrowBack.svg"
import {AskForm} from '../components/AskForm'
import {Breadcrumbs} from '../components/Breadcrumbs'
import {OrderBlock} from '../components/OrderBlock'
import {AppContext} from '../context/AppContext'

const BREADCRUMB_ITEMS = [
    {label: 'Главная', href: '/'},
    {label: 'Корзина', href: '/cart'}
]

export const CartPage = () => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('Catalog must be used within an AppContextProvider');
    }

    const {addToCart, toggleBtns, removeFromCart, setCart} = context;
    const cartProducts = JSON.parse(localStorage.getItem("cart") || '[]');
    const [count, setCount] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1000);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <Breadcrumbs items={BREADCRUMB_ITEMS}/>
            {cartProducts.length > 0 ? (
                <>
                    <div className='cart-wrap'>
                        <h1>Корзина</h1>
                        <div className='cart-container'>
                            <div className='cart-products'>
                                {isMobile ? (
                                    <>
                                        {cartProducts.map((item: any, index: number) => (
                                            <div className='product-cart-line mobile' key={index}>
                                                <img className='cross' style={{cursor: 'pointer'}}
                                                     onClick={() => removeFromCart(item.id)} src={Cross} alt="Cross"/>
                                                <a href={`/product/${item.id}`}>
                                                    <div>
                                                        <img src={item.image} alt="Tyre"/>
                                                        <div className='product-model-block'>
                                                            <p>{item.vendor}</p>
                                                            <span>{item.model}</span>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div className='cart-price-block'>
                                                    <div>
                                                        <p className='text-p'>Цена за 1шт.</p>
                                                        <span
                                                            className='price-span'>{item.vendor === 'Kumho' || item.vendor === 'Arivo' ? Number(item.price * 1.25).toFixed(2) : Number(item.price * 1.15).toFixed(2)} руб.</span>
                                                        <span
                                                            className='price-span-discount'>{item.vendor === 'Kumho' || item.vendor === 'Arivo' ? Number(item.price * 1.25 - item.price).toFixed(2) : Number(item.price * 1.15 - item.price).toFixed(2)} руб.</span>
                                                        <span
                                                            className='final-span-price'>{Number(item.price).toFixed(2)} руб.</span>
                                                    </div>
                                                    <div className='btns-block'>
                                                        <button onClick={() => toggleBtns(item, "minus")}
                                                                className='btn-quantity'>–
                                                        </button>
                                                        <span>{item.quantity}</span>
                                                        <button onClick={() => toggleBtns(item, "plus")}
                                                                className='btn-quantity'>+
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <p className='text-p'>Стоимость</p>
                                                        <span
                                                            className='price-span'>{item.vendor === 'Kumho' || item.vendor === 'Arivo' ? Number(item.price * item.quantity * 1.25).toFixed(2) : Number(item.price * item.quantity * 1.15).toFixed(2)} руб.</span>
                                                        <span
                                                            className='price-span-discount'>{item.vendor === 'Kumho' || item.vendor === 'Arivo' ? Number((item.price * item.quantity * 1.25) - item.price * item.quantity).toFixed(2) : Number((item.price * item.quantity * 1.15) - item.price * item.quantity).toFixed(2)} руб.</span>
                                                        <span
                                                            className='final-span-price'>{Number(item.price * item.quantity).toFixed(2)} руб.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {cartProducts.map((item: any, index: number) => (
                                            <div className='product-cart-line' key={index}>
                                                <a href={`/product/${item.id}`}>
                                                    <div className='cart-img-block'>
                                                        <img src={item.image} alt="Tyre"/>
                                                    </div>
                                                </a>
                                                <div className='product-info-block'>
                                                    <div className='product-text-info-block'>
                                                        <a href={`/product/${item.id}`}>
                                                            <div className='product-model-block'>
                                                                <p>{item.vendor}</p>
                                                                <span>{item.model}</span>
                                                            </div>
                                                        </a>
                                                        <div className='cart-price-block'>
                                                            <div>
                                                                <p className='text-p'>Цена за 1шт.</p>
                                                                <span
                                                                    className='price-span'>{item.vendor === 'Kumho' || item.vendor === 'Arivo' ? Number(item.price * 1.25).toFixed(2) : Number(item.price * 1.15).toFixed(2)} руб.</span>
                                                                <span
                                                                    className='price-span-discount'>{item.vendor === 'Kumho' || item.vendor === 'Arivo' ? Number(item.price * 1.25 - item.price).toFixed(2) : Number(item.price * 1.15 - item.price).toFixed(2)} руб.</span>
                                                                <span
                                                                    className='final-span-price'>{Number(item.price).toFixed(2)} руб.</span>
                                                            </div>
                                                            <div>
                                                                <p className='text-p'>Стоимость</p>
                                                                <span
                                                                    className='price-span'>{item.vendor === 'Kumho' || item.vendor === 'Arivo' ? Number(item.price * item.quantity * 1.25).toFixed(2) : Number(item.price * item.quantity * 1.15).toFixed(2)} руб.</span>
                                                                <span
                                                                    className='price-span-discount'>{item.vendor === 'Kumho' || item.vendor === 'Arivo' ? Number((item.price * item.quantity * 1.25) - item.price * item.quantity).toFixed(2) : Number((item.price * item.quantity * 1.15) - item.price * item.quantity).toFixed(2)} руб.</span>
                                                                <span
                                                                    className='final-span-price'>{Number(item.price * item.quantity).toFixed(2)} руб.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='product-cart-buttons'>
                                                        <div className='btns-block'>
                                                            <button onClick={() => toggleBtns(item, "minus")}
                                                                    className='btn-quantity'>–
                                                            </button>
                                                            <span>{item.quantity}</span>
                                                            <button onClick={() => toggleBtns(item, "plus")}
                                                                    className='btn-quantity'>+
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <img src={Trash} alt="Trash"/>
                                                            <p style={{cursor: 'pointer'}}
                                                               onClick={() => removeFromCart(item.id)}>Удалить</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className='card-questions'>
                                            <div>
                                                <img src={Phone} alt=""/>
                                                <p>ОСТАЛИСЬ ВОПРОСЫ ?</p>
                                            </div>
                                            <p>Звоните нам +375 (29) 115-26-26</p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <OrderBlock/>
                        </div>
                    </div>
                    <AskForm/>
                </>
            ) : (
                <div className='cart-emty-wrap'>
                    <a href="/products?category=passenger&page=1">
                        <img src={ArrowBack} alt="ArrowBack"/>
                        <p>Вернуться к покупкам</p>
                    </a>
                    <div>
                        <img src={SmileySad} alt="SmileySad"/>
                        <h2>Ваша корзина пуста</h2>
                    </div>
                </div>
            )}
        </>
    )
}
